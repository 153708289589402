import { useState } from 'react'
// import type { SetStateAction } from 'react'
import { isEmpty } from 'lodash'
import * as yup from 'yup'
import { Autocomplete, Box, Chip, CircularProgress, Container, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import FORMIK from 'modules/formik'
import { useGetSchemeTypeDropdownMutation } from 'store/serviceBookingEventBanner'
// import type { PartialSubsPlanCreateProps, PartialSubsPlanDataCreateProps } from 'modules/partial'
import { useGetMemberTypeDropdownsssMutation } from 'store/dropdown'
import { useCreateSubsPlanMutation } from 'store/subsplan'
import type {
  CallbackHandler, CarTypeDataDropDown, DetailStateProps,
  ErrorProps
} from 'modules/types'
import BackdropImage from 'components/backdropImage/backdropImage'
import GlobalStyle from 'modules/styles'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  if (!open) return <></>

  const [getSchemetype, schemeTypeDropdown] = useGetSchemeTypeDropdownMutation()
  const [createBannerList, create] = useCreateSubsPlanMutation()
  const [getCarModelDropdown, carModelList] = useGetMemberTypeDropdownsssMutation()

  const [isImageTypeValid, setIsImageTypeValid] = useState<boolean>(false)
  const [isImageFiveKB, setIsImageFiveKB] = useState<boolean>(false)
  const [previewImage, setPreviewImage] = useState<string | ArrayBuffer | null>(null)
  const [previewBackdrop, setPreviewBackdrop] = useState<boolean>(false)
  const [userType, setUserType] = useState<CarTypeDataDropDown[]>([])
  const [messageUntil, setMessageUntil] = useState<string>('')

  const scheme = yup.object<PartialSubsPlanCreateProps>({
    Files: yup.mixed().required('Files is required'),
    data: yup.object<PartialSubsPlanDataCreateProps>({
      SubscriptionPlanName: yup.string().required('Subscription Plan Name is required').max(30, 'Maximum Length is 30 Characters'),
      SubscriptionPlanNameID: yup.string().required('Subscription Plan ID is required').max(30, 'Maximum Length is 30 Characters'),
      SubscriptionPlanNameEN: yup.string().required('Subscription Plan EN is required').max(30, 'Maximum Length is 30 Characters'),
      // SeqNo: yup.number().nullable(),
      SeqNo: yup.number().required('Sequence No is required').positive('Sequence No must be greater than 0').integer('Sequence No must be an integer'),
      Price: yup.number().required('Price is required').positive().integer(),
      Duration: yup.string().required('Duration is required'),
      ValidFrom: yup.string().required('Valid From is required'),
      ValidUntil: yup.string().required('Valid Until Plan Name is required'),
      CarModel: yup.array().of(yup.object().shape({
        PkId: yup.number().required(),
        CarName: yup.string().required(),
      })).required('Car Model is required'),
    })
  })

  const initialValues: PartialSubsPlanCreateProps = {
    Files: {} as File,
    // Files: null,
    data: {
      SubscriptionPlanName: '',
      SubscriptionPlanNameID: '',
      SubscriptionPlanNameEN: '',
      SeqNo: null,
      Price: null,
      Duration: '',
      ValidFrom: '',
      ValidUntil: '',
      CarModel: []
    }
  }

  const formatNumberWithCommas = (value: string) => {
    console.log('value', value);

    if (!value) return '';
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const fileOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string,
    callback: CallbackHandler<string | ArrayBuffer | null>
  ) => {
    const files = e && e.target && e.target.files;
    const notEmpty = files && files.length > 0 && !isEmpty(files);
    if (notEmpty) {
      const file = files[0];
      const validTypes = ['image/png', 'image/gif', 'image/jpeg'];

      if (!validTypes.includes(file.type)) {
        formik.setFieldError(field, 'Invalid file type. Only PNG, GIF, or JPEG files are allowed.');
        formik.setFieldValue(field, {} as File); // Reset the field value
        setIsImageTypeValid(true);
        setIsImageFiveKB(false);
        return callback(null);
      }

      if (file.size > 5 * 1024) {
        formik.setFieldError(field, 'File size must be less than 5KB.');
        formik.setFieldValue(field, {} as File); // Reset the field value
        setIsImageTypeValid(false);
        setIsImageFiveKB(true);
        return callback(null);
      }

      // Reset the error state if the file is valid
      setIsImageTypeValid(false);
      setIsImageFiveKB(false); // Reset to false since the file size is valid

      console.log('Selected file:', file); // Log file yang dipilih

      const reader = new FileReader();
      reader.onload = () => callback(reader.result);
      reader.readAsDataURL(file);

      formik.setFieldValue(field, file);
      return;
    } else {
      formik.setFieldValue(field, {} as File);
      return callback(null);
    }
  };

  const formik = FORMIK.useFormFormik<PartialSubsPlanCreateProps>(scheme, initialValues, (values) => {
    const formData = new FormData();
    const data = {
      ...values.data,
      // IsAllCarModel: userType.length === 0,
      // CarModel: userType.length > 0 ? userType : [],
      CarModel: userType.length > 0
        ? userType.map(car => ({ PkId: car.id, CarName: car.name }))
        : [],
    };

    // Pastikan nilai Files tidak null
    if (values.Files) {
      formData.append('Files', values.Files);
    } else {
      console.warn('No file selected to send.'); // Log peringatan jika tidak ada file
    }

    // Menambahkan data ke FormData
    formData.append('data', JSON.stringify(data));

    // Cek isi FormData
    console.log('FormData contents:');
    formData.forEach((value, key) => {
      console.log('key', key);
      console.log('value', value);
    });

    createBannerList(formData).then(response => {
      console.log('Response from server:', response);
    }).catch(error => {
      console.error('Error sending FormData:', error);
    });
  });


  const error = formik.errors && (formik.errors.data as unknown as PartialSubsPlanDataCreateProps)
  const touched = formik.touched && (formik.touched as PartialSubsPlanDataCreateProps)
  const requestToken = formik.values.data;

  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const handleChange = (value: CarTypeDataDropDown[]) => {
    const isian = carModelList.data || []; // All available car models  
    // Check if "All" is selected
    if (value.some(item => item.name === "All")) {
      setUserType(isian);
      setIsDisabled(true); // Disable the dropdown
      formik.setFieldValue('data.IsAllCarModel', true); // Set IsAllCarModel to true
      formik.setFieldValue('data.CarModel', isian.map(car => ({ PkId: car.id, CarName: car.name }))); // Set all car models
    } else {
      const filteredValue = value.filter(item => item.name !== "All");
      setUserType(filteredValue);
      setIsDisabled(false);
      const isAllSelected = filteredValue.length === isian.length; // Check if all available models are selected
      formik.setFieldValue('data.IsAllCarModel', isAllSelected);
      formik.setFieldValue('data.CarModel', filteredValue.map(car => ({ PkId: car.id, CarName: car.name }))); // Update CarModel
    }
  };

  const isEndDateGreaterThanStartDate = () => {
    if (
      messageUntil && dayjs(formik.values.data.ValidFrom).isAfter(dayjs(formik.values.data.ValidUntil))
    ) {
      return true
    } else {
      return false
    }
  }

  const disabled = !formik.isValid || isEmpty(previewImage) || (formik.values.data.CarModel.length === 0) ||
    isEndDateGreaterThanStartDate() || (formik.values.data.Price === null || isNaN(formik.values.data.Price) || formik.values.data.Price === 0);
  // console.log('Formik values:', formik.values);
  // console.log('CarModel:', formik.values.data.CarModel);
  // console.log('formikError', formik.errors)
  // console.log('formikTouched', formik.touched)
  // console.log('Preview Image:', previewImage);

  return (
    <>
      <Dialog
        title='Create New Plan'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={disabled}
      >
        <Container {...DialogStyle.Container}>
          <TextField
            {...DialogStyle.textField}
            id='SubscriptionPlanName'
            variant='outlined'
            label='Subscription Plan Name'
            value={formik.values.data.SubscriptionPlanName}
            onChange={(event) => formik.setFieldValue('data.SubscriptionPlanName', event.target.value)}
            onBlur={formik.handleBlur}
            error={touched && Boolean(touched.SubscriptionPlanName) && error && Boolean(error.SubscriptionPlanName)}
            helperText={touched && touched.SubscriptionPlanName && error && error.SubscriptionPlanName}
            fullWidth
          />
          <TextField
            {...DialogStyle.textField}
            id='SubscriptionPlanNameEN'
            variant='outlined'
            label='Subscription Plan Name EN-US'
            value={formik.values.data.SubscriptionPlanNameEN}
            onChange={(event) => formik.setFieldValue('data.SubscriptionPlanNameEN', event.target.value)}
            onBlur={formik.handleBlur}
            error={touched && Boolean(touched.SubscriptionPlanNameEN) && error && Boolean(error.SubscriptionPlanNameEN)}
            helperText={touched && touched.SubscriptionPlanNameEN && error && error.SubscriptionPlanNameEN}
            fullWidth
          />
          <TextField
            {...DialogStyle.textField}
            id='SubscriptionPlanNameID'
            variant='outlined'
            label='Subscription Plan Name ID'
            value={formik.values.data.SubscriptionPlanNameID}
            onChange={(event) => formik.setFieldValue('data.SubscriptionPlanNameID', event.target.value)}
            onBlur={formik.handleBlur}
            error={touched && Boolean(touched.SubscriptionPlanNameID) && error && Boolean(error.SubscriptionPlanNameID)}
            helperText={touched && touched.SubscriptionPlanNameID && error && error.SubscriptionPlanNameID}
            fullWidth
          />
          <TextField
            {...DialogStyle.textField}
            id='SeqNo'
            variant='outlined'
            label='Sequence No'
            type='number'
            value={formik.values.data.SeqNo}
            onChange={(event) => formik.setFieldValue('data.SeqNo', event.target.value)}
            onBlur={formik.handleBlur}
            error={touched && Boolean(touched.SeqNo) && error && Boolean(error.SeqNo)}
            helperText={touched && touched.SeqNo && error && error.SeqNo}
            fullWidth
          />
          <Autocomplete
            options={(schemeTypeDropdown && schemeTypeDropdown.data) || []}
            getOptionLabel={(list) => list.name}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.name == value.name : false
            }
            onOpen={() => getSchemetype()}
            onChange={(_, id) => formik.setFieldValue('data.Duration', id && id.name)}
            ListboxProps={GlobalStyle.ListBox}
            renderOption={(props, item) => (
              <li {...props} key={item.name}>
                {item.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name='Duration'
                label='Scheme Type'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {schemeTypeDropdown.isLoading && (
                        <CircularProgress color='inherit' size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
          <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                value={requestToken && requestToken.ValidFrom ? dayjs(requestToken.ValidFrom) : null} // Change undefined to null
                onChange={(e: Dayjs | null) => {
                  const newDate = e ? e.format('YYYY-MM-DD') : ''; // Handle null case
                  formik.setFieldValue('data.ValidFrom', newDate);
                }}
                label='Start Date'
                format='MMM, DD YYYY'
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                value={requestToken && requestToken.ValidUntil ? dayjs(requestToken.ValidUntil) : null}
                minDate={requestToken && requestToken.ValidFrom ? dayjs(requestToken.ValidFrom) : undefined}
                onChange={(e: Dayjs | null) => {
                  const newDate = e ? e.format('YYYY-MM-DD') : ''; // Handle null case
                  formik.setFieldValue('data.ValidUntil', newDate);
                }}
                onError={(error) => {
                  if (error) {
                    formik.setFieldError('data.ValidUntil', error);
                    setMessageUntil(formik.values.data.ValidFrom)
                  } else if (formik.values.data.ValidFrom && dayjs(formik.values.data.ValidFrom).isAfter(dayjs(formik.values.data.ValidUntil))) {
                    formik.setFieldError('data.ValidUntil', 'Valid Until must be greater than Valid From');
                  }
                }}
                label='End Date'
                format='MMM, DD YYYY'
              />
            </LocalizationProvider>
          </div>
          {messageUntil && dayjs(formik.values.data.ValidFrom).isAfter(dayjs(formik.values.data.ValidUntil)) && (
            <FormHelperText sx={{ alignSelf: 'center', marginTop: -1 }} error id='ValidUntil'>End Date must be greater than Start Date</FormHelperText>
          )}
          <FormControl fullWidth>
            <InputLabel htmlFor='electricityFee'>Price</InputLabel>
            <OutlinedInput
              id='Price'
              label='Price'
              // type='number'
              type='text'
              // value={formik.values.data.Price && formik.values.data.Price.toString()}
              // value={formatNumberWithCommas((formik.values.data.Price || 0).toString())}
              value={formatNumberWithCommas(formik.values.data.Price !== null && !isNaN(formik.values.data.Price) ? formik.values.data.Price.toString() : '')}
              // onChange={(event) => formik.setFieldValue('data.Price', event.target.value)}
              onChange={(e) => {
                const rawValue = parseFloat(e.target.value.replace(/,/g, ''));
                formik.setFieldValue('data.Price', rawValue);
              }}
              onBlur={formik.handleBlur}
              // error={touched && Boolean(touched.Price) && error && Boolean(error.Price)}
              error={
                formik.touched &&
                formik.touched.data?.Price &&
                Boolean(formik.errors.data?.Price)
              }
              inputProps={{ autoComplete: 'off' }}
              onKeyDown={(event) => {
                if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
              }}
              startAdornment={<InputAdornment position='start'>Rp</InputAdornment>}
            />
            {formik.touched && formik.touched.data?.Price && formik.errors && (
              <FormHelperText error id='Price'>
                {formik.errors.data?.Price}
              </FormHelperText>
            )}
          </FormControl>
          <Autocomplete
            fullWidth
            sx={{ marginBottom: '10px' }}
            multiple
            id='fixed-tags-demo'
            value={userType}
            onOpen={() => getCarModelDropdown()}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.id === value.id : false // Compare by id instead of name
            }
            onChange={(event, newValue) => {
              setUserType(newValue);
              handleChange(newValue);
            }}
            options={[
              { id: 14, name: "All" }, // New option
              ...(carModelList && carModelList.data || []), // Existing options
            ]}
            getOptionLabel={(option) => option.name}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  label={option.name} {...getTagProps({ index })}
                  key={index}
                  disabled={isDisabled}
                />
              ))
            }
            renderInput={(params) => <TextField {...params} label='Car Model' />}
          />
          <TextField
            id='Files'
            label='Icon Plan'
            type='file'
            placeholder='Files'
            InputLabelProps={{ shrink: true }}
            inputProps={{ accept: 'image/png, image/gif, image/jpeg' }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              fileOnChange(e, 'Files', (value) => setPreviewImage(value))
            }
            onBlur={formik.handleBlur}
            fullWidth
          />
          {previewImage && (
            <Grid item xs={12}>
              <Typography {...DialogStyle.Title}>Icon Plan</Typography>
              <Box {...DialogStyle.Image}>
                <BackdropImage
                  open={previewBackdrop}
                  onOpen={() => setPreviewBackdrop(true)}
                  onClose={() => setPreviewBackdrop(false)}
                >
                  <img
                    src={previewImage as string | undefined}
                    alt='Files'
                    width='100%'
                    height='100%'
                  />
                </BackdropImage>
              </Box>
            </Grid>
          )}
          {isImageTypeValid && (
            <FormHelperText error id='Files' sx={{ display: 'flex', alignSelf: 'center', mt: -2 }}>
              Invalid file type. Only PNG, GIF, or JPEG files are allowed.
            </FormHelperText>
          )}
          {isImageFiveKB && (
            <FormHelperText error id='Files' sx={{ display: 'flex', alignSelf: 'center', mt: -2 }}>
              File size must be less than 5 KB.
            </FormHelperText>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export type PartialSubsPlanCreateProps = {
  Files: File | null
  data: PartialSubsPlanDataCreateProps
}
export type PartialSubsPlanDataCreateProps = {
  SubscriptionPlanName: string
  SubscriptionPlanNameID: string
  SubscriptionPlanNameEN: string
  SeqNo: number | null
  Price: number | null
  Duration: string
  ValidFrom: string
  ValidUntil: string
  IsAllCarModel?: boolean,
  CarModel: CarModelCreateProps[]
}
export type CarModelCreateProps = {
  PkId: number,
  CarName: string
}

export default Create
