import { useCallback } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import Loading from 'components/loading/loading'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import {
  useGetConfigDetailQuery,
  useGetConfigPaymentMethodListQuery,
  useUpdateConfigMutation
} from 'store/config'
import type { PartialUpdateConfigProps } from 'modules/partial'
import Drawer from 'components/drawer/detail'
import { IOSSwitch } from 'components/iosSwitch/iosSwitch'
import DrawerStyle from './style'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetConfigDetailQuery(id)

  console.log('data', data);
  
  const configPaymentMethodListResult = useGetConfigPaymentMethodListQuery()

  const [updateConfig, updateConfigResult] = useUpdateConfigMutation()

  const onSubmit = useCallback(
    (values: PartialUpdateConfigProps) => {
      updateConfig({
        ...values,
        value: values.code === 'AUTO_RENEWAL' && !values.value?.length ? [''] : values.value
      })
    },
    [updateConfig]
  )

  const scheme = yup.object<PartialUpdateConfigProps>({
    pkId: yup.number().required('Config ID is required'),
    code: yup.string().required('Config code is required'),
    name: yup.string().required('Config name is required'),
    value: yup.array().of(yup.string().required('Config value is required'))
  })

  const formik = useFormik<PartialUpdateConfigProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      pkId: (data && data.pkId) || 0,
      code: (data && data.code) || '',
      name: (data && data.name) || '',
      value: (data && data.value && data.value.filter(Boolean)) || []
    },
    onSubmit
  })
  
  console.log('formik.values.value?.[0]', formik.values.value?.[0]);
  

  const errorData = formik.errors

  const getValueForm = () => {
    switch (formik.values.code) {
      case 'PAYMENT_METHOD':
        return (
          <Grid item xs={12}>
            <FormGroup>
              {configPaymentMethodListResult.data?.map((v, id) => (
                <FormControlLabel
                  key={id}
                  name='value'
                  label={v.name}
                  control={
                    <Checkbox
                      defaultChecked={data?.value?.indexOf(v.name) !== -1}
                      onChange={formik.handleChange}
                      value={v.name}
                    />
                  }
                />
              ))}
            </FormGroup>
          </Grid>
        )

      case 'AUTO_RENEWAL':
        return (
          <Grid item xs={12}>
            <FormControlLabel
              name='value'
              labelPlacement='start'
              label={formik.values.value?.[0] === 'Disable' ? 'Disable' : 'Enable'}
              control={
                <IOSSwitch
                  sx={{ mx: 1 }}
                  defaultChecked={formik.values.value?.[0] === 'Enable'}
                  onChange={(e) => {
                    formik.setFieldValue('value', e.target.checked ? ['Enable'] : ['Disable']);
                  }}
                  value={formik.values.value?.[0]}
                />
              }
            />
          </Grid>
        )

      default:
        return (
          <TextField
            name='value[0]'
            variant='outlined'
            label='Value'
            value={formik.values.value?.[0]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.value && Boolean(errorData.value)}
            helperText={formik.touched.value && errorData.value}
            fullWidth
          />
        )
    }
  }

  return (
    <>
      <Drawer open={open} title='Edit Config' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <Box {...DrawerStyle.Content}>
                <TextField
                  variant='outlined'
                  label='Config Code'
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.code && Boolean(errorData.code)}
                  helperText={formik.touched.code && errorData.code}
                  disabled
                  fullWidth
                />

                <TextField
                  variant='outlined'
                  label='Category'
                  value={data.category}
                  disabled
                  fullWidth
                />

                <TextField
                  name='name'
                  variant='outlined'
                  label='Config Name'
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(errorData.name)}
                  helperText={formik.touched.name && errorData.name}
                  fullWidth
                />

                {getValueForm()}
              </Box>

              <Box {...DrawerStyle.ButtonContainer}>
                <LoadingButton
                  disabled={!formik.isValid}
                  loading={updateConfigResult.isLoading}
                  variant='contained'
                  onClick={() => formik.handleSubmit()}
                >
                  Submit
                </LoadingButton>
              </Box>
            </>
          )}
        </Container>
      </Drawer>

      <Notification
        open={!updateConfigResult.isLoading && !updateConfigResult.isUninitialized}
        onClose={() =>
          updateConfigResult.isError ? updateConfigResult.reset() : location.reload()
        }
        isError={Boolean(updateConfigResult.error) && updateConfigResult.isError}
        message={GLOBAL.returnExceptionMessage(
          updateConfigResult.isError,
          updateConfigResult.error as ErrorProps
        )}
      />
    </>
  )
}

export default Edit
