import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import ReactQuill from 'react-quill'
import { decode } from 'html-entities'
import { ExpandMoreOutlined } from '@mui/icons-material'
import { useGetDetailCopywritingQuery } from 'store/copyright'
import type { CopywritingDetailProps, DetailStateProps } from 'modules/types'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
// import { SimplePdfViewer } from 'components/pdfViewer/simplePdfViewer'
import { PdfViewer } from 'components/pdfViewer/pdfViewer'
import DrawerStyle from './style'


const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetDetailCopywritingQuery(id)

  const loading = !data && (isLoading || isFetching)

  const category = (data && data.category) || '-'
  const CopywritingCode = (data && data.code) || '-'
  const CopywritingyName = (data && data.name) || '-'
  const contentID = (data && decode(data.contentID)) || '-'
  const contentEN = (data && decode(data.contentEN)) || '-'

  const gridDataLabel = ['Category', 'Copywriting Code', 'Copywriting Name']

  const gridDataValue = [category, CopywritingCode, CopywritingyName]

  // const createdBy = (data && data.cre) || '-'
  // const createdAt = (data && data.createdAt) || '-'
  // const modifiedBy = (data && data.modifiedBy) || '-'
  // const modifiedAt = (data && data.modifiedAt) || '-'

  // const gridBasicLabel = ['Created By', 'Created At', 'Modified By', 'Modified At']
  // const gridBasicValue = [createdBy, createdAt, modifiedBy, modifiedAt]

  return (
    <>
      <Drawer open={open} title='Detail Copywriting' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {loading ? (
            <Loading />
          ) : data ? (
            <>
              <Grid container spacing={2}>
                {gridDataValue.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <TextField
                      variant='outlined'
                      label={gridDataLabel[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                ))}
              </Grid>

              <Accordion elevation={0} defaultExpanded disableGutters>
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined />}
                  aria-controls='panel1-content'
                  id='panel1-header'
                >
                  <Typography sx={{ fontWeight: 'bold' }}>Bahasa ID</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  {isPdfContent(data) ? (
                    contentID ? (
                      <PdfViewer url={contentID} />
                    ) : (
                      <>🚫 Data not found</>
                    )
                  ) : (
                    <ReactQuill theme='snow' value={contentID} readOnly={true} />
                  )}
                </AccordionDetails>
              </Accordion>

              <Accordion elevation={0} defaultExpanded disableGutters>
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined />}
                  aria-controls='panel1-content'
                  id='panel1-header'
                >
                  <Typography sx={{ fontWeight: 'bold' }}>Languange EN-US</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  {isPdfContent(data) ? (
                    contentEN ? (
                      <PdfViewer url={contentEN} />
                    ) : (
                      <>🚫 Data not found</>
                    )
                  ) : (
                    <ReactQuill theme='snow' value={contentEN} readOnly={true} />
                  )}
                </AccordionDetails>
              </Accordion>

              {/* <Accordion elevation={0} disableGutters>
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls='basic-information'
                  >
                    <Typography>Basic Information</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {gridBasicValue.map((item, index) => (
                        <Grid key={index} item xs={12}>
                          <TextField
                            variant='outlined'
                            label={gridBasicLabel[index]}
                            value={item}
                            inputProps={{ readOnly: true }}
                            fullWidth
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion> */}
            </>
          ) : (
            <>🚫 Data not found</>
          )}
        </Container>
      </Drawer>
    </>
  )
}

const isPdfContent = (data: CopywritingDetailProps): boolean =>
  (data.category === 'Term and Condition' && data.code === 'TNC') ||
  (data.category === 'Privacy Policy' && data.code === 'PRIVACY_POLICY')

export default Detail
