import CONSTANT from 'modules/constant'
import type {
    PaginationProps,
    PaidSubscriptionDetailProps,
    PaidSubscriptionListProps,
    ResultProps,
} from 'modules/types'
import { API, download, headers } from './api'

const PaidSubscriptionAPI = API.injectEndpoints({
    endpoints: (builder) => ({
        getPaidSubscriptionList: builder.mutation<PaginationProps<PaidSubscriptionListProps>, FormData>({
            query: (body) => ({
                url: CONSTANT.URL_PAID_SUBSCRIPTION_LIST,
                method: 'POST',
                headers: headers({ useContentType: false }),
                body: body,
                formData: true
            })
        }),
        getPaidSubscriptionDetail: builder.query<PaidSubscriptionDetailProps, number>({
            query: (id) => ({
                url: CONSTANT.URL_PAID_SUBSCRIPTION_DETAIL + id,
                method: 'GET',
                headers: headers()
            })
        }),
        updateStopAutoRenewal: builder.mutation<ResultProps, {Id: number, IsActive: boolean}>({
            query: (body) => ({
                url: CONSTANT.URL_PAID_SUBSCRIPTION_UPDATE_STOP_AUTO_RENEWAL,
                method: 'PUT',
                headers: headers(),
                body: body
            })
        }),
        downloadPaidSubscription: builder.mutation<PaginationProps<PaidSubscriptionListProps>, FormData>({
            query: (body) => ({
                url: CONSTANT.URL_PAID_SUBSCRIPTION_DOWNLOAD,
                method: 'POST',
                headers: headers({ useContentType: false }),
                responseHandler: download,
                body: body,
                formData: true
            })
        })
    })
})

export const {
    useGetPaidSubscriptionListMutation,
    useGetPaidSubscriptionDetailQuery,
    useUpdateStopAutoRenewalMutation,
    useDownloadPaidSubscriptionMutation
} = PaidSubscriptionAPI