/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState } from 'react'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import GLOBAL from 'modules/global'
import { incrementValue } from './incrementValue'

export const DonutChart = ({
  type = 'donut',
  title,
  total,
  colorType = 'color1',
  data
}: DonutChartProps) => {
  const [chartWidthRef, setChartWidthRef] = useState<number>(0)
  const [chartMiddlePointRef, setChartMiddlePointRef] = useState<number>(0)
  const legendHeightRef = useRef<number>(0)

  const options = {
    chart: {
      type: 'pie',
      height: 300,
      events: {
        render: function (this: any) {
          const series = this.series[0]

          const x = series.center[0] + this.plotLeft
          const y = series.center[1] + this.plotTop

          setChartWidthRef(this.chartWidth)
          setChartMiddlePointRef(y)
          legendHeightRef.current = Number(this.legend.legendHeight ?? 0)

          this.subtitle.css({
            fontFamily: 'Hyundai Sans Text Office',
            fontSize: `${series.center[2] / 12}px`
          })
          this.subtitle.attr({ x, y })
        }
      }
    },
    colors: getColorList(colorType),
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    title: {
      text: title,
      align: 'left',
      style: {
        fontFamily: 'Hyundai Sans Head Office',
        fontWeight: '500',
        fontSize: '18px'
      }
    },
    subtitle: {
      floating: true,
      verticalAlign: 'middle',
      useHTML: true,
      text:
        total !== null && total !== undefined
          ? `
        <div style="position: absolute; transform: translate(-50%, -25%); font-weight: bold; font-size: 40px">
        ${String(total)}
        </div>
      `
          : undefined
    },
    credits: { enabled: false },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>'
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      y: chartMiddlePointRef - legendHeightRef.current / 2,
      // borderWidth: 1,
      backgroundColor: '#FFFFFF',
      shadow: false,
      useHTML: true,
      navigation: {
        activeColor: '#002c5f',
        inactiveColor: '#999',
        style: { fontFamily: 'Hyundai Sans Text Office' }
      },
      labelFormatter: function (this: any) {
        return [
          `<div style="font-family: Hyundai Sans Text Office; font-size: 14px; margin-bottom: 8px; width: ${
            chartWidthRef * (this.valueAmount !== undefined ? 1 / 2 : 2 / 5) - 20
          }px">`,
          `<div>${this.name}</div>`,
          '<div style="display: flex; flex-direction: row; gap: 6px; align-items: center">',
          `<div style="flex: 1; font-weight: 500">${this.y}</div>`,
          this.valueAmount !== undefined
            ? `<div>Rp ${GLOBAL.formatCurrency(this.valueAmount)}</div>`
            : '',
          this.growth !== undefined ? (incrementValue(Number(this.growth), true) as string) : '',
          '</div>',
          '</div>'
        ].join('')
      }
    },
    plotOptions: {
      pie: {
        innerSize: type === 'donut' ? '75%' : undefined
      },
      series: {
        allowPointSelect: true,
        cursor: 'pointer',
        // dataLabels: [{
        //     enabled: true,
        //     distance: 20,
        //     format: '{point.name}'
        // }, {
        //     enabled: true,
        //     distance: -15,
        //     format: '{point.percentage:.0f}%',
        //     style: {
        //         fontSize: '0.9em'
        //     }
        // }],
        dataLabels: {
          enabled: false,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        },
        showInLegend: true
      }
    },
    series: [
      {
        type: 'pie',
        name: 'Total Subscription',
        data: data.map((d) => ({ ...d, y: d.value }))
      }
    ]
  } as Highcharts.Options

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

const getColorList = (colorType: DonutChartProps['colorType']): string[] => {
  switch (colorType) {
    case 'color2':
      return ['#7fe47e', '#e46157', '#c14940', '#ffae4c', '#ff928a']
    default:
      return ['#00809e', '#00aad2', '#33bbdb', '#66cce4', '#99dded']
  }
}

interface DonutChartProps {
  type?: 'donut' | 'pie'
  title: string
  total?: number
  colorType?: 'color1' | 'color2'
  data: { name: string; value: number; valueAmount?: number; growth?: number }[]
}
