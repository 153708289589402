import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Chip,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import moment from 'moment'
import GLOBAL from 'modules/global'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import type { Children } from 'modules/types'
import { useGetDetailSubsPlanQuery } from 'store/subsplan'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose, status }: DetailStatePlanProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetDetailSubsPlanQuery(id)

  const loading = !data && (isLoading || isFetching)

  const subsplanID = (data && data.pkId) || '-'
  const subsplaname = (data && data.subscriptionPlanName) || '-'
  const subsplanameEN = data && data.subscriptionPlanNameEN
  const subsplanameID = data && data.subscriptionPlanNameID
  const sequenceNo = data && data.seqNo
  const schemeType = data && data.duration
  const validFrom = data && GLOBAL.formatDate(data.validFrom) || '-'
  const validUntil = data && GLOBAL.formatDate(data.validUntil) || '-'
  const price = data && data.price
  const iconPlan = data && data.iconUrl || null
  // const status = status || '-'

  const gridDataLabel = [
    'Subscription Plan ID',
    'Subscription Plan Name',
    'Subscription Plan Name EN-US',
    'Subscription Plan Name ID',
    'Sequence No',
    'Scheme Type'
  ]

  const gridDataValue = [
    subsplanID,
    subsplaname,
    subsplanameEN,
    subsplanameID,
    sequenceNo,
    schemeType
  ]

  const gridDataLabel2 = [
    'Start Date',
    'End Date'
  ]

  const gridDataValue2 = [
    validFrom,
    validUntil
  ]

  const gridDataLabel3 = [
    'Price',
  ]

  const gridDataValue3 = [
    price
  ]

  const formatDateWithTime = (value: string) =>
    value ? moment(value).format('DD MMM YYYY HH:mm') : '-';

  const createdBy = (data && data.createdByName) || '-'
  const createdAt = (data && formatDateWithTime(data.createdDate)) || '-'
  const modifiedBy = (data && data.modifiedByName) || '-'
  const modifiedAt = (data && formatDateWithTime(data.modifiedDate)) || '-'

  const statusLabel = status === null ? '-' : (status === 'ENABLED' ? 'Enabled' : 'Disabled');
  const statusColor = status === 'ENABLED' ? 'success' : 'error';

  return (
    <>
      <Drawer open={open} title='Subscription Detail Plan' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {loading && <Loading />}
          {!loading && (
            <>
              <Grid container spacing={2}>
                {gridDataValue.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <TextField
                      variant='outlined'
                      label={gridDataLabel[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={2}>
                {gridDataValue2.map((item, index) => (
                  <Grid key={index} item xs={6}>
                    <TextField
                      variant='outlined'
                      label={gridDataLabel2[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={2}>
                {gridDataValue3.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <TextField
                      variant='outlined'
                      label={gridDataLabel3[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                ))}
              </Grid>
              <Autocomplete
                fullWidth
                sx={{ marginBottom: '10px' }}
                multiple
                id='fixed-tags-demo'
                defaultValue={(data && data.carModel) || []}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.carName == value.carName : false
                }
                disabled
                options={(data && data.carModel) || []}
                getOptionLabel={(option) => option.carName}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip label={option.carName} {...getTagProps({ index })} key={index} />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params} label='Car model' />
                )}
              />
              <Box style={{
                display: 'flex',
                width: '100%',
                minHeight: '4rem',
                border: '1px solid rgba(0, 0, 0, 0.2)',
                borderRadius: 1,
                overflow: 'hidden',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                {iconPlan && (
                  <img
                    src={iconPlan}
                    alt={iconPlan}
                    style={{
                      height: 24, width: 24, objectFit: 'contain',
                      marginRight: '10px',
                      marginLeft: '-300px',
                      display: 'flex',
                    }}
                  />
                )}
                <Typography>Icon Plan</Typography>
              </Box>
              <Grid container>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    paddingLeft: '8px'
                  }}>
                  {status !== null ? (
                    <>
                      <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Status</Typography>
                      <Chip
                        sx={{ marginTop: '3px' }}
                        label={statusLabel}
                        color={statusColor}
                      />
                    </>
                  ) : (
                    <>
                      <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Status</Typography>
                      <Typography>{statusLabel}</Typography>
                    </>
                  )}
                </Box>
              </Grid>
              <Accordion elevation={0} disableGutters>
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined />}
                  aria-controls='basic-information'
                >
                  <Typography>Basic Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ marginTop: '4px', }}>
                      <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Created By</Typography>
                      <Typography>{createdBy}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: '4px', }}>
                      <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Last Modified By</Typography>
                      <Typography>{modifiedBy}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: '4px', }}>
                      <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Created Date</Typography>
                      <Typography>{createdAt}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: '4px', }}>
                      <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Last Modified Date</Typography>
                      <Typography>{modifiedAt}</Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export type DetailStatePlanProps = Children & {
  title?: string
  open: boolean
  maxWidth?: 'sm' | 'md'
  onClose: () => void,
  status: string
}

export default Detail
