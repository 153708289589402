import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  TableRow,
  TableCell,
  IconButton,
  Typography,
  FormHelperText,
} from '@mui/material'
import moment from 'moment'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useDownloadSubscriptionExtensionLogMutation, useGeExtensionLogListMutation } from 'store/subscriptionExtensionLog'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import CONSTANT from 'modules/constant'
import Notification from 'components/notification'
import type { ErrorProps } from 'modules/types'
import GLOBAL from 'modules/global'
import Loading from 'components/loading/loading'
import Icon from 'components/icon'
// import GlobalStyle from 'modules/styles'
import Detail from './detail/detail'

const SubscriptionExtensionLog = () => {
  const [getSubciptionExtensionLogList, listExtensionLog] = useGeExtensionLogListMutation()
  const [downloadSubscriptionExtensionLog, download] = useDownloadSubscriptionExtensionLogMutation()

  const [maxPrevoiusDate, setMaxPreviousDate] = useState<string>('')
  const [maxAdjustDate, setMaxAdjustDate] = useState<string>('')
  const [maxACreatedDate, setMaxCreatedDate] = useState<string>('')
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [randomKey, setRandomKey] = useState<number>(0)

  const [filter, setFilter] = useState({
    MinPreviousExpDate: '',
    MaxPreviousExpDate: '',
    MinAdjustedExpDate: '',
    MaxAdjustedExpDate: '',
    MinCreatedDate: '',
    MaxCreatedDate: ''
  })

  const [payload, setPayload] = useState<PartialSubExtensionLogProps>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: '',
    MinPreviousExpDate: '',
    MaxPreviousExpDate: '',
    MinAdjustedExpDate: '',
    MaxAdjustedExpDate: '',
    MinCreatedDate: '',
    MaxCreatedDate: ''
  })
  const formatDateWithTime = (value: string) =>
    value ? moment(value).format('MMM DD, YYYY HH:mm:ss') : '-';
  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: value })
  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })

  const onResync = () => {
    const formData = new FormData()

    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search) && !isEmpty(payload.Search))
      formData.append('Search', payload.Search)

    if (!isEmpty(payload.MinPreviousExpDate)) formData.append('MinPreviousExpDate', payload.MinPreviousExpDate)
    if (!isEmpty(payload.MaxPreviousExpDate)) formData.append('MaxPreviousExpDate', payload.MaxPreviousExpDate)
    if (!isEmpty(payload.MinAdjustedExpDate)) formData.append('MinAdjustedExpDate', payload.MinAdjustedExpDate)
    if (!isEmpty(payload.MaxAdjustedExpDate)) formData.append('MaxAdjustedExpDate', payload.MaxAdjustedExpDate)
    if (!isEmpty(payload.MinCreatedDate)) formData.append('MinCreatedDate', payload.MinCreatedDate)
    if (!isEmpty(payload.MaxCreatedDate)) formData.append('MaxCreatedDate', payload.MaxCreatedDate)

    getSubciptionExtensionLogList(formData)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('Length', String(listExtensionLog.data?.recordsTotal))

    if (!isEmpty(payload.Search) && !isEmpty(payload.Search))
      formData.append('Search', payload.Search)

    if (!isEmpty(payload.MinPreviousExpDate)) formData.append('MinPreviousExpDate', payload.MinPreviousExpDate)
    if (!isEmpty(payload.MaxPreviousExpDate)) formData.append('MaxPreviousExpDate', payload.MaxPreviousExpDate)
    if (!isEmpty(payload.MinAdjustedExpDate)) formData.append('MinAdjustedExpDate', payload.MinAdjustedExpDate)
    if (!isEmpty(payload.MaxAdjustedExpDate)) formData.append('MaxAdjustedExpDate', payload.MaxAdjustedExpDate)
    if (!isEmpty(payload.MinCreatedDate)) formData.append('MinCreatedDate', payload.MinCreatedDate)
    if (!isEmpty(payload.MaxCreatedDate)) formData.append('MaxCreatedDate', payload.MaxCreatedDate)

    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadSubscriptionExtensionLog(formData)
  }

  const onFilter = (state: boolean) => { setDialogFilter(state), setMaxPreviousDate(''), setMaxAdjustDate(''), setMaxCreatedDate('') }

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback: CallableFunction) => {
    setAnchor(null)
    callback(callback)
  }

  const tableHeadTitles = [
    'Registered Owner',
    'VIN',
    'User ID',
    'Previous Exp Date',
    'Adjusted Exp Date',
    'Created Date'
  ]

  const openAnchor = Boolean(anchor)
  const totalPage =
    listExtensionLog.data && GLOBAL.tableTotalPage(listExtensionLog.data.recordsFiltered)
  const numbers = GLOBAL.tableNumber(payload.Start)

  const isMaxDateGreaterThanMintDate = () => {
    if (
      maxPrevoiusDate && dayjs(filter.MinPreviousExpDate).isAfter(dayjs(filter.MaxPreviousExpDate)) ||
      maxAdjustDate && dayjs(filter.MinAdjustedExpDate).isAfter(dayjs(filter.MaxAdjustedExpDate)) ||
      maxACreatedDate && dayjs(filter.MinCreatedDate).isAfter(dayjs(filter.MaxCreatedDate))
    ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    onResync()
    setMaxPreviousDate('')
    setMaxAdjustDate('')
    setMaxCreatedDate('')
  }, [payload])

  return (
    <>
      <Content
        title='Subscription Extension Logs'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={listExtensionLog.data && listExtensionLog.data.recordsFiltered}
        page={payload.Start + 1}
        isLoading={listExtensionLog.isLoading}
        useDownloadDropdown
      >
        {listExtensionLog.isSuccess &&
          listExtensionLog.data &&
          listExtensionLog.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.pkId}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.pkId}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => {
                    onAction(event, index)
                    onRemoveAnchor(() => setDrawerDetail(true))
                  }}
                >
                  <Icon icon='VisibilityOutlined' />
                </IconButton>
                <Detail
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                  id={table.pkId}
                />
              </TableCell>
              <TableCell>{table.registeredOwner || '-'}</TableCell>
              <TableCell>{table.vin || '-'}</TableCell>
              <TableCell>{table.userId || '-'}</TableCell>
              <TableCell>{formatDateWithTime(table.proviousExpDate) || ''}</TableCell>
              <TableCell>{formatDateWithTime(table.adjustedExpDate) || '-'}</TableCell>
              <TableCell>{formatDateWithTime(table.createdDate) || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>

      {listExtensionLog.isLoading && <Loading />}

      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter) || isMaxDateGreaterThanMintDate()}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setRandomKey(randomKey + 1)
          setFilter({
            MinPreviousExpDate: '',
            MaxPreviousExpDate: '',
            MinAdjustedExpDate: '',
            MaxAdjustedExpDate: '',
            MinCreatedDate: '',
            MaxCreatedDate: ''
          })
          setPayload({
            ...payload,
            Start: 0,
            MinPreviousExpDate: '',
            MaxPreviousExpDate: '',
            MinAdjustedExpDate: '',
            MaxAdjustedExpDate: '',
            MinCreatedDate: '',
            MaxCreatedDate: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, Start: 0, ...filter })
          onFilter(false)
        }}
      >
        <Typography><strong>Previous Exp. Date</strong></Typography>
        <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex', marginTop: -15 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              value={filter.MinPreviousExpDate ? dayjs(filter.MinPreviousExpDate) : null}
              onChange={(event: Dayjs | null) =>
                setFilter({ ...filter, MinPreviousExpDate: (event && event.format('YYYY-MM-DD')) || '' })
              }
              label='Min Date'
              format='MMM, DD YYYY'
              sx={{ width: '190px' }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              value={filter.MaxPreviousExpDate ? dayjs(filter.MaxPreviousExpDate) : null}
              minDate={dayjs(filter.MinPreviousExpDate)}
              onChange={(event: Dayjs | null) =>
                setFilter({ ...filter, MaxPreviousExpDate: (event && event.format('YYYY-MM-DD')) || '' })
              }
              onError={(error) => {
                if (error) {
                  setMaxPreviousDate(filter.MaxPreviousExpDate)
                }
              }}
              label='Max Date'
              format='MMM, DD YYYY'
              sx={{ width: '190px' }}
            />
          </LocalizationProvider>
        </div>
        {maxPrevoiusDate && dayjs(filter.MinPreviousExpDate).isAfter(dayjs(filter.MaxPreviousExpDate)) && (
          <FormHelperText sx={{ alignSelf: 'center', marginTop: -2 }} error id='MaxPreviousExpDate'>Max Date must be greater than Min Date</FormHelperText>
        )}
        <Typography><strong>Adjusted Exp. Date</strong></Typography>
        <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex', marginTop: -15 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              value={filter.MinAdjustedExpDate ? dayjs(filter.MinAdjustedExpDate) : null}
              onChange={(event: Dayjs | null) =>
                setFilter({ ...filter, MinAdjustedExpDate: (event && event.format('YYYY-MM-DD')) || '' })
              }
              label='Min Date'
              format='MMM, DD YYYY'
              sx={{ width: '190px' }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              value={filter.MaxAdjustedExpDate ? dayjs(filter.MaxAdjustedExpDate) : null}
              minDate={dayjs(filter.MinAdjustedExpDate)}
              onChange={(event: Dayjs | null) =>
                setFilter({ ...filter, MaxAdjustedExpDate: (event && event.format('YYYY-MM-DD')) || '' })
              }
              onError={(error) => {
                if (error) {
                  setMaxAdjustDate(filter.MaxAdjustedExpDate)
                }
              }}
              label='Max Date'
              format='MMM, DD YYYY'
              sx={{ width: '190px' }}
            />
          </LocalizationProvider>
        </div>
        {maxAdjustDate && dayjs(filter.MinAdjustedExpDate).isAfter(dayjs(filter.MaxAdjustedExpDate)) && (
          <FormHelperText sx={{ alignSelf: 'center', marginTop: -2 }} error id='MaxAdjustedExpDate'>Max Date must be greater than Min Date</FormHelperText>
        )}
        <Typography><strong>Created Date</strong></Typography>
        <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex', marginTop: -15 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              value={filter.MinCreatedDate ? dayjs(filter.MinCreatedDate) : null}
              onChange={(event: Dayjs | null) =>
                setFilter({ ...filter, MinCreatedDate: (event && event.format('YYYY-MM-DD')) || '' })
              }
              label='Min Date'
              format='MMM, DD YYYY'
              sx={{ width: '190px' }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              value={filter.MaxCreatedDate ? dayjs(filter.MaxCreatedDate) : null}
              minDate={dayjs(filter.MinCreatedDate)}
              onChange={(event: Dayjs | null) =>
                setFilter({ ...filter, MaxCreatedDate: (event && event.format('YYYY-MM-DD')) || '' })
              }
              onError={(error) => {
                if (error) {
                  setMaxCreatedDate(filter.MaxCreatedDate)
                }
              }}
              label='Max Date'
              format='MMM, DD YYYY'
              sx={{ width: '190px' }}
            />
          </LocalizationProvider>
        </div>
        {maxACreatedDate && dayjs(filter.MinCreatedDate).isAfter(dayjs(filter.MaxCreatedDate)) && (
          <FormHelperText sx={{ alignSelf: 'center', marginTop: -2 }} error id='MaxCreatedDate'>Max Date must be greater than Min Date</FormHelperText>
        )}
      </Filter>

      <Notification
        open={
          !listExtensionLog.isLoading &&
          !listExtensionLog.isUninitialized &&
          !listExtensionLog.isSuccess
        }
        onClose={() => (listExtensionLog.isError ? listExtensionLog.reset() : location.reload())}
        isError={Boolean(listExtensionLog.error) && listExtensionLog.isError}
        message={GLOBAL.returnExceptionMessage(
          listExtensionLog.isError,
          listExtensionLog.error as ErrorProps
        )}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />
    </>
  )
}

export type PartialSubExtensionLogProps = {
  Start: number
  Length: number
  Search: string
  MinPreviousExpDate: string
  MaxPreviousExpDate: string
  MinAdjustedExpDate: string
  MaxAdjustedExpDate: string
  MinCreatedDate: string
  MaxCreatedDate: string
}
export default SubscriptionExtensionLog
