import { useEffect, useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { CircularProgress } from '@mui/material'
import { useLazyDownloadFileQuery } from 'store/blob'
import useDebounce from 'modules/useDebounce'
import type { PdfViewerProps } from 'modules/types'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'

export const PdfViewer = ({ url, onComplete }: PdfViewerProps) => {
  const [downloadFile, downloadFileResult] = useLazyDownloadFileQuery()

  const pdfContentRef = useRef<HTMLDivElement>(null)

  const [pageCount, setPageCount] = useState<number>(0)

  const [width, setWidth] = useState<number>(0)

  const debounceWidth = useDebounce(String(width))

  useEffect(() => {
    window.addEventListener('load', updateWidth)
    window.addEventListener('resize', updateWidth)

    return () => {
      window.removeEventListener('load', updateWidth)
      window.removeEventListener('resize', updateWidth)

      if (downloadFileResult.data) URL.revokeObjectURL(downloadFileResult.data)
    }
  }, [])

  useEffect(() => {
    if (!url) return

    if (url.startsWith('blob:')) {
      updateWidth()
    }
    downloadFile(url)
    updateWidth()
  }, [url])

  useEffect(() => {
    if (!downloadFileResult.data) return

    updateWidth()
  }, [downloadFileResult.isFetching])

  const updateWidth = () => {
    setWidth(pdfContentRef.current?.offsetWidth ?? 0)
  }

  const pdfUrl = url.startsWith('blob:') ? url : downloadFileResult.data
  console.log('pdfUrl ====>', pdfUrl);
  console.log('File BLOB === url', url);
  console.log('File SERVER === downloadFileResult.data', downloadFileResult.data);

  return (
    <div ref={pdfContentRef}>
      {downloadFileResult.isLoading ? (
        <Loading showText />
      ) : pdfUrl ? (
        <>
          {Boolean(Number(debounceWidth)) && (
            <Document
              file={pdfUrl}
              loading={<Loading showText />}
              onLoadSuccess={(pdf) => {
                onComplete?.()
                setPageCount(pdf.numPages)
              }}
            >
              {Array.from(Array(pageCount).keys()).map((id) => (
                <div key={id + 1} style={{ marginBottom: id + 1 === pageCount ? '0' : '1rem' }}>
                  <Page
                    width={Number(debounceWidth)}
                    pageNumber={id + 1}
                    loading={<Loading />}
                    onClick={console.log}
                  />
                </div>
              ))}
            </Document>
          )}
        </>
      ) : (
        <>🚫 Data not found</>
      )}
    </div>
  )
}

const Loading = ({ showText = false }: LoadingProps) => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '.5rem' }}>
    <CircularProgress size={12} /> {showText ? 'Loading...' : ''}
  </div>
)

interface LoadingProps {
  showText?: boolean
}

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`
// pdfjs.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js';
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.mjs',
//   import.meta.url
// ).toString()

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/legacy/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();
