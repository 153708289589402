import CONSTANT from 'modules/constant'
import type { UserMenuProps } from 'modules/types'
import { API, headers } from './api'

const userMenuAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getUserMenu: builder.query<UserMenuProps, void>({
      query: () => ({
        url: CONSTANT.URL_USER_MENU,
        method: 'GET',
        headers: headers()
      })
    })
  })
})

export const { useGetUserMenuQuery } = userMenuAPI
