import { ArrowDownLeftIcon, ArrowUpRightIcon } from '@heroicons/react/20/solid'
import { Box, Grid } from '@mui/material'

export const incrementValue = (value: number, asHtmlString = false) => {
  if (asHtmlString) {
    return [
      `<div style="display: flex; align-items: center; gap: 0.25rem; font-weight: 500; color: ${
        value >= 0 ? '#31c48d' : '#f05252'
      }">`,
      `<img alt="growth-${value >= 0 ? 'up' : 'down'}" src="/Growth-Indicator-${
        value >= 0 ? 'up' : 'down'
      }.png" /> `,
      `${value > 0 ? '+' : ''}${value && value !== 100 ? value.toFixed(1) : value}%`,
      '</div>'
    ].join('')
  }

  return (
    <Grid container gap={0.5} color={value >= 0 ? '#31c48d' : '#f05252'} fontWeight={500}>
      <Grid item>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          width={20}
          height={20}
          borderRadius={100}
          bgcolor={value >= 0 ? '#31c48d' : '#f05252'}
        >
          {value >= 0 ? (
            <ArrowUpRightIcon width={16} height={16} color='white' />
          ) : (
            <ArrowDownLeftIcon width={16} height={16} color='white' />
          )}
        </Box>
      </Grid>
      <Grid item>
        {value > 0 ? '+' : ''}
        {value && value !== 100 ? value.toFixed(1) : value}%
      </Grid>
    </Grid>
  )
}
