import { isEmpty } from 'lodash'
import CONSTANT from 'modules/constant'
import type { PaginationProps, SubsTransactionProps } from 'modules/types'
import type { PartialSubsTransactionProps } from 'modules/partial'
import { API, download, headers } from './api'

const SubscriptionTransactionAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getSubsTransactionList: builder.query<
      PaginationProps<SubsTransactionProps>,
      PartialSubsTransactionProps
    >({
      query: (payload) => {
        const body = new FormData()

        body.append('Start', payload.Start.toString())
        body.append('Length', payload.Length.toString())

        if (!isEmpty(payload.Search)) body.append('Search', payload.Search)
        if (!isEmpty(payload.Sort)) body.append('Sort', payload.Sort)
        if (!isEmpty(payload.CarModel)) body.append('CarModel', payload.CarModel)
        if (!isEmpty(payload.PaymentType)) body.append('PaymentType', payload.PaymentType)
        if (!isEmpty(payload.PromoName)) body.append('PromoName', payload.PromoName)
        if (!isEmpty(payload.StatusRenewal)) body.append('StatusRenewal', payload.StatusRenewal)
        if (!isEmpty(payload.StopRenewalDate))
          body.append('StopRenewalDate', payload.StopRenewalDate)
        if (!isEmpty(payload.SubscriptionName))
          body.append('SubscriptionName', payload.SubscriptionName)
        if (!isEmpty(payload.MaxSubscriptionEndDate))
          body.append('MaxSubscriptionEndDate', payload.MaxSubscriptionEndDate)
        if (!isEmpty(payload.MaxSubscriptionStartDate))
          body.append('MaxSubscriptionStartDate', payload.MaxSubscriptionStartDate)
        if (!isEmpty(payload.MinSubscriptionEndDate))
          body.append('MinSubscriptionEndDate', payload.MinSubscriptionEndDate)
        if (!isEmpty(payload.MinSubscriptionStartDate))
          body.append('MinSubscriptionStartDate', payload.MinSubscriptionStartDate)

        return {
          url: CONSTANT.URL_SUBS_TRANSACTION_LIST,
          method: 'POST',
          headers: headers({ useContentType: false }),
          body
        }
      }
    }),
    getSubsTransactionDetail: builder.query<SubsTransactionProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_SUBS_TRANSACTION_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    downloadSubsTransaction: builder.mutation<
      unknown,
      PartialSubsTransactionProps & { type?: string, lengthValue?: string }
    >({
      query: (payload) => {
        const body = new FormData()

        body.append('Start', payload.Start.toString())
        body.append('Length', payload.lengthValue || payload.Length.toString())

        if (!isEmpty(payload.Search)) body.append('Search', payload.Search)
        if (!isEmpty(payload.Sort)) body.append('Sort', payload.Sort)
        if (!isEmpty(payload.CarModel)) body.append('CarModel', payload.CarModel)
        if (!isEmpty(payload.PaymentType)) body.append('PaymentType', payload.PaymentType)
        if (!isEmpty(payload.PromoName)) body.append('PromoName', payload.PromoName)
        if (!isEmpty(payload.StatusRenewal)) body.append('StatusRenewal', payload.StatusRenewal)
        if (!isEmpty(payload.StopRenewalDate))
          body.append('StopRenewalDate', payload.StopRenewalDate)
        if (!isEmpty(payload.SubscriptionName))
          body.append('SubscriptionName', payload.SubscriptionName)
        if (!isEmpty(payload.MaxSubscriptionEndDate))
          body.append('MaxSubscriptionEndDate', payload.MaxSubscriptionEndDate)
        if (!isEmpty(payload.MaxSubscriptionStartDate))
          body.append('MaxSubscriptionStartDate', payload.MaxSubscriptionStartDate)
        if (!isEmpty(payload.MinSubscriptionEndDate))
          body.append('MinSubscriptionEndDate', payload.MinSubscriptionEndDate)
        if (!isEmpty(payload.MinSubscriptionStartDate))
          body.append('MinSubscriptionStartDate', payload.MinSubscriptionStartDate)
        if (payload.type && !isEmpty(payload.type)) body.append('Download-Type', payload.type)

        return {
          url: CONSTANT.URL_SUBS_TRANSACTION_DOWNLOAD,
          method: 'POST',
          headers: headers({ useContentType: false }),
          responseHandler: download,
          body
        }
      }
    })
  })
})

export const {
  useLazyGetSubsTransactionListQuery,
  useGetSubsTransactionDetailQuery,
  useDownloadSubsTransactionMutation
} = SubscriptionTransactionAPI
