import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import ReactQuill from 'react-quill'
import { decode } from 'html-entities'
import {
    Autocomplete,
    Button,
    CircularProgress,
    Container,
    Grid,
    TextField,
    Typography,
} from '@mui/material'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import Loading from 'components/loading/loading'
import { useGetCategoryDropdownMutation, useGetDetailCopywritingQuery, useUpdateCopywritingMutation } from 'store/copyright';
import type { DetailStateProps, ErrorProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import Preview from './preview/page'
import DialogStyle from './style'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: string | number }) => {
    if (!open) return <></>

    const { data, isFetching, isLoading } = useGetDetailCopywritingQuery(id)
    const [getCategoryDropdown, categoryDropdown] = useGetCategoryDropdownMutation();
    const [updateCopywriting, update] = useUpdateCopywritingMutation()

    const [isiPayload, setIsiPayload] = useState<PartialPreviewCopywritingProps>()
    const [dialogCreate, setDialogCreate] = useState<boolean>(false);
    const [pdfFileBahasa, setPdfFileBahasa] = useState<File | null>(null);
    const [pdfFileEnglish, setPdfFileEnglish] = useState<File | null>(null);
    const [fileErrorBahasa, setFileErrorBahasa] = useState<string | null>(null);
    const [fileErrorEnglish, setFileErrorEnglish] = useState<string | null>(null);
    // const [isBahasaChanged, setIsBahasaChanged] = useState<boolean>(false);
    // const [isEnglishChanged, setIsEnglishChanged] = useState<boolean>(false);
    const [htmlContentID, setHtmlContentID] = useState<string>('');
    const [htmlContentEN, setHtmlContentEN] = useState<string>('');

    const scheme = yup.object<PartialEditCopywritingProps>({
        CategoryPkId: yup.number().required('Category PkId is required'),
        Code: yup.string().required('Copywriting Code is required'),
        Name: yup.string().required('Copywriting Name is required'),
        ContentID: yup.string().nullable(),
        ContentEN: yup.string().nullable()
    });

    const handleFileChangeEnglish = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            if (file.type === 'application/pdf') {
                setPdfFileEnglish(file);
                setFileErrorEnglish(null);
                formik.setFieldValue('ContentEN', file.name); // Update value ContentEN
                // setIsEnglishChanged(true);
            } else {
                setFileErrorEnglish('File must be a PDF.');
                setPdfFileEnglish(null);
            }
        }
    };

    const handleFileChangeBahasa = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            if (file.type === 'application/pdf') {
                setPdfFileBahasa(file);
                setFileErrorBahasa(null);
                formik.setFieldValue('ContentID', file.name); // Update value ContentID
                // setIsBahasaChanged(true);
            } else {
                setFileErrorBahasa('File must be a PDF.');
                setPdfFileBahasa(null);
            }
        }
    };

    const handleContentIDChange = (content: string) => {
        setHtmlContentID(content);
        formik.setFieldValue('ContentID', GLOBAL.emptyQuill(content));
    };

    const handleContentENChange = (content: string) => {
        setHtmlContentEN(content);
        formik.setFieldValue('ContentEN', GLOBAL.emptyQuill(content));
    };

    const formik = useFormik<PartialEditCopywritingProps>({
        validationSchema: scheme,
        enableReinitialize: true,
        validateOnMount: false,
        // validateOnChange: true,
        initialValues: {
            PkId: (data && data.pkId) || 0,
            CategoryPkId: (data && data.categoryPkId) || 0,
            Code: (data && data.code) || '',
            Name: (data && data.name) || '',
            ContentID: (data?.contentID) || '',
            ContentEN: (data?.contentEN) || '',
            type: (data && data.type) || ''
        },
        onSubmit: (values: PartialEditCopywritingProps) => handleSubmit(values)
    })

    // const readFileAsBase64 = (file: File): Promise<string> =>
    //     new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.onloadend = () => resolve(reader.result as string);
    //         reader.onerror = reject;
    //         reader.readAsDataURL(file);
    //     });

    //Base64 tidak pakai prefix data:application/pdf;base64,
    const readFileAsBase64 = (file: File): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = (reader.result as string).split(',')[1];
                resolve(base64String);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });

    const handleSubmit = async (values: PartialEditCopywritingProps) => {
        let valueContentID = values.ContentID;
        let valueContentEN = values.ContentEN;

        if (data?.type === 'Html') {
            valueContentID = btoa(values.ContentID);
            valueContentEN = btoa(values.ContentEN);
        } else if (data?.category === 'Term and Condition' || data?.category === 'Privacy Policy') {
            try {
                if (pdfFileBahasa) {
                    valueContentID = await readFileAsBase64(pdfFileBahasa);
                } else {
                    valueContentID = '';
                }
                if (pdfFileEnglish) {
                    valueContentEN = await readFileAsBase64(pdfFileEnglish);
                } else {
                    valueContentEN = '';
                }
            } catch (error) {
                console.error('Error reading PDF files:', error);
                return;
            }
        }

        const formattedPayload = {
            PkId: values.PkId,
            CategoryPkId: values.CategoryPkId,
            Code: values.Code,
            Name: values.Name,
            ContentID: valueContentID,
            ContentEN: valueContentEN,
            type: values.type || '',
        };

        await updateCopywriting({ body: formattedPayload });
    };

    const errorData = formik.errors

    // const onPress = async () => {
    //     setIsiPayload({
    //         ...formik.values,
    //         category: data?.category,
    //         ContentEN: pdfFileEnglish ? URL.createObjectURL(pdfFileEnglish) : formik.values.ContentEN,
    //         ContentID: pdfFileBahasa ? URL.createObjectURL(pdfFileBahasa) : formik.values.ContentID
    //     });
    //     setDialogCreate(true);
    // };

    const onPress = async () => {
        let contentID, contentEN;

        if (data?.type === 'Html') {
            contentID = htmlContentID || decode(formik.values.ContentID);
            contentEN = htmlContentEN || decode(formik.values.ContentEN);
        } else if (data?.type === 'Pdf') {
            contentID = pdfFileBahasa ? URL.createObjectURL(pdfFileBahasa) : formik.values.ContentID;
            contentEN = pdfFileEnglish ? URL.createObjectURL(pdfFileEnglish) : formik.values.ContentEN;
        } else {
            contentID = formik.values.ContentID;
            contentEN = formik.values.ContentEN;
        }

        setIsiPayload({
            ...formik.values,
            category: data?.category,
            ContentEN: contentEN,
            ContentID: contentID
        });
        setDialogCreate(true);
    };

    const categoryDropdownValue = (!categoryDropdown.isLoading &&
        categoryDropdown?.data?.find((brand) => brand.name.toLowerCase() === data?.category.toLowerCase())) || null;

    useEffect(() => {
        if (data) {

            // setIsBahasaChanged(false);
            // setIsEnglishChanged(false);
            getCategoryDropdown()
        }
    }, [data])

    const disableButton = (formik.values.Code.length === 0) || (formik.values.Name.length === 0) ||
        fileErrorBahasa !== null || fileErrorEnglish !== null;

    // const disableButton = !formik.isValid || 
    // (data?.category === 'Term and Condition' || data?.category === 'Privacy Policy' 
    //     ? (fileErrorBahasa !== null || fileErrorEnglish !== null)
    //     : false);
    // ((data?.category === 'Term and Condition' || data?.category === 'Privacy Policy') && (!pdfFileEnglish || !pdfFileBahasa));
    // (isBahasaChanged && !isEnglishChanged);

    // const disableButton = !formik.isValid;
    // console.log('disableButton', disableButton);

    // console.log('Form validation:', {
    //     isValid: formik.isValid,
    //     values: formik.values,
    //     errors: formik.errors,
    //     disableButton
    // });

    return (
        <>
            <Dialog
                title='Edit Copywriting'
                open={open}
                onCancel={onClose}
                onSubmit={() => formik.handleSubmit()}
                loading={update.isLoading}
                isDisabled={disableButton}
                submitText={'Submit'}
            >
                <Container {...DialogStyle.Container}>
                    {(isLoading || isFetching) && !data && <Loading />}
                    {data && (
                        <>
                            <Autocomplete
                                options={(categoryDropdown && categoryDropdown.data) || []}
                                getOptionLabel={(list) => list.name}
                                isOptionEqualToValue={(option, value) =>
                                    option && value ? option.code == value.code : false
                                }
                                disabled={true}
                                onOpen={() => getCategoryDropdown()}
                                value={categoryDropdownValue}
                                onChange={(_, id) => {
                                    formik.setFieldValue('CategoryPkId', id?.pkId);
                                    const newNameCategory = id?.name || null;
                                    formik.setFieldValue('nameCategory', newNameCategory);
                                }}
                                ListboxProps={GlobalStyle.ListBox}
                                renderOption={(props, item) => (
                                    <li {...props} key={item.code}>
                                        {item.name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name='CategoryPkId'
                                        label='Category'
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {categoryDropdown.isLoading && (
                                                        <CircularProgress color='inherit' size={20} />
                                                    )}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            )
                                        }}
                                    />
                                )}
                            />
                            <TextField
                                id='Code'
                                disabled={true}
                                variant='outlined'
                                label='Copywriting Code'
                                value={formik.values.Code}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.Code && Boolean(errorData.Code)}
                                helperText={formik.touched.Code && errorData.Code}
                                fullWidth
                            />
                            <TextField
                                id='Name'
                                variant='outlined'
                                label='Copywriting Name'
                                value={formik.values.Name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.Name && Boolean(errorData.Name)}
                                helperText={formik.touched.Name && errorData.Name}
                                fullWidth
                            />
                            <Grid item xs={12}>
                                <Typography {...DialogStyle.Title}>Bahasa ID</Typography>
                                {data?.category === 'Term and Condition' || data?.category === 'Privacy Policy' ? (
                                    <>
                                        <input
                                            type="file"
                                            accept="application/pdf"
                                            onChange={handleFileChangeBahasa}
                                        />
                                        {pdfFileBahasa ? (
                                            <TextField
                                                sx={{ marginTop: '6px' }}
                                                variant="outlined"
                                                value={pdfFileBahasa.name}
                                                fullWidth
                                                InputProps={{ readOnly: true }}
                                            />
                                        ) : (
                                            <TextField
                                                sx={{ marginTop: '6px' }}
                                                variant="outlined"
                                                value={formik.values.ContentID}
                                                fullWidth
                                                InputProps={{ readOnly: true }}
                                            />
                                        )}
                                        {fileErrorBahasa && (
                                            <Typography sx={{ marginTop: '6px' }} color="error" variant="body2">
                                                {fileErrorBahasa}
                                            </Typography>
                                        )}
                                    </>
                                ) : data?.type === 'Html' ? (
                                    <ReactQuill
                                        theme='snow'
                                        value={htmlContentID || decode(formik.values.ContentID)}
                                        onChange={handleContentIDChange}
                                    />
                                ) : (
                                    <TextField
                                        id='ContentID'
                                        variant='outlined'
                                        label='ContentID'
                                        value={formik.values.ContentID}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.Name && Boolean(errorData.Name)}
                                        helperText={formik.touched.Name && errorData.Name}
                                        fullWidth
                                        multiline
                                        rows={3}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography {...DialogStyle.Title}>Language EN-US</Typography>
                                {data?.category === 'Term and Condition' || data?.category === 'Privacy Policy' ? (
                                    <>
                                        <input
                                            type="file"
                                            accept="application/pdf"
                                            onChange={handleFileChangeEnglish}
                                        />
                                        {pdfFileEnglish ? (
                                            <TextField
                                                sx={{ marginTop: '6px' }}
                                                variant="outlined"
                                                value={pdfFileEnglish.name}
                                                fullWidth
                                                InputProps={{ readOnly: true }}
                                            />
                                        ) : (
                                            <TextField
                                                sx={{ marginTop: '6px' }}
                                                variant="outlined"
                                                value={formik.values.ContentEN}
                                                fullWidth
                                                InputProps={{ readOnly: true }}
                                            />
                                        )}
                                        {fileErrorEnglish && (
                                            <Typography sx={{ marginTop: '6px' }} color="error" variant="body2">
                                                {fileErrorEnglish}
                                            </Typography>
                                        )}
                                    </>
                                ) : data?.type === 'Html' ? (
                                    <ReactQuill
                                        theme='snow'
                                        value={htmlContentEN || decode(formik.values.ContentEN)}
                                        onChange={handleContentENChange}
                                    />
                                ) : (
                                    <TextField
                                        id='ContentEN'
                                        variant='outlined'
                                        label='ContentEN'
                                        value={formik.values.ContentEN}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.Name && Boolean(errorData.Name)}
                                        helperText={formik.touched.Name && errorData.Name}
                                        fullWidth
                                        multiline
                                        rows={3}
                                    />
                                )}
                            </Grid>
                        </>
                    )}
                </Container>
                <Button variant='contained' onClick={onPress}>
                    Preview
                </Button>
            </Dialog>
            {dialogCreate &&
                <Preview
                    open={dialogCreate}
                    onClose={() => setDialogCreate(false)}
                    data={isiPayload}
                />}
            <Notification
                open={!update.isLoading && !update.isUninitialized}
                onClose={() => (update.isError ? update.reset() : location.reload())}
                isError={Boolean(update.error) && update.isError}
                message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
            />
        </>
    )
}

export type PartialPreviewCopywritingProps = {
    nameCategory?: string
    CategoryPkId?: number
    category?: string
    Code: string
    Name: string
    ContentID: string
    ContentEN: string
    type?: string
}

// export type PartialEditCopywritingProps = {
//     PkId?: number
//     CategoryPkId: number
//     Code: string
//     Name: string,
//     ContentID: string,
//     ContentEN: string,
//     type?: string
// }

export type PartialEditCopywritingProps = {
    PkId?: number
    CategoryPkId: number
    Code: string
    Name: string
    ContentID: string
    ContentEN: string
    type?: string
}

export default Edit