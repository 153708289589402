import { useEffect, useState } from "react"
import { Formik } from "formik"
import { isEmpty } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { Dialpad } from "@mui/icons-material"
import { Box, Container, FormControl, FormHelperText, IconButton, InputLabel, OutlinedInput } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import * as yup from 'yup'
import { useTfaRegisterMutation, useTfaVerifyMutation } from "store/user"
import { getUser } from "store/user.slice"
import type { AppDispatch } from "store/api.store"
import type { PartialVerify2FAProps } from "modules/partial"
import type { ErrorProps, LoginOtpStateProps, UserProps } from "modules/types"
import Notification from 'components/notification'
import GLOBAL from "modules/global"
import LoginOtpStyle from "./style"



const LoginOtp = () => {
  const location = useLocation(),
    [qrImage, setQrImage] = useState<string>(''),
    [notifMessage, setNotifMessage] = useState<string>(''),
    [tfaRegister, tfaRegisterResp] = useTfaRegisterMutation(),
    [tfaVerify, tfaVerifyResp] = useTfaVerifyMutation(),
    state = useSelector((state: { user: UserProps }) => state),
    dispatch = useDispatch<AppDispatch>(),
    navigate = useNavigate();

  let initiatedRegister = false;

  const [initialValue] = useState({ otp: '' })
  const scheme = yup.object({
    otp: yup.string()
      .required('OTP Number is required.')
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(6, 'Must be exactly 6 digits')
      .max(6, 'Must be exactly 6 digits')
  })

  const setVerifyNotif = (msg: string, isError: boolean) => {
    tfaVerifyResp.isError = isError;
    setNotifMessage(msg);
  }

  const setSessionAndRedirect = () => {
    dispatch(getUser(location.state.loginResponse));
    return navigate('/');
  }

  const register2FA = (email: string) => {
    if (initiatedRegister) return;
    else initiatedRegister = true;

    tfaRegister({
      body: {username: email},
      token: location.state.loginResponse?.token
    }).unwrap()
      .then((res) => setQrImage(res.qrCode))
      .catch(() => initiatedRegister = false);
  }

  useEffect(() => {
    const reqState: LoginOtpStateProps = location.state;
    if (!reqState) return navigate('/login');
    if (!isEmpty(state.user.token)) return navigate('/');
    if (!reqState.requestOtp) setSessionAndRedirect();

    if (!reqState.loginResponse.is2FaEnabled)
      register2FA(reqState.email);
  }, [location]);

  useEffect(() => {
    if (!tfaVerifyResp.isLoading && tfaVerifyResp.isSuccess) {
      const invalidMsg = 'Invalid OTP Code';
      try {
        if (tfaVerifyResp.isSuccess && tfaVerifyResp.data != null) {    
          if (tfaVerifyResp.data.success) {
            setVerifyNotif('Success', false);  
            setSessionAndRedirect();
          }

          setVerifyNotif(invalidMsg, true);
        } else
          setVerifyNotif(invalidMsg, true);
      } catch (err) {
        console.error(err);
        setVerifyNotif('Unknown Error', true);
      }
    }
  }, [tfaVerifyResp]);

  return (
    <>
      <Container maxWidth={false} disableGutters>
        <Box {...LoginOtpStyle.Wrapper}>
          <Formik
            initialValues={initialValue}
            validationSchema={scheme}
            enableReinitialize
            validateOnMount
            onSubmit={(values, { setSubmitting }) => {
              const reqData: PartialVerify2FAProps = {
                email: location.state.email,
                otp: values.otp
              }

              tfaVerify({body: reqData, token: location.state.loginResponse?.token})
                .then(() => setSubmitting(false));
            }}
          >
            {({
              values,
              touched,
              errors,
              isValid,
              isSubmitting,
              handleBlur,
              handleChange,
              handleSubmit
            }) => (
              <form onSubmit={handleSubmit} {...LoginOtpStyle.Card}>
                <img src='/logo-bluelink.png' alt='Logo' {...LoginOtpStyle.Logo} />
                { qrImage == '' ? (
                    <p style={{margin: 0}}>
                      <b>Two-Factor Authentication</b><br />
                      Please check your authentication app<br />
                      and enter generated 6-digit code to verify login.
                    </p>
                  ) : (
                    <p style={{margin: 0}}>
                      <b>Two-Factor Authentication</b><br />
                      Scan QR below using your authentication app<br />
                      and enter generated 6-digit code to verify login.
                    </p>
                  )
                }
                { qrImage != '' && <img src={qrImage} style={LoginOtpStyle.QRCode} /> }

                <FormControl fullWidth>
                  <InputLabel htmlFor='otp'>OTP Code</InputLabel>
                  <OutlinedInput
                    id='otp'
                    label='OTP'
                    type='text'
                    value={values.otp}
                    inputProps={{ maxLength: 6 }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched && touched.otp && Boolean(errors.otp)}
                    endAdornment={
                      <IconButton aria-label='otp' edge='end'>
                        <Dialpad />
                      </IconButton>
                    }
                  />
                  {touched && touched.otp && errors && (
                    <FormHelperText error id='otp'>
                      {errors.otp}
                    </FormHelperText>
                  )}
                </FormControl>

                <LoadingButton disabled={!isValid} loading={isSubmitting} {...LoginOtpStyle.Button}>
                  Verify
                </LoadingButton>
              </form>
            )}

          </Formik>
        </Box>
      </Container>

      <Notification
        open={!tfaRegisterResp.isLoading && !tfaRegisterResp.isUninitialized && !tfaRegisterResp.isSuccess}
        isError={tfaRegisterResp.isError || !tfaRegisterResp.data?.qrCode}
        message={GLOBAL.returnExceptionMessage(tfaRegisterResp.isError, tfaRegisterResp.error as ErrorProps)}
      />

      <Notification
        open={!tfaVerifyResp.isLoading && !tfaVerifyResp.isUninitialized}
        isError={tfaVerifyResp.isError}
        message={notifMessage}
      />
    </>
  )
}

export default LoginOtp
