import { useEffect } from 'react'
import ReactQuill from 'react-quill';
import {
    Dialog,
} from '@mui/material'
import { useGetCategoryDropdownMutation } from 'store/copyright'
// import { SimplePdfViewer } from 'components/pdfViewer/simplePdfViewer';
import { PdfViewer } from 'components/pdfViewer/pdfViewer';

export type CopywritingPayload = {
    nameCategory?: string
    CategoryPkId?: number
    category?: string
    Code: string;
    Name: string;
    ContentID: string;
    ContentEN: string;
    type?: string
}

const Preview = ({ open, onClose, data }: DetailStateProps & { data: CopywritingPayload | undefined }) => {
    const [getCategoryDropdown, categoryDropdown] = useGetCategoryDropdownMutation();
    console.log('data', data);

    const categoryDropdownValue =
        !categoryDropdown.isLoading &&
        categoryDropdown?.data?.find(
            (brand) => brand.pkId === data?.CategoryPkId
        ) || null;

    const categoryName = categoryDropdownValue?.name || '-';
    const cleanStrIndo = data?.ContentID.replace(/<[^>]*>/g, '');
    const cleanStrENG = data?.ContentEN.replace(/<[^>]*>/g, '');

    useEffect(() => {
        if (data) {
            getCategoryDropdown()
        }
    }, [data])

    const breakLongText = (text: string, maxWidth: number): JSX.Element[] => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) return [<span key={0}>{text}</span>];

        context.font = '16px Arial';
        const words = text.split(' ');
        const lines: JSX.Element[] = [];
        let currentLine = '';

        words.forEach((word, index) => {
            const testLine = currentLine + word + ' ';
            const metrics = context.measureText(testLine);
            const testWidth = metrics.width;

            if (testWidth > maxWidth && index > 0) {
                lines.push(<span key={lines.length}>{currentLine.trim()}<br /></span>);
                currentLine = word + ' ';
            } else {
                currentLine = testLine;
            }
        });

        lines.push(<span key={lines.length}>{currentLine.trim()}</span>);
        return lines;
    };

    if (!open) return null;

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth='lg'
            >
                <div style={{ backgroundColor: '#ecefff', padding: '20px', position: 'relative', width: '850px' }}>
                    <div style={{ position: 'sticky', top: '10px', zIndex: 1000, display: 'flex', justifyContent: 'flex-end' }}>
                        <button onClick={onClose} style={{ background: 'none', border: 'none', cursor: 'pointer', fontSize: '25px' }}>
                            ×
                        </button>
                    </div>
                    <div style={{ marginLeft: 10, marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '300px' }}>
                                <p style={{ color: '#737373', marginBottom: '-15px' }}>Category</p>
                                <p ><strong>{categoryName}</strong></p>
                            </div>
                            <div style={{ width: '300px', }}>
                                <p style={{ color: '#737373', marginBottom: '-15px' }}>Copywriting Code</p>
                                <p><strong>{data?.Code ? data?.Code : '-'}</strong></p>
                            </div>
                        </div>
                        <div style={{ backgroundColor: '#fff', width: '600px', display: 'flex', flexDirection: 'column', padding: '12px', height: data?.type === 'Text' ? '600px' : 'auto' }}>
                            <div>
                                <p><strong>{categoryName}</strong></p>
                                {data?.category === 'Term and Condition' || data?.category === 'Privacy Policy' ? (
                                    <PdfViewer url={data?.ContentID || ''} />
                                ) : data?.type === 'Html' ? (
                                    <ReactQuill theme='snow' value={data.ContentID} readOnly={true} />    
                                ) : (
                                    <div>{cleanStrIndo ? breakLongText(cleanStrIndo, 590) : '-'}</div>
                                )}
                            </div>
                            <div style={{ marginTop: '150px' }}>
                                <p><strong>{categoryName}</strong></p>
                                {data?.category === 'Term and Condition' || data?.category === 'Privacy Policy' ? (
                                    <PdfViewer url={data?.ContentEN || ''} />
                                ) : data?.type === 'Html' ? (
                                    <ReactQuill theme='snow' value={data.ContentEN} readOnly={true} /> 
                                ) : (
                                    <div>{cleanStrENG ? breakLongText(cleanStrENG, 590) : '-'}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export type Children = {
    children?: React.ReactNode
}

export type DetailStateProps = Children & {
    title?: string
    open: boolean
    data: CopywritingPayload | undefined;
    maxWidth?: 'sm' | 'md'
    onClose: () => void
}

export default Preview