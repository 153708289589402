import { useState } from 'react'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Box } from '@mui/material'
import { DashboardEmpty } from 'pages/dashboard/empty'

export const BarChart = ({
  title,
  actionButton,
  loading = false,
  onSeriesChange,
  transactionData = []
}: BarChartProps) => {
  const [visibleSeries, setVisibleSeries] = useState('By Region')

  const data = Array.isArray(transactionData) ? transactionData : []

  const options: Highcharts.Options = {
    chart: {
      type: 'bar',
      height: 280,
      style: {
        fontFamily: 'Hyundai Sans Text Office'
      },
      scrollablePlotArea: {
        minHeight: transactionData.length * 52
      }
    },
    title: { text: undefined },
    legend: { enabled: false },
    credits: { enabled: false },
    tooltip: { enabled: false },
    xAxis: {
      categories: data.map((item) => item.name),
      title: {
        text: null
      },
      gridLineWidth: 1,
      lineWidth: 0,
      labels: {
        align: 'left',
        x: 0,
        y: -3,
        enabled: true
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: '',
        align: 'high'
      },
      labels: {
        overflow: 'justify'
      },
      gridLineWidth: 1,
      gridLineDashStyle: 'Dash'
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true
        },
        groupPadding: 0.1,
        events: {
          click: function (event) {
            const seriesName = event.point.series.name
            setVisibleSeries(seriesName)
            onSeriesChange(seriesName)
          },
          mouseOver: function (event) {
            // Ensure that the event is defined
            if (event && event.target) {
              // Handle mouse over event
            }
          }
        }
      }
    },
    series: [
      {
        type: 'bar',
        name: 'By Region',
        color: '#00aad2',
        data: data.map((item) => Number(item.value)),
        pointPlacement: -0.2,
        dataLabels: {
          align: 'right',
          alignTo: 'plotEdges',
          y: -14,
          style: {
            fontWeight: 'normal'
          }
        },
        pointWidth: 10,
        visible: visibleSeries === 'By Region'
      },
      {
        type: 'bar',
        name: 'By Car Model',
        color: '#00aad2',
        data: data.map((item) => Number(item.value)),
        pointPlacement: -0.2,
        dataLabels: {
          align: 'right',
          alignTo: 'plotEdges',
          y: -14,
          style: {
            fontWeight: 'normal'
          }
        },
        pointWidth: 10,
        visible: visibleSeries === 'By Car Model'
      },
      {
        type: 'bar',
        name: 'By Age',
        color: '#00aad2',
        data: data.map((item) => Number(item.value)),
        pointPlacement: -0.2,
        dataLabels: {
          align: 'right',
          alignTo: 'plotEdges',
          y: -14,
          style: {
            fontWeight: 'normal'
          }
        },
        pointWidth: 10,
        visible: visibleSeries === 'By Age'
      }
    ]
  }

  // Custom legend click handler
  const handleLegendClick = (seriesName: string) => {
    setVisibleSeries(seriesName)
    onSeriesChange(seriesName)
  }

  return (
    <>
      {actionButton}

      <div
        style={{
          textAlign: 'left',
          fontFamily: 'Hyundai Sans Head Office',
          fontSize: '18px',
          fontWeight: 500
        }}
      >
        {title}
      </div>

      <div style={{ marginBottom: '10px', marginTop: '10px' }}>
        <span
          style={{
            cursor: 'pointer',
            marginRight: '10px',
            color: visibleSeries === 'By Region' ? '#FFFFFF' : '#000000',
            fontSize: '14px',
            fontWeight: visibleSeries === 'By Region' ? 500 : 400,
            backgroundColor: visibleSeries === 'By Region' ? '#00AAD2' : '#EBEBEB',
            padding: '6px 12px'
          }}
          onClick={() => handleLegendClick('By Region')}
        >
          By Region
        </span>
        <span
          style={{
            cursor: 'pointer',
            marginRight: '10px',
            color: visibleSeries === 'By Car Model' ? '#FFFFFF' : '#000000',
            fontSize: '14px',
            fontWeight: visibleSeries === 'By Car Model' ? 500 : 400,
            backgroundColor: visibleSeries === 'By Car Model' ? '#00AAD2' : '#EBEBEB',
            padding: '6px 12px'
          }}
          onClick={() => handleLegendClick('By Car Model')}
        >
          By Car Model
        </span>
        <span
          style={{
            cursor: 'pointer',
            marginRight: '10px',
            color: visibleSeries === 'By Age' ? '#FFFFFF' : '#000000',
            fontSize: '14px',
            fontWeight: visibleSeries === 'By Age' ? 500 : 400,
            backgroundColor: visibleSeries === 'By Age' ? '#00AAD2' : '#EBEBEB',
            padding: '6px 12px'
          }}
          onClick={() => handleLegendClick('By Age')}
        >
          By Age
        </span>
      </div>

      <Box position='relative'>
        <Box minHeight={280}>
          {loading ? (
            <DashboardEmpty loading />
          ) : transactionData?.length ? (
            <HighchartsReact highcharts={Highcharts} options={options} />
          ) : (
            <DashboardEmpty />
          )}
        </Box>
      </Box>
    </>
  )
}

interface BarChartProps {
  title: string
  actionButton: JSX.Element
  loading?: boolean
  onSeriesChange: (seriesName: string) => void
  transactionData: { name: string; value: string }[] | undefined // Allow undefined
}
