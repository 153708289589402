import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Box, CircularProgress, Collapse, Fade, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useGetUserMenuQuery } from 'store/userMenu'
import Icon from 'components/icon'
import { setActiveMenu } from 'store/userMenu.slice'
import type { AppDispatch } from 'store/api.store'
import type { UserSubMenuProps } from 'modules/types'
import SidebarStyle from './style'

const Sidebar = () => {
  const location = useLocation()

  const dispatch = useDispatch<AppDispatch>()

  const { data: userMenu, isFetching } = useGetUserMenuQuery()

  const [hover, setHover] = useState<boolean>(false)

  const onMouseEvent = (state: boolean) => setHover(state)

  useEffect(() => {
    if (!userMenu?.mainMenu.length) return

    dispatch(setActiveMenu(getActiveMenu(userMenu?.mainMenu, location.pathname)))
  }, [location.pathname, userMenu?.mainMenu])

  return (
    <Box {...SidebarStyle.Container}>
      {isFetching ? (
        <Box p={3.5}>
          <CircularProgress size={24} />
        </Box>
      ) : userMenu?.mainMenu.length ? (
        <Collapse
          orientation='horizontal'
          in={hover}
          collapsedSize={80}
          onMouseEnter={() => onMouseEvent(true)}
          onMouseLeave={() => onMouseEvent(false)}
        >
          <Box {...SidebarStyle.Wrapper}>
            {userMenu.mainMenu.map(({ icon, name, path, subMenu }, index) => (
              <Link
                {...SidebarStyle.Link}
                key={index}
                to={(subMenu.length ? subMenu[0].path : path) ?? '/'}
              >
                <Box {...SidebarStyle.Button}>
                  <Box {...SidebarStyle.Icon}>
                    <Icon icon={icon || 'ChevronRight'} />
                    {/* <IconHero name={icon} /> */}
                  </Box>
                  <Fade in={hover}>
                    <Typography {...SidebarStyle.Title}>{name}</Typography>
                  </Fade>
                </Box>
              </Link>
            ))}
          </Box>
        </Collapse>
      ) : (
        <Box p={3.5}>
          <Icon icon='Error' />
        </Box>
      )}
    </Box>
  )
}

const getActiveMenu = (
  menu: UserSubMenuProps[],
  path?: string,
  result: UserSubMenuProps[] = []
): UserSubMenuProps[] => {
  if (!path) return result

  const currentPath = path
    .split('/')
    .filter((_, id) => id <= result.length + 1)
    .join('/')

  const currentMenu = menu.find((m) => m.path === currentPath)
  const currentSubmenu = currentMenu?.subMenu ?? []

  if (!currentMenu) return result

  result.push({ ...currentMenu, subMenu: [] })

  return getActiveMenu(currentSubmenu, path, result)
}

export default Sidebar
