import {
  Container,
  Typography
} from '@mui/material'
import moment from 'moment'
import { decode } from 'html-entities'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import type { DetailStateProps } from 'modules/types'
import { useGetDetailAuditTrailQuery } from 'store/audittrail'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetDetailAuditTrailQuery(id)

  const loading = !data && (isLoading || isFetching)

  const tableName = (data && data.module) || '-'
  const recordID = (data && data.recordId) || '-'
  const columnName = data && data.columnName || ''
  // const oldValue = data && data.oldValue || ''
  // const newValue = data && decode(data.newValue) || ''
  const timeStamp = data && data.timestamp || ''
  const createdBy = data && data.createdName || ''
  const role = data && data.role || ''


  const oldValue = data && data.oldValue ?
    (data.oldValue.includes('&lt;') ?
      decode(data.oldValue).replace(/<p>/g, '').replace(/<\/p>/g, '') :
      data.oldValue)
    : '';

  const newValue = data && data.newValue ?
    (data.newValue.includes('&lt;') ?
      decode(data.newValue).replace(/<p>/g, '').replace(/<\/p>/g, '') :
      data.newValue)
    : '';


  // const formatDateWithTime = (value: string) => value ? moment(value).format('MMM DD, YYYY HH:mm:ss') : '-';
  const formatDateWithTime = (value: string) =>  value ? moment(value).format('MMM DD, YYYY hh:mm:ss A') : '-';

  return (
    <>
      <Drawer open={open} title='Detail Audit Trail' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {loading && <Loading />}
          {!loading && (
            <>
              <Typography style={{ color: '#737373', fontSize: 14, fontWeight: '400' }}>Table Name</Typography>
              <Typography sx={{ marginTop: -2 }} style={{ color: '#000000', fontSize: 16, fontWeight: '500' }}><strong>{tableName ? tableName : '-'}</strong></Typography>

              <Typography style={{ color: '#737373', fontSize: 14, fontWeight: '400' }}>Record ID</Typography>
              <Typography sx={{ marginTop: -2 }} style={{ color: '#000000', fontSize: 16, fontWeight: '500' }}><strong>{recordID ? recordID : '-'}</strong></Typography>

              <Typography style={{ color: '#737373', fontSize: 14, fontWeight: '400' }}>Column Name</Typography>
              <Typography sx={{ marginTop: -2 }} style={{ color: '#000000', fontSize: 16, fontWeight: '500' }}><strong>{columnName ? columnName : '-'}</strong></Typography>

              <Typography style={{ color: '#737373', fontSize: 14, fontWeight: '400' }}>Old Value</Typography>
              <Typography sx={{ marginTop: -2 }} style={{ color: '#000000', fontSize: 16, fontWeight: '500' }}><strong>{oldValue ? oldValue : '-'}</strong></Typography>

              <Typography style={{ color: '#737373', fontSize: 14, fontWeight: '400' }}>New Value</Typography>
              <Typography sx={{ marginTop: -2 }} style={{ color: '#000000', fontSize: 16, fontWeight: '500' }}><strong>{newValue ? newValue : '-'}</strong></Typography>

              <Typography style={{ color: '#737373', fontSize: 14, fontWeight: '400' }}>Time Stamp</Typography>
              <Typography sx={{ marginTop: -2 }} style={{ color: '#000000', fontSize: 16, fontWeight: '500' }}><strong>{timeStamp ? formatDateWithTime(timeStamp) : '-'}</strong></Typography>

              <Typography style={{ color: '#737373', fontSize: 14, fontWeight: '400' }}>Created By</Typography>
              <Typography sx={{ marginTop: -2 }} style={{ color: '#000000', fontSize: 16, fontWeight: '500' }}><strong>{createdBy ? createdBy : '-'}</strong></Typography>

              <Typography style={{ color: '#737373', fontSize: 14, fontWeight: '400' }}>Role</Typography>
              <Typography sx={{ marginTop: -2 }} style={{ color: '#000000', fontSize: 16, fontWeight: '500' }}><strong>{role ? role : '-'}</strong></Typography>
            </>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export default Detail
