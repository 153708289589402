import { Box, Grid, Typography } from '@mui/material'
import IconComponent from 'components/iconHero/information'
import GLOBAL from 'modules/global'
import { incrementValue } from './incrementValue'
import CustomTooltip from './CustomTooltip'

export const NumberChart = ({ icon, color, tooltip, data }: NumberChartProps) => (
  <>
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      bgcolor={color}
      my={2}
      width={48}
      height={48}
    >
      {icon}
    </Box>

    <Grid container flexDirection='row' alignItems='center' gap={0.5}>
      <Grid item>
        <Typography style={{ fontSize: 36, fontWeight: 500 }}>
          {GLOBAL.numberFormat(data.value)}
        </Typography>
      </Grid>
      <Grid item>{incrementValue(data.value)}</Grid>
    </Grid>

    <Box display='flex' gap={1} sx={{ fontSize: 13.6, color: '#999999' }}>
      <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {data.name}
      </span>
      {tooltip && (
        <CustomTooltip arrow title={tooltip}>
          <div>
            <IconComponent color='#00aad2' width='18' height='18' />
          </div>
        </CustomTooltip>
      )}
    </Box>
  </>
)

interface NumberChartProps {
  icon: JSX.Element
  color: string
  tooltip?: string
  data: { name: string; value: number; growth: number }
}
