import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs'
import { TabContext, TabList } from '@mui/lab'
import { Box, Container, Tab } from '@mui/material'
import type { UserSubMenuProps } from 'modules/types'
import TabStyle from './style'

const Tabs = ({ tabs }: { tabs?: UserSubMenuProps[] }) => {
  const navigate = useNavigate()
  const breadcrumbs = useReactRouterBreadcrumbs()
  const current = tabs && tabs.find((list) => breadcrumbs.some((bread) => list.path == bread.key))

  const [id, setIndex] = useState<string>('')

  const changeTab = (_: React.SyntheticEvent, value: string) => {
    setIndex(value)
    if (tabs) navigate(tabs[+value].path || '/')
  }

  useEffect(() => {
    if (tabs && isEmpty(id)) {
      setIndex('0')
      navigate(tabs[0].path || '/')
    }

    if (current) {
      const index = tabs.findIndex((e) => e.path == current.path)

      setIndex(index.toString())
      navigate(current.path || '/')
    }
  }, [current, id])

  return (
    <Container {...TabStyle.Container}>
      <TabContext value={id}>
        <Box {...TabStyle.Tab}>
          {current && !isEmpty(id) && (
            <TabList onChange={changeTab}>
              {tabs &&
                tabs.map((tab, index) => (
                  <Tab label={tab.name} key={index} value={index.toString()} />
                ))}
            </TabList>
          )}
        </Box>
      </TabContext>
    </Container>
  )
}

export default Tabs
