import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import * as yup from 'yup'
import type { GridColDef, GridRowId, GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid'
import { DataGrid } from '@mui/x-data-grid'
import {
  Autocomplete,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import Dialog from 'components/dialog/dialog'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import {
  useGetAccountPermissionDetailQuery,
  useGetMainMenuListMutation,
  useGetSubMenuListMutation,
  useUpdateAccountPermissionMutation
} from 'store/assignment'
import { useGetMenuTreeQuery } from 'store/menu'
import { useGetRolesListDropdownsssMutation } from 'store/roles'
import type { PartialMenuPermissionMenusProps, PartialMenuPermissionProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import DialogStyle from './style'

type SubMenuProps = {
  menu?: string
  name: string
  id: number
  functions: RolesAssignmentFunctionProps[]
  convertedFunctions: string
}

const Edit = ({ open, id, onClose }: DetailStateProps & { id: string }) => {
  if (!open) return <></>

  const {
    data: dataAccount,
    isFetching: isAccountFetching,
    isLoading: isAccountLoading
  } = useGetAccountPermissionDetailQuery(id)

  const {
    data: dataMenu,
    isFetching: isMenuFetching,
    isLoading: isMenuLoading
  } = useGetMenuTreeQuery()

  const [getRolesListDropdown, rolesList] = useGetRolesListDropdownsssMutation()
  const [getMainMenuList, mainMenuList] = useGetMainMenuListMutation()
  const [getSubMenuList, subMenuList] = useGetSubMenuListMutation()
  const [updateAccountPermission, updateAccount] = useUpdateAccountPermissionMutation()

  const [isDeleteButtonPressed, setIsDeleteButtonPressed] = useState(false)
  const [isAddEnabled, setIsAddEnabled] = useState<boolean>(false)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true)
  const [selectedMenuId, setSelectedMenuId] = useState<number | null>(null)

  const selectedRole =
    rolesList &&
    rolesList.data &&
    rolesList.data.find((el) => el.name == (dataAccount && dataAccount.roleName))

  const selectedMainMenu =
    dataMenu &&
    dataMenu.mainMenu &&
    dataMenu.mainMenu
      ?.map((main) => {
        const subMenu = main.subMenu?.map((sMenu) => {
          const functions = sMenu?.listFunction?.filter(
            (func) => dataAccount && dataAccount.permissions.includes(func.name)
          )

          const filtered = functions?.map((func) => ({
            id: func.functionId,
            name: func.name,
            type: func.type
          }))

          let parentLevel1Id: number | undefined = undefined
          let parentLevel2Id: number | undefined = undefined
          switch (sMenu.level) {
            case 2:
              parentLevel1Id = main.mainMenuId
              break

            case 3: {
              const parentPathSegments = sMenu.path.split('/')
              parentPathSegments.pop()

              parentLevel1Id = main.mainMenuId
              parentLevel2Id = main.subMenu.find((m) => m.path === parentPathSegments.join('/'))
                ?.mainMenuId
              break
            }
          }

          const list = {
            menu: main.name,
            name: sMenu.name,
            id: sMenu.mainMenuId,
            parentLevel1Id,
            parentLevel2Id,
            functions: filtered,
            convertedFunctions: filtered?.map((func) => func.name).join(', ')
          }

          return list
        })

        const filteredMainMenuFunctions = main.listFunction
          .map((f) => ({ id: f.functionId, name: f.name, type: f.type }))
          .filter((f) => dataAccount?.permissions.includes(f.name))

        const mainMenu = filteredMainMenuFunctions.length
          ? {
              menu: main.name,
              name: main.name,
              id: main.mainMenuId,
              parentLevel1Id: undefined,
              parentLevel2Id: undefined,
              functions: filteredMainMenuFunctions,
              convertedFunctions: filteredMainMenuFunctions?.map((func) => func.name).join(', ')
            }
          : undefined

        const filtered = [mainMenu, ...subMenu.filter((sub) => sub.functions?.length)].filter(
          Boolean
        )
        return filtered
      })
      .filter((menu) => menu.length)
      .flat(1)

  const subMenuColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 72 },
    {
      field: 'parentLevel1Id',
      headerName: 'Parent Id',
      width: 72,
      sortable: false
    },
    { field: 'name', headerName: 'Sub Menu', width: 240 }
  ]

  const functionNameColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 96 },
    { field: 'name', headerName: 'Function Name', width: 240 }
  ]

  const selectedColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 96 },
    { field: 'menu', headerName: 'Main Menu', width: 160 },
    { field: 'name', headerName: 'Sub Menu', width: 160 },
    { field: 'convertedFunctions', headerName: 'Function Name', width: 256 },
    {
      field: 'Action',
      headerName: 'Action',
      width: 96,
      sortable: false,
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <IconButton onClick={() => onDeleteSelectionRow(row)}>
          <Icon icon='DeleteForever' />
        </IconButton>
      )
    }
  ]

  const [functionNameRows] = useState<{ id: string; name: string }[]>([
    { id: 'CREATE', name: 'Create' },
    { id: 'READ', name: 'View' },
    { id: 'UPDATE', name: 'Edit / Update' },
    { id: 'EXPORT', name: 'Export' }
  ])

  const [selectedSubMenu, setSelectedSubMenu] = useState<(RolesAssignmentMenuProps | undefined)[]>(
    selectedMainMenu as (RolesAssignmentMenuProps | undefined)[]
  )

  const [selectedFunction, setSelectedFunction] =
    useState<({ id: string; name: string } | undefined)[]>(functionNameRows)

  const [selectedRow, setSelectedRow] = useState<(RolesAssignmentMenuProps | undefined)[]>(
    selectedMainMenu as (RolesAssignmentMenuProps | undefined)[]
  )

  const onSubMenuSelectionRow = (ids: GridRowSelectionModel) => {
    const subMenu = subMenuList && subMenuList.data
    const list = isEmpty(selectedRow) ? subMenu : selectedRow.concat(subMenu)

    const SelectionRow = ids?.map((id) => list && list.find((row) => row && row.id == id))

    // Cek jika ada parentLevel1Id atau parentLevel2Id yang sama dengan id dari SelectionRow
    const parentIds = SelectionRow.flatMap((row) => [
      row?.parentLevel1Id,
      row?.parentLevel2Id
    ]).filter((id) => id !== undefined)

    const additionalRows = list?.filter((row) => parentIds.includes(row?.id as number))

    const finalSelectionRow = [...SelectionRow, ...(additionalRows || [])]

    setSelectedSubMenu(finalSelectionRow)
    setIsAddEnabled(
      finalSelectionRow.some((sub) => sub !== undefined) &&
        selectedFunction.some((func) => func !== undefined)
    )
  }

  const onFunctionSelectionRow = (ids: GridRowSelectionModel) => {
    const selectedIds = ids as string[]

    // Jika CREATE, UPDATE, atau EXPORT dipilih, pastikan READ juga tercentang
    if (
      selectedIds.includes('CREATE') ||
      selectedIds.includes('UPDATE') ||
      selectedIds.includes('EXPORT')
    ) {
      if (!selectedIds.includes('READ')) {
        selectedIds.push('READ')
      }
    }

    const SelectionRow = selectedIds.map((id) => functionNameRows.find((row) => row.id === id))
    setSelectedFunction(SelectionRow)
    setIsAddEnabled(
      selectedSubMenu.some((sub) => sub !== undefined) &&
        SelectionRow.some((func) => func !== undefined)
    )
  }

  const onAddSelectionRow = () => {
    const menu =
      mainMenuList &&
      mainMenuList.data &&
      mainMenuList.data.find((menu) => formik.values.menuId == menu.id)

    const functionId = selectedFunction?.map((func) => func && func.id)
    const currentListedId =
      subMenuList && subMenuList.data && subMenuList.data.map((el) => el && el.id)

    const selected = selectedSubMenu?.map((sub) => {
      if (sub) {
        const filteredFunctions = sub.functions.filter((func) => {
          if (!functionId || !func.type) return false
          return functionId.includes(func.type.toUpperCase())
        })

        const result: SubMenuProps = {
          menu: sub.menu,
          name: sub.name,
          id: sub.id,
          functions: filteredFunctions,
          convertedFunctions: sub.functions
            .filter((func) => functionId.includes(func.type.toUpperCase()))
            .map(({ name }) => name)
            .join(', ')
        }

        if (currentListedId && currentListedId.includes(sub.id)) {
          result.menu = menu && menu.name
        }

        return result
      }

      return undefined
    })

    const merged = (selectedRow ?? []).concat(selected)
    const filter = merged.filter(
      (obj, id) => obj && merged.findIndex((item) => (item && item.id) == obj.id) == id
    )

    // Cek jika ada parentLevel1Id atau parentLevel2Id yang sama dengan id dari filter
    const parentIds = filter
      .flatMap((row) => [row?.parentLevel1Id, row?.parentLevel2Id])
      .filter((id) => id !== undefined)

    const additionalRows = subMenuList?.data?.filter((row) => parentIds.includes(row?.id))

    const finalSelectionRow = [...filter, ...(additionalRows || [])]

    setSelectedRow(finalSelectionRow)

    if (!isEmpty(finalSelectionRow)) {
      setIsSubmitDisabled(false)
    }
  }

  const onDeleteSelectionRow = (row: RolesAssignmentMenuProps) => {
    const removedParentIds: number[] = []

    // Find level 2 parent ID.
    const level2Parent = selectedRow.find((r) => r?.id === row.parentLevel2Id)
    if (level2Parent) {
      // Get direct children for level 2 parent.
      const childrenMenu = selectedRow.filter(
        (r) => r?.id !== row.id && r?.parentLevel2Id === level2Parent.id
      )

      if (!childrenMenu.length) removedParentIds.push(level2Parent.id)
    }

    // Find level 1 parent ID.
    const level1Parent = selectedRow.find((r) => r?.id === row.parentLevel1Id)
    if (level1Parent) {
      // Get direct children for level 1 parent.
      const childrenMenu = selectedRow.filter(
        (r) =>
          r?.id !== row.id &&
          r?.parentLevel1Id === level1Parent.id &&
          r.parentLevel2Id === undefined
      )

      if (!childrenMenu.length) removedParentIds.push(level1Parent.id)
    }

    const SelectionRow = selectedRow
      .filter((r) => r && r.id !== row.id)
      .filter((r) => r?.parentLevel1Id !== row.id && r?.parentLevel2Id !== row.id)
      .filter((r) => !removedParentIds.includes(r?.id ?? 0))
    setSelectedRow(SelectionRow)
    setIsDeleteButtonPressed(true)

    const updatedSubMenu = selectedSubMenu.filter((sub) => sub && sub.id !== row.id)
    setSelectedSubMenu(updatedSubMenu)

    const updatedFunction = selectedFunction.filter((func) =>
      row.functions.some((f) => f.type !== func?.id)
    )
    setSelectedFunction(updatedFunction)

    // Cek jika ada parentLevel1Id atau parentLevel2Id yang sama dengan id dari row yang dihapus
    // const parentIds = [row.parentLevel1Id, row.parentLevel2Id].filter((id) => id !== undefined)
    // const list = isEmpty(selectedRow) ? subMenuList.data : selectedRow.concat(subMenuList.data)
    // const additionalRows = list?.filter((row) => parentIds.includes(row?.id as number))
    // const finalSelectionRow = [...SelectionRow, ...(additionalRows || [])]

    // Jika selectedRow kosong setelah penghapusan, disable tombol Submit
    if (isEmpty(SelectionRow)) {
      setIsSubmitDisabled(true)
    } else {
      setSelectedRow(SelectionRow)
    }
  }

  const convertMenuPermissionMenus = (values: PartialMenuPermissionProps) => {
    const data = { ...values }
    delete data.menuId

    const mainMenu =
      selectedMainMenu &&
      selectedMainMenu.map((main) => main && main.functions?.map((func) => func.id))

    const selectedList = selectedRow?.map(
      (selected) => selected && selected.functions?.map((func) => func.id)
    )

    const selectedFlattened = selectedList.flat(1)
    const selectMapped = selectedFlattened?.map((id) => ({ FunctionId: id }))

    const listMenu = mainMenu && mainMenu.flat(1)
    const listMapped = listMenu && listMenu?.map((id) => ({ FunctionId: id }))

    const resultFromList =
      listMapped &&
      listMapped.map((el) => ({
        FunctionId: el.FunctionId,
        Action: selectMapped?.map((menu) => menu.FunctionId).includes(el.FunctionId)
          ? 'INSERT'
          : 'DELETE'
      }))

    const resultFromSelect =
      selectMapped &&
      selectMapped.map((el) => ({
        FunctionId: el.FunctionId as number,
        Action: 'INSERT'
      }))

    const merged = resultFromList && resultFromList.concat(resultFromSelect)
    const result =
      merged &&
      merged.filter(
        (obj, id) =>
          obj &&
          merged &&
          merged.findIndex((item) => (item && item.FunctionId) == obj.FunctionId) == id
      )

    return {
      ...data,
      Menus: result as PartialMenuPermissionMenusProps[]
    }
  }

  const onSubmit = (values: PartialMenuPermissionProps) => {
    const menus = convertMenuPermissionMenus(values)
    updateAccountPermission(menus)
  }

  const scheme = yup.object<PartialMenuPermissionProps>({
    userId: yup.string().required('Assign is required'),
    roleId: yup.number().positive('RoleId is required').required('RoleId is required'),
    menuId: yup.number().notRequired(),
    Menus: yup.array().of(
      yup.object({
        FunctionId: yup
          .number()
          .positive('FunctionId is required')
          .required('FunctionId is required'),
        Action: yup.string().required('Action is required')
      })
    )
  })

  const formik = useFormik<PartialMenuPermissionProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      userId: (dataAccount && dataAccount.userId) || '',
      roleId: (selectedRole && selectedRole.pkId) || 0,
      menuId: selectedMenuId || 0, // Inisialisasi dengan ID pertama jika ada
      Menus: []
    },
    onSubmit: onSubmit
  })

  // const selectedMainMenuItem =
  //   mainMenuList.data && mainMenuList.data.find((menu) => menu.id === formik.values.menuId)
  // console.log('selectedMainMenuItem', selectedMainMenuItem)

  useEffect(() => {
    getRolesListDropdown()
    if (!isEmpty(selectedRow)) {
      setIsSubmitDisabled(false)
    } else {
      setIsSubmitDisabled(true)
    }
  }, [selectedRow])

  if (
    isEmpty(selectedRow) &&
    selectedMainMenu &&
    selectedMainMenu.length &&
    !isDeleteButtonPressed
  ) {
    setIsSubmitDisabled(true)
    setSelectedRow(selectedMainMenu)
  }

  if (
    isEmpty(selectedSubMenu) &&
    !selectedSubMenu &&
    selectedRow &&
    selectedRow.length &&
    !isDeleteButtonPressed
  ) {
    setSelectedSubMenu(selectedRow)
  }

  return (
    <>
      <Dialog
        title='Edit Role Assignment'
        open={open}
        maxWidth='md'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={rolesList.isLoading || updateAccount.isLoading}
        isDisabled={!formik.isValid || isSubmitDisabled}
      >
        <Container {...DialogStyle.Container}>
          {(isAccountLoading || isAccountFetching) &&
            !dataAccount &&
            (isMenuLoading || isMenuFetching) &&
            !dataMenu && <Loading />}
          {dataAccount && dataMenu && (
            <>
              {selectedRole && (
                <Autocomplete
                  options={rolesList.data || []}
                  getOptionLabel={(list) => list.name}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.pkId == value.pkId : false
                  }
                  value={selectedRole}
                  readOnly
                  disabled={true}
                  ListboxProps={GlobalStyle.ListBox}
                  renderOption={(props, item) => (
                    <li {...props} key={item.pkId}>
                      {item.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='roleId'
                      label='Select A Role'
                      value={formik.values.userId}
                      error={
                        formik.touched && formik.touched.roleId && Boolean(formik.errors.roleId)
                      }
                      helperText={
                        formik.touched &&
                        formik.touched.roleId &&
                        formik.errors &&
                        formik.errors.roleId
                      }
                      InputProps={{
                        ...params.InputProps,
                        readOnly: true,
                        endAdornment: (
                          <>
                            {rolesList.isLoading && <CircularProgress color='inherit' size={20} />}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
              )}
              <TextField
                id='userId'
                variant='outlined'
                label='Assign Access To'
                disabled={true}
                value={dataAccount && dataAccount.fullName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.userId && Boolean(formik.errors.userId)}
                helperText={
                  formik.touched && formik.touched.userId && formik.errors && formik.errors.userId
                }
                inputProps={{ readOnly: true }}
                fullWidth
              />
              <Typography {...DialogStyle.Permission}>Permissions</Typography>
              <Autocomplete
                options={mainMenuList.data || []}
                getOptionLabel={(list) => list.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.id == value.id : false
                }
                onOpen={() => getMainMenuList()}
                onChange={(_, id) => {
                  formik.setFieldValue('menuId', id && id.id)
                  setSelectedMenuId(id && id.id)
                  if (id && id.id !== null) {
                    getSubMenuList(id.id)
                    setSelectedSubMenu([])
                  }
                }}
                // value={selectedMainMenuItem}
                onBlur={formik.handleBlur}
                ListboxProps={GlobalStyle.ListBox}
                renderOption={(props, item) => (
                  <li {...props} key={item.id}>
                    {item.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='menuId'
                    label='Select Main Menu'
                    error={formik.touched && formik.touched.menuId && Boolean(formik.errors.menuId)}
                    helperText={
                      formik.touched &&
                      formik.touched.menuId &&
                      formik.errors &&
                      formik.errors.menuId
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {mainMenuList.isLoading && <CircularProgress color='inherit' size={20} />}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
              <Grid container columnSpacing={2}>
                <Grid item xs={6}>
                  <DataGrid
                    rows={(subMenuList && subMenuList.data) || []}
                    columns={subMenuColumns}
                    rowSelectionModel={
                      selectedSubMenu && (selectedSubMenu?.map((el) => el && el.id) as GridRowId[])
                    }
                    onRowSelectionModelChange={onSubMenuSelectionRow}
                    keepNonExistentRowsSelected
                    checkboxSelection
                    hideFooter
                    autoHeight
                  />
                </Grid>
                {subMenuList && subMenuList.data && (
                  <Grid item xs={6}>
                    <DataGrid
                      rows={functionNameRows}
                      columns={functionNameColumns}
                      rowSelectionModel={selectedFunction?.map((el) => el && el.id) as GridRowId[]}
                      onRowSelectionModelChange={onFunctionSelectionRow}
                      checkboxSelection
                      hideFooter
                      autoHeight
                    />
                  </Grid>
                )}
              </Grid>
              <Button
                variant='contained'
                disabled={!isAddEnabled}
                // disabled={selectedSubMenu?.length === 0 || selectedFunction?.length === 0}
                onClick={onAddSelectionRow}
                style={{ display: isAddEnabled ? 'block' : 'none' }} // Control visibility
                // style={{
                //   display:
                //     selectedSubMenu?.length > 0 && selectedFunction?.length > 0 ? 'block' : 'none'
                // }}
              >
                Add
              </Button>
              {selectedRow && !isEmpty(selectedRow) && (
                <DataGrid rows={selectedRow} columns={selectedColumns} hideFooter autoHeight />
              )}
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!updateAccount.isLoading && !updateAccount.isUninitialized}
        onClose={() => (updateAccount.isError ? updateAccount.reset() : location.reload())}
        isError={updateAccount.isError}
        message={GLOBAL.returnExceptionMessage(
          updateAccount.isError,
          updateAccount.error as ErrorProps
        )}
      />
    </>
  )
}

export type RolesAssignmentMenuProps = {
  id: number
  menu?: string
  name: string
  parentLevel1Id?: number
  parentLevel2Id?: number
  functions: RolesAssignmentFunctionProps[]
  convertedFunctions?: string
}

export type RolesAssignmentFunctionProps = {
  id: number
  name: string
  type: string
}

export default Edit
