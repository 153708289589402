import { Box, Chip, Container, Grid, TextField, Typography } from '@mui/material'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import { useGetAccountPermissionDetailQuery } from 'store/assignment'
import type { DetailStateProps } from 'modules/types'
import DetailStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: string }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetAccountPermissionDetailQuery(id)

  const userId = (data && data.userId) || '-'
  const email = (data && data.email) || '-'
  const fullName = (data && data.fullName) || '-'
  const roleName = (data && data.roleName) || '-'
  const phone = (data && data.phone) || '-'
  // const permissions = (data && data.permissions && data.permissions.join('\r\n')) || '-'
  const permissions = (data && data.permissions && data.permissions.join('\r\n')) || '-'

  const gridDataLabel = [
    'User Id',
    'Email',
    'Full Name',
    'Role Name',
    'Phone Number',
    'Permissions'
  ]
  const gridDataValue = [userId, email, fullName, roleName, phone, permissions]

  return (
    <Drawer open={open} title='Role Assignment Detail' onClose={onClose}>
      <Container {...DetailStyle.Container}>
        {(isLoading || isFetching) && !data && <Loading />}
        {data && (
          <Grid container spacing={2}>
            {gridDataValue.map((item, index) => (
              <Grid key={index} item xs={12}>
                <TextField
                  variant='outlined'
                  label={gridDataLabel[index]}
                  value={item}
                  inputProps={{ readOnly: true }}
                  multiline
                  fullWidth
                />
              </Grid>
            ))}
          </Grid>
        )}
        <Grid container spacing={2}>
          <Grid item xs={2.5}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}>
              <Typography sx={{ marginLeft: '8px', color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Status</Typography>
              <Chip
                label={data?.status === 'ENABLED' ? 'Enabled' : 'Disabled'}
                color={data?.status === 'ENABLED' ? 'success' : 'error'}
              />
            </Box>
          </Grid>
        </Grid>

      </Container>
    </Drawer>
  )
}

export default Detail
