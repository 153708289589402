import { createSlice } from '@reduxjs/toolkit'
import type { UserProps } from 'modules/types'

const initialState: UserProps = {
  token: '',
  refreshToken: '',
  validUntil: '',
  user: {
    email: '',
    fullname: '',
    info1: null,
    info2: null,
    info3: null,
    member: null,
    origin: null,
    permissions: [],
    phone: '',
    sessionId: '',
    type: null,
    userCode: '',
    username: null
  },
  is2FaEnabled: null
}

const UserSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    getUser: (state, action) => (state = action.payload)
  }
})

export const { getUser } = UserSlice.actions
export default UserSlice.reducer
