export enum DatePeriod {
  Weekly = 1,
  Monthly,
  Yearly,
  Custom
}

export enum UserPermission {
  Read = 1,
  Create,
  Update,
  Export
}
