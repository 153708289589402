import CONSTANT from 'modules/constant'
import { API, headers, headersOtp } from 'store/api'
import type { PartialUserProps, PartialRegister2FAProps, PartialVerify2FAProps } from 'modules/partial'
import type { Register2FAProps, UserProps, Verify2FAProps } from 'modules/types'

const userAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    loginUser: builder.mutation<UserProps, PartialUserProps>({
      query: (body) => ({
        url: CONSTANT.URL_LOGIN,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    logoutUser: builder.mutation({
      query: () => ({
        url: CONSTANT.URL_LOGOUT,
        method: 'POST',
        headers: headers()
      })
    }),
    tfaRegister: builder.mutation<Register2FAProps, {body: PartialRegister2FAProps, token: string}>({
      query: ({body, token}) => ({
        url: CONSTANT.URL_2FA_REGISTER,
        method: 'POST',
        headers: headersOtp({token: token}),
        body: body
      })
    }),
    tfaVerify: builder.mutation<Verify2FAProps, {body: PartialVerify2FAProps, token: string}>({
      query: ({body, token}) => ({
        url: CONSTANT.URL_2FA_VERIFY,
        method: 'POST',
        headers: headersOtp({token: token}),
        body: body
      })
    })
  })
})

export const { useLoginUserMutation, useLogoutUserMutation, useTfaRegisterMutation, useTfaVerifyMutation } = userAPI
