import { useState } from 'react'
import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { DatePeriod } from 'modules/enums'
import type { DashboardHeaderProps } from 'modules/types'
import { AppDatePicker } from 'components/appDatePicker/appDatePicker'

export const DashboardHeader = ({ payload, setPayload }: DashboardHeaderProps) => {
  const [period, setPeriod] = useState<DatePeriod>(payload.period)

  const [dates, setDates] = useState<[Date?, Date?]>(payload.dates)

  const getDatePicker = () => {
    switch (period) {
      case DatePeriod.Weekly:
        return <AppDatePicker type='week' date={dates[0]} onChange={changeDate} />
      case DatePeriod.Monthly:
        return <AppDatePicker type='month' date={dates[0]} onChange={changeDate} />
      case DatePeriod.Yearly:
        return <AppDatePicker type='year' date={dates[0]} onChange={changeDate} />
      default:
        return <AppDatePicker type='date-range' date={dates} onChange={changeDate} />
    }
  }

  const changeDate = (dates: [Date?, Date?]) => {
    setDates(dates)
    setPayload({ period, dates })
  }

  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid item>
        <ToggleButtonGroup
          value={period}
          exclusive
          onChange={(_, value: number) => {
            setPeriod(value)
            setDates(payload.period === value ? payload.dates : [undefined, undefined])
          }}
          aria-label='Date period'
        >
          {Object.keys(DatePeriod)
            .filter((k) => !isNaN(Number(k)))
            .map(Number)
            .map((k, id) => (
              <ToggleButton key={id + 1} value={k} aria-label={DatePeriod[k]}>
                {DatePeriod[k]}
              </ToggleButton>
            ))}
        </ToggleButtonGroup>
      </Grid>

      <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
        {getDatePicker()}
      </Grid>
    </Grid>
  )
}
