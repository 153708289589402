import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { InputAdornment, OutlinedInput } from '@mui/material'
import { CalendarMonth } from '@mui/icons-material'
import type { AppDatePickerProps } from 'modules/types'

import 'react-datepicker/dist/react-datepicker.css'
import './style.scss'

const SHORT_DATE_DFNS_FORMAT = 'd MMM yyyy'
const SHORT_DATE_FORMAT = 'D MMM YYYY'
const MONTH_FORMAT = 'MMM YYYY'

const inputComponent = (
  <OutlinedInput
    size='small'
    sx={{ width: '18rem' }}
    endAdornment={
      <InputAdornment position='end'>
        <CalendarMonth />
      </InputAdornment>
    }
  />
)

export const AppDatePicker = ({ type, date, onChange }: AppDatePickerProps) => {
  const [dates, setDates] = useState<[Date?, Date?]>([undefined, undefined])

  useEffect(() => {
    if (type !== 'date-range') return

    setDates(date ?? [undefined, undefined])
  }, [type])

  switch (type) {
    case 'date-range':
      return (
        <DatePicker
          dateFormat={SHORT_DATE_DFNS_FORMAT}
          popperPlacement='bottom-end'
          useWeekdaysShort
          selectsRange
          monthsShown={2}
          customInput={inputComponent}
          placeholderText='Select date'
          startDate={dates?.[0] ?? undefined}
          endDate={dates?.[1] ?? undefined}
          onCalendarClose={() => {
            if (!dates[0] || !dates[1]) {
              setDates([undefined, undefined])
              return
            }

            onChange?.(dates)
          }}
          onChange={(date) => setDates([date?.[0] ?? undefined, date?.[1] ?? undefined])}
          onFocus={(e) => e.target.setAttribute('readonly', '')}
        />
      )

    case 'week':
      return (
        <DatePicker
          dateFormat={SHORT_DATE_DFNS_FORMAT}
          popperPlacement='bottom-end'
          useWeekdaysShort
          showWeekNumbers
          showWeekPicker
          customInput={inputComponent}
          placeholderText='Select week'
          value={
            date &&
            getDateRange(date, 'week')
              .map((d) => d.format(SHORT_DATE_FORMAT))
              .join(' - ')
          }
          selected={date}
          onChange={(date) =>
            onChange?.(
              date
                ? (getDateRange(date, 'week').map((d) => d.toDate()) as [Date, Date])
                : [undefined, undefined]
            )
          }
          onFocus={(e) => e.target.setAttribute('readonly', '')}
        />
      )

    case 'month':
      return (
        <DatePicker
          useWeekdaysShort
          showMonthYearPicker
          customInput={inputComponent}
          placeholderText='Select month'
          value={
            date &&
            getDateRange(date, 'month')
              .map((d) => d.format(SHORT_DATE_FORMAT))
              .join(' - ')
          }
          selected={date}
          onChange={(date) =>
            onChange?.(
              date
                ? (getDateRange(date, 'month').map((d) => d.toDate()) as [Date, Date])
                : [undefined, undefined]
            )
          }
          onFocus={(e) => e.target.setAttribute('readonly', '')}
        />
      )

    case 'year':
      return (
        <DatePicker
          useWeekdaysShort
          showYearPicker
          yearItemNumber={9}
          customInput={inputComponent}
          placeholderText='Select year'
          value={
            date &&
            getDateRange(date, 'year')
              .map((d) => d.format(MONTH_FORMAT))
              .join(' - ')
          }
          selected={date}
          onChange={(date) =>
            onChange?.(
              date
                ? (getDateRange(date, 'year').map((d) => d.toDate()) as [Date, Date])
                : [undefined, undefined]
            )
          }
          onFocus={(e) => e.target.setAttribute('readonly', '')}
        />
      )

    default:
      return (
        <DatePicker
          dateFormat={SHORT_DATE_DFNS_FORMAT}
          popperPlacement='bottom-end'
          useWeekdaysShort
          customInput={inputComponent}
          placeholderText='Select date'
          selected={date}
          onChange={(date) => onChange?.(date ?? undefined)}
          onFocus={(e) => e.target.setAttribute('readonly', '')}
        />
      )
  }
}

const getDateRange = (date: Date, unit: moment.unitOfTime.Base) => [
  moment(date).startOf(unit),
  moment(date).endOf(unit)
]
