import React, { useEffect, useState } from 'react'
import { Box, Grid, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { BoltIcon, UserIcon, UserPlusIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import { LaunchOutlined } from '@mui/icons-material'
import GLOBAL from 'modules/global'
import { DatePeriod } from 'modules/enums'
import type { DashboardChartPayload } from 'modules/types'
import {
  useLazyGetNewSubscriberQuery,
  useLazyGetRenewedSubscriptionQuery,
  useLazyGetRevenueQuery,
  useLazyGetSubscriptionChurnRateQuery,
  useLazyGetSubscriptionRenewalRateQuery,
  useLazyGetTotalRevenueByTypeQuery,
  useLazyGetTotalSubscriptionPLanQuery,
  useLazyGetTotalSubscriptionQuery,
  useLazyGetTotalSubscriptionStatusQuery,
  useLazyGetTotalTransactionPaidByTypeQuery,
  useLazyGetTotalUserByTypeQuery,
  useLazyGetTotalUserGrowthQuery,
  useLazyGetTotalUsersQuery,
  useLazyGetTransactionPaymentMethodQuery
} from 'store/dashboard'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import { AreaChart } from 'components/charts/area'
import { GaugeChart } from 'components/charts/gauge'
import { NumberChart } from 'components/charts/number'
import { BarChart } from 'components/charts/bar'
import { DonutChart } from 'components/charts/donut'
import { incrementValue } from 'components/charts/incrementValue'
import { DashboardHeader } from './header'
import { DashboardEmpty } from './empty'

const Dashboard = () => {
  const navigate = useNavigate()
  const [getRevenue, revenueValue] = useLazyGetRevenueQuery()
  const [getSubscriptionRenewalRate, subscriptionRenewalRateValue] =
    useLazyGetSubscriptionRenewalRateQuery()
  const [getSubscriptionChurnRate, subscriptionChurnRateValue] =
    useLazyGetSubscriptionChurnRateQuery()
  const [getTotalSubscriptionPLan, totalSubscriptionPLanValue] =
    useLazyGetTotalSubscriptionPLanQuery()
  const [getTotalUserGrowth, totalUserGrowthValue] = useLazyGetTotalUserGrowthQuery()
  const [getNewSubscriber, newSubscriberValue] = useLazyGetNewSubscriberQuery()
  const [getRenewedSubscription, renewedSubscriptionValue] = useLazyGetRenewedSubscriptionQuery()
  const [getTotalTransactionPaidByType, totalTransactionPaidByTypeValue] =
    useLazyGetTotalTransactionPaidByTypeQuery()
  const [getTotalUserByType, totalUserByTypeValue] = useLazyGetTotalUserByTypeQuery()
  const [getTotalRevenueByType, totalRevenueByTypeValue] = useLazyGetTotalRevenueByTypeQuery()
  const [getTransactionPaymentMethod, transactionPaymentMethodValue] =
    useLazyGetTransactionPaymentMethodQuery()
  const [getTotalSubscriptionStatus, totalSubscriptionStatusValue] =
    useLazyGetTotalSubscriptionStatusQuery()
  const [getTotalSubscription, totalSubscriptionValue] = useLazyGetTotalSubscriptionQuery()
  const [getTotalUsers, totalUsersValue] = useLazyGetTotalUsersQuery()

  const currentYear = moment().year()
  const [payload, setPayload] = useState<DashboardChartPayload>({
    period: DatePeriod.Weekly,
    dates: [moment(`${currentYear}-01-01`).toDate(), moment(`${currentYear}-12-30`).toDate()], // Default dates
    Type: 'region'
  })
  const [transactionType, setTransactionType] = useState<string>('region')
  const [totalUserType, setTotalUserType] = useState<string>('region')
  const [revenueType, setRevenueType] = useState<string>('region')
  const [loadingRevenue, setLoadingRevenue] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingNumberOne, setLoadingNumberOne] = useState<boolean>(false)
  const [loadingNumberTwo, setLoadingNumberTwo] = useState<boolean>(false)
  const [loadingNumberThird, setLoadingNumberThird] = useState<boolean>(false)
  const [loadingTransactionPaidByType, setLoadingTransactionPaidByType] = useState<boolean>(false)
  const [loadingTotalUserByType, setLoadingTotalUserByType] = useState<boolean>(false)
  const [loadingTotalRevenueByType, setLoadingTotalRevenueByType] = useState<boolean>(false)

  // const handleSeriesChange = (seriesName: string) => {
  //   let newType: string;

  //   // Map series names to payload types
  //   switch (seriesName) {
  //     case 'By Region':
  //       newType = 'region';
  //       break;
  //     case 'By Car Model':
  //       newType = 'car';
  //       break;
  //     case 'By Age':
  //       newType = 'age';
  //       break;
  //     default:
  //       newType = 'region'; // Default value if none match
  //   }

  //   setPayload(prevPayload => ({
  //     ...prevPayload,
  //     Type: newType // Update the payload with the selected series type
  //   }));
  // };
  const handleTransactionTypeChange = (seriesName: string) => {
    let newType: string
    switch (seriesName) {
      case 'By Region':
        newType = 'region'
        break
      case 'By Car Model':
        newType = 'car'
        break
      case 'By Age':
        newType = 'age'
        break
      default:
        newType = 'region'
    }
    setTransactionType(newType)
  }

  const handleUserTypeChange = (seriesName: string) => {
    let newType: string
    switch (seriesName) {
      case 'By Region':
        newType = 'region'
        break
      case 'By Car Model':
        newType = 'car'
        break
      case 'By Age':
        newType = 'age'
        break
      default:
        newType = 'region'
    }
    setTotalUserType(newType)
  }

  const handleRevenueTypeChange = (seriesName: string) => {
    let newType: string
    switch (seriesName) {
      case 'By Region':
        newType = 'region'
        break
      case 'By Car Model':
        newType = 'car'
        break
      case 'By Age':
        newType = 'age'
        break
      default:
        newType = 'region'
    }
    setRevenueType(newType)
  }

  useEffect(() => {
    const breadcrumbHeader = document.getElementById('breadcrumbHeader')
    if (breadcrumbHeader) breadcrumbHeader.style.display = 'none'

    return () => {
      if (breadcrumbHeader) breadcrumbHeader.style.removeProperty('display')
    }
  }, [])

  useEffect(() => {
    setLoadingRevenue(true)
    Promise.all([
      getRevenue({
        StartDate: moment(payload.dates[0]).format('YYYY-MM-DD'),
        EndDate: moment(payload.dates[1]).format('YYYY-MM-DD'),
        Duration: DatePeriod[payload.period] ? DatePeriod[payload.period] : 'Yearly'
      })
    ])
      .then(() => {
        setLoadingRevenue(false)
      })
      .catch(() => {
        setLoadingRevenue(false)
      })
  }, [JSON.stringify(payload.period), JSON.stringify(payload.dates), DatePeriod[payload.period]])

  useEffect(() => {
    setLoading(true)
    console.log('Payload for API:', DatePeriod[payload.period], payload.dates)

    Promise.all([
      getSubscriptionRenewalRate({
        StartDate: moment(payload.dates[0]).format('YYYY-MM-DD'),
        EndDate: moment(payload.dates[1]).format('YYYY-MM-DD')
      }),
      getSubscriptionChurnRate({
        StartDate: moment(payload.dates[0]).format('YYYY-MM-DD'),
        EndDate: moment(payload.dates[1]).format('YYYY-MM-DD')
      }),
      getTotalSubscriptionPLan({
        StartDate: moment(payload.dates[0]).format('YYYY-MM-DD'),
        EndDate: moment(payload.dates[1]).format('YYYY-MM-DD'),
        Duration: DatePeriod[payload.period] ? DatePeriod[payload.period] : 'Yearly'
      }),
      getTransactionPaymentMethod({
        StartDate: moment(payload.dates[0]).format('YYYY-MM-DD'),
        EndDate: moment(payload.dates[1]).format('YYYY-MM-DD'),
        Duration: DatePeriod[payload.period] ? DatePeriod[payload.period] : 'Yearly'
      }),
      getTotalSubscriptionStatus({
        StartDate: moment(payload.dates[0]).format('YYYY-MM-DD'),
        EndDate: moment(payload.dates[1]).format('YYYY-MM-DD')
      }),
      getTotalSubscription({
        StartDate: moment(payload.dates[0]).format('YYYY-MM-DD'),
        EndDate: moment(payload.dates[1]).format('YYYY-MM-DD')
      }),
      getTotalUsers({
        StartDate: moment(payload.dates[0]).format('YYYY-MM-DD'),
        EndDate: moment(payload.dates[1]).format('YYYY-MM-DD')
      })
    ])
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [JSON.stringify(payload.period), JSON.stringify(payload.dates)])

  useEffect(() => {
    setLoadingNumberOne(true)
    Promise.all([
      getTotalUserGrowth({
        StartDate: moment(payload.dates[0]).format('YYYY-MM-DD'),
        EndDate: moment(payload.dates[1]).format('YYYY-MM-DD'),
        Duration: DatePeriod[payload.period] ? DatePeriod[payload.period] : 'Yearly'
      })
    ])
      .then(() => {
        setLoadingNumberOne(false)
      })
      .catch(() => {
        setLoadingNumberOne(false)
      })
  }, [JSON.stringify(payload.period), JSON.stringify(payload.dates), DatePeriod[payload.period]])

  useEffect(() => {
    setLoadingNumberTwo(true)
    Promise.all([
      getNewSubscriber({
        StartDate: moment(payload.dates[0]).format('YYYY-MM-DD'),
        EndDate: moment(payload.dates[1]).format('YYYY-MM-DD'),
        Duration: DatePeriod[payload.period] ? DatePeriod[payload.period] : 'Yearly'
      })
    ])
      .then(() => {
        setLoadingNumberTwo(false)
      })
      .catch(() => {
        setLoadingNumberTwo(false)
      })
  }, [JSON.stringify(payload.period), JSON.stringify(payload.dates), DatePeriod[payload.period]])

  useEffect(() => {
    setLoadingNumberThird(true)
    Promise.all([
      getRenewedSubscription({
        StartDate: moment(payload.dates[0]).format('YYYY-MM-DD'),
        EndDate: moment(payload.dates[1]).format('YYYY-MM-DD'),
        Duration: DatePeriod[payload.period] ? DatePeriod[payload.period] : 'Yearly'
      })
    ])
      .then(() => {
        setLoadingNumberThird(false)
      })
      .catch(() => {
        setLoadingNumberThird(false)
      })
  }, [JSON.stringify(payload.period), JSON.stringify(payload.dates), DatePeriod[payload.period]])

  useEffect(() => {
    setLoadingTransactionPaidByType(true)
    getTotalTransactionPaidByType({
      StartDate: moment(payload.dates[0]).format('YYYY-MM-DD'),
      EndDate: moment(payload.dates[1]).format('YYYY-MM-DD'),
      // Type: payload.Type || 'region'
      Type: transactionType
    })
      .then(() => {
        setLoadingTransactionPaidByType(false)
      })
      .catch(() => {
        setLoadingTransactionPaidByType(false)
      })
  }, [JSON.stringify(payload.dates), transactionType])

  useEffect(() => {
    setLoadingTotalUserByType(true)
    getTotalUserByType({
      StartDate: moment(payload.dates[0]).format('YYYY-MM-DD'),
      EndDate: moment(payload.dates[1]).format('YYYY-MM-DD'),
      Type: totalUserType
    })
      .then(() => {
        setLoadingTotalUserByType(false)
      })
      .catch(() => {
        setLoadingTotalUserByType(false)
      })
  }, [JSON.stringify(payload.dates), totalUserType])

  useEffect(() => {
    setLoadingTotalRevenueByType(true)
    getTotalRevenueByType({
      StartDate: moment(payload.dates[0]).format('YYYY-MM-DD'),
      EndDate: moment(payload.dates[1]).format('YYYY-MM-DD'),
      Type: revenueType
    })
      .then(() => {
        setLoadingTotalRevenueByType(false)
      })
      .catch(() => {
        setLoadingTotalRevenueByType(false)
      })
  }, [JSON.stringify(payload.dates), revenueType])

  // const growthValueNumberChart = Number(totalUserGrowthValue.data?.growth)
  // let formattedGrowthValue
  // if (growthValueNumberChart === 0) {
  //   formattedGrowthValue = '0'
  // } else {
  //   formattedGrowthValue = !isNaN(growthValueNumberChart)
  //     ? growthValueNumberChart.toFixed(1)
  //     : '0.0'
  // }

  // const growthValueNumberChartTwo = Number(newSubscriberValue.data?.growth)
  // let formattedGrowthValueTwo
  // if (growthValueNumberChartTwo === 0) {
  //   formattedGrowthValueTwo = '0'
  // } else {
  //   formattedGrowthValueTwo = !isNaN(growthValueNumberChartTwo)
  //     ? growthValueNumberChartTwo.toFixed(1)
  //     : '0.0'
  // }

  // const growthValueNumberChartThird = Number(renewedSubscriptionValue.data?.growth)
  // let formattedGrowthValueThird
  // if (growthValueNumberChartThird === 0) {
  //   formattedGrowthValueThird = '0'
  // } else {
  //   formattedGrowthValueThird = !isNaN(growthValueNumberChartThird)
  //     ? growthValueNumberChartThird.toFixed(1)
  //     : '0.0'
  // }

  const totalValue = totalSubscriptionPLanValue.data
    ? totalSubscriptionPLanValue.data.data.reduce((acc, item) => acc + Number(item.valueQty), 0)
    : 0
  const totalValueStatus = totalSubscriptionStatusValue.data
    ? totalSubscriptionStatusValue.data.data.reduce((acc, item) => acc + Number(item.value), 0)
    : 0
  const totalValueTotalSubs = totalSubscriptionValue.data
    ? totalSubscriptionValue.data.data.reduce((acc, item) => acc + Number(item.value), 0)
    : 0
  const totalValueTotalUsers = totalUsersValue.data
    ? totalUsersValue.data.data.reduce((acc, item) => acc + Number(item.value), 0)
    : 0

  return (
    <>
      <Breadcrumb sideComponent={<DashboardHeader payload={payload} setPayload={setPayload} />} />

      <Box p={2}>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} md={7}>
            <Box position='relative' p={2} bgcolor={'white'} height='100%'>
              <Box minHeight={400}>
                {loadingRevenue ? (
                  <DashboardEmpty title='Revenue' loading />
                ) : revenueValue.data?.valuePerMonth.length ? (
                  <Box flex={1}>
                    <Box sx={{ position: 'absolute', right: '1rem', zIndex: 1 }}>
                      <IconButton onClick={() => navigate('/subscription/transaction')}>
                        <LaunchOutlined sx={{ color: 'black' }} />
                      </IconButton>
                    </Box>

                    <AreaChart
                      title={getRevenueTitle(
                        Number(revenueValue.data?.valuePerYear),
                        Number(revenueValue.data?.percentageGrowth),
                        payload.dates
                      )}
                      valuePerMonth={revenueValue.data.valuePerMonth.map((item) => ({
                        name: item.name,
                        value: Number(item.value || 0),
                        subs: Number(item.subs || 0)
                      }))}
                    />
                  </Box>
                ) : (
                  <DashboardEmpty title='Revenue' />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={6}>
                <Box position='relative' p={2} bgcolor={'white'} height='100%'>
                  <Box minHeight={200}>
                    {loading ? (
                      <DashboardEmpty loading />
                    ) : subscriptionRenewalRateValue.data ? (
                      <>
                        <Box sx={{ position: 'absolute', right: '1rem', zIndex: 1 }}>
                          <IconButton onClick={() => navigate('/subscription/transaction')}>
                            <LaunchOutlined sx={{ color: 'black' }} />
                          </IconButton>
                        </Box>

                        <GaugeChart
                          color='#00aad2'
                          name={subscriptionRenewalRateValue.data.name}
                          value={Number(subscriptionRenewalRateValue.data.value ?? '0')}
                          tooltip='Percentage of users who decided to subscribe/ renew their subscription'
                        />
                      </>
                    ) : (
                      <DashboardEmpty />
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box position='relative' p={2} bgcolor={'white'} height='100%'>
                  <Box minHeight={200}>
                    {loading ? (
                      <DashboardEmpty loading />
                    ) : subscriptionChurnRateValue.data ? (
                      <>
                        <Box sx={{ position: 'absolute', right: '1rem', zIndex: 1 }}>
                          <IconButton
                            onClick={() =>
                              navigate('/subscription/user-subscription/paid-subscription')
                            }
                          >
                            <LaunchOutlined sx={{ color: 'black' }} />
                          </IconButton>
                        </Box>

                        <GaugeChart
                          color='#e63312'
                          name={subscriptionChurnRateValue.data.name}
                          value={Number(subscriptionChurnRateValue.data.value ?? '0')}
                          tooltip='Percentage of users who decided not to subscribe/ renew their subscription'
                        />
                      </>
                    ) : (
                      <DashboardEmpty />
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box position='relative' p={2} bgcolor={'white'} height='100%'>
                  <Box minHeight={160}>
                    {loadingNumberOne ? (
                      <DashboardEmpty loading />
                    ) : totalUserGrowthValue.data ? (
                      <>
                        <Box sx={{ position: 'absolute', right: '1rem', zIndex: 1 }}>
                          <IconButton
                            onClick={() =>
                              navigate('/subscription/user-subscription/paid-subscription')
                            }
                          >
                            <LaunchOutlined sx={{ color: 'black' }} />
                          </IconButton>
                        </Box>

                        <NumberChart
                          icon={<UserIcon style={{ height: 30, width: 30, color: '#036672' }} />}
                          color='#d5f5f6'
                          data={{
                            name: totalUserGrowthValue.data.name || 'Total Users',
                            value: Number(totalUserGrowthValue.data.valueQty ?? '0'),
                            growth: Number(totalUserGrowthValue.data.growth ?? '0')
                          }}
                        />
                      </>
                    ) : (
                      <DashboardEmpty />
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box position='relative' p={2} bgcolor={'white'} height='100%'>
                  <Box minHeight={160}>
                    {loadingNumberTwo ? (
                      <DashboardEmpty loading />
                    ) : newSubscriberValue.data ? (
                      <>
                        <Box sx={{ position: 'absolute', right: '1rem', zIndex: 1 }}>
                          <IconButton onClick={() => navigate('/subscription/transaction')}>
                            <LaunchOutlined sx={{ color: 'black' }} />
                          </IconButton>
                        </Box>

                        <NumberChart
                          icon={
                            <UserPlusIcon style={{ height: 30, width: 30, color: '#046c4e' }} />
                          }
                          color='#def7ec'
                          tooltip='Free to Paid users'
                          data={{
                            name: newSubscriberValue.data.name || 'New Subscribers',
                            value: Number(newSubscriberValue.data.valueQty ?? 0),
                            growth: Number(newSubscriberValue.data.growth ?? 0)
                          }}
                        />
                      </>
                    ) : (
                      <DashboardEmpty />
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box position='relative' p={2} bgcolor={'white'} height='100%'>
                  <Box minHeight={160}>
                    {loadingNumberThird ? (
                      <DashboardEmpty loading />
                    ) : renewedSubscriptionValue.data ? (
                      <>
                        <Box sx={{ position: 'absolute', right: '1rem', zIndex: 1 }}>
                          <IconButton onClick={() => navigate('/subscription/transaction')}>
                            <LaunchOutlined sx={{ color: 'black' }} />
                          </IconButton>
                        </Box>

                        <NumberChart
                          icon={<BoltIcon style={{ height: 30, width: 30, color: '#1a56db' }} />}
                          color='#e1effe'
                          tooltip='Paid to Paid users'
                          data={{
                            name: renewedSubscriptionValue.data.name || 'Renewed Subscribers',
                            value: Number(renewedSubscriptionValue.data.valueQty ?? 0),
                            growth: Number(renewedSubscriptionValue.data.growth ?? 0)
                          }}
                        />
                      </>
                    ) : (
                      <DashboardEmpty />
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} md={4}>
            <Box position='relative' p={2} bgcolor={'white'} height='100%'>
              <BarChart
                title='Total Transaction Paid'
                actionButton={
                  <Box sx={{ position: 'absolute', right: '1rem', zIndex: 1 }}>
                    <IconButton onClick={() => navigate('/subscription/transaction')}>
                      <LaunchOutlined sx={{ color: 'black' }} />
                    </IconButton>
                  </Box>
                }
                loading={loadingTransactionPaidByType}
                onSeriesChange={handleTransactionTypeChange}
                transactionData={totalTransactionPaidByTypeValue.data}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box position='relative' p={2} bgcolor={'white'} height='100%'>
              <BarChart
                title='Total Users'
                actionButton={
                  <Box sx={{ position: 'absolute', right: '1rem', zIndex: 1 }}>
                    <IconButton
                      onClick={() => navigate('/subscription/user-subscription/paid-subscription')}
                    >
                      <LaunchOutlined sx={{ color: 'black' }} />
                    </IconButton>
                  </Box>
                }
                loading={loadingTotalUserByType}
                onSeriesChange={handleUserTypeChange}
                transactionData={totalUserByTypeValue.data}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box position='relative' p={2} bgcolor={'white'} height='100%'>
              <BarChart
                title='Total Revenue'
                actionButton={
                  <Box sx={{ position: 'absolute', right: '1rem', zIndex: 1 }}>
                    <IconButton onClick={() => navigate('/subscription/transaction')}>
                      <LaunchOutlined sx={{ color: 'black' }} />
                    </IconButton>
                  </Box>
                }
                loading={loadingTotalRevenueByType}
                onSeriesChange={handleRevenueTypeChange}
                transactionData={totalRevenueByTypeValue.data}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} md={6}>
            <Box position='relative' p={2} bgcolor={'white'} height='100%'>
              <Box minHeight={300}>
                {loading ? (
                  <DashboardEmpty title='Total Subscription by Plan' loading />
                ) : totalSubscriptionPLanValue.data?.data.length ? (
                  <>
                    <Box sx={{ position: 'absolute', right: '1rem', zIndex: 1 }}>
                      <IconButton onClick={() => navigate('/subscription/user-subscription/paid-subscription')}>
                        <LaunchOutlined sx={{ color: 'black' }} />
                      </IconButton>
                    </Box>

                    <DonutChart
                      title='Total Subscription by Plan'
                      total={totalValue}
                      data={totalSubscriptionPLanValue.data.data.map((item) => ({
                        name: item.name,
                        value: Number(item.valueQty),
                        valueAmount: Number(item.valueAmount),
                        growth: Number(item.growth)
                      }))}
                    />
                  </>
                ) : (
                  <DashboardEmpty title='Total Subscription by Plan' />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box position='relative' p={2} bgcolor={'white'} height='100%'>
              <Box minHeight={300}>
                {loading ? (
                  <DashboardEmpty title='Transaction Paid by Payment Method' loading />
                ) : transactionPaymentMethodValue.data?.length ? (
                  <>
                    <Box sx={{ position: 'absolute', right: '1rem', zIndex: 1 }}>
                      <IconButton onClick={() => navigate('/subscription/transaction')}>
                        <LaunchOutlined sx={{ color: 'black' }} />
                      </IconButton>
                    </Box>

                    <DonutChart
                      type='pie'
                      title='Transaction Paid by Payment Method'
                      data={transactionPaymentMethodValue.data.map((item) => ({
                        name: item.name,
                        value: Number(item.valueQty),
                        valueAmount: Number(item.valueAmount),
                        growth: Number(item.growth)
                      }))}
                    />
                  </>
                ) : (
                  <DashboardEmpty title='Transaction Paid by Payment Method' />
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box position='relative' p={2} bgcolor={'white'} height='100%'>
              <Box minHeight={300}>
                {loading ? (
                  <DashboardEmpty title='Total Subscription by Payment Status' loading />
                ) : totalSubscriptionStatusValue.data?.data.length ? (
                  <>
                    <Box sx={{ position: 'absolute', right: '1rem', zIndex: 1 }}>
                      <IconButton onClick={() => navigate('/subscription/transaction')}>
                        <LaunchOutlined sx={{ color: 'black' }} />
                      </IconButton>
                    </Box>

                    <DonutChart
                      title='Total Subscription by Payment Status'
                      colorType='color2'
                      data={totalSubscriptionStatusValue.data.data.map((item) => ({
                        name: item.name,
                        value: Number(item.value)
                      }))}
                      total={totalValueStatus}
                    />
                  </>
                ) : (
                  <DashboardEmpty title='Total Subscription by Payment Status' />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box position='relative' p={2} bgcolor={'white'} height='100%'>
              <Box minHeight={300}>
                {loading ? (
                  <DashboardEmpty title='Total Subscription by Status' loading />
                ) : totalSubscriptionValue.data?.data.length ? (
                  <>
                    <Box sx={{ position: 'absolute', right: '1rem', zIndex: 1 }}>
                      <IconButton onClick={() => navigate('/subscription/user-subscription/paid-subscription')}>
                        <LaunchOutlined sx={{ color: 'black' }} />
                      </IconButton>
                    </Box>

                    <DonutChart
                      title='Total Subscription by Status'
                      colorType='color2'
                      data={totalSubscriptionValue.data.data.map((item) => ({
                        name: item.name,
                        value: Number(item.value)
                      }))}
                      total={totalValueTotalSubs}
                    />
                  </>
                ) : (
                  <DashboardEmpty title='Total Subscription by Status' />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box position='relative' p={2} bgcolor={'white'} height='100%'>
              <Box minHeight={300}>
                {loading ? (
                  <DashboardEmpty title='Total User' loading />
                ) : totalUsersValue.data?.data.length ? (
                  <>
                    <Box sx={{ position: 'absolute', right: '1rem', zIndex: 1 }}>
                      <IconButton
                        onClick={() =>
                          navigate('/subscription/user-subscription/paid-subscription')
                        }
                      >
                        <LaunchOutlined sx={{ color: 'black' }} />
                      </IconButton>
                    </Box>

                    <DonutChart
                      title='Total User'
                      colorType='color2'
                      data={totalUsersValue.data.data.map((item) => ({
                        name: item.name,
                        value: Number(item.value)
                      }))}
                      total={totalValueTotalUsers}
                    />
                  </>
                ) : (
                  <DashboardEmpty title='Total User' />
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const getRevenueTitle = (value: number, incValue: number, dates: [Date?, Date?]): string =>
  [
    '<div style="color: #999; font-size: 0.9rem; font-weight: normal; margin-bottom: 1rem">',
    dates[0] && dates[1]
      ? `<div style="margin: 0">Revenue ${moment(dates[0]).format('D MMM YYYY')} - ${moment(
          dates[1]
        ).format('D MMM YYYY')}</div>`
      : '',
    '<div style="display: flex; align-items: center; gap: 0.5rem">',
    `<span style="color: #111928; font-size: 2.25rem; font-weight: 500">Rp ${GLOBAL.formatCurrency(
      value
    )}</span>`,
    incrementValue(incValue, true) as string,
    `<span> VS LAST YEAR</span>`,
    '</div>',
    '</div>'
  ].join('')

export default Dashboard
