import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import moment from 'moment'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import { useGetUsersAccountDetailsssQuery } from 'store/control'
import type { DetailStateProps } from 'modules/types'
import DetailStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: string }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetUsersAccountDetailsssQuery(id)

  const userId = (data && data.userCode) || '-'
  const email = (data && data.email) || '-'
  const fullName = (data && data.fullname) || '-'
  const mobilePhone = (data && data.mobileNumber) || '-'

  const gridDataLabel = ['ID', 'Email', 'Full Name', 'Phone Number']
  const gridDataValue = [userId, email, fullName, mobilePhone]

  const formatDateWithTime = (value: string) =>
    value ? moment(value).format('DD MMM YYYY HH:mm') : '-';

  const createdBy = (data && data.createdByName) || ''
  const createdAt = (data && formatDateWithTime(data.createdDate)) || '-'
  const modifiedBy = (data && data.modifiedByName) || ''
  const modifiedAt = (data && formatDateWithTime(data.modifiedDate)) || ''
  const statusLabel = data?.status === null ? '-' : (data?.status === 'ENABLED' ? 'Enabled' : 'Disabled');
  const statusColor = data?.status === 'ENABLED' ? 'success' : 'error';

  return (
    <Drawer open={open} title='Detail User' onClose={onClose}>
      <Container {...DetailStyle.Container}>
        {(isLoading || isFetching) && !data && <Loading />}
        {data && (
          <>
            <Grid container spacing={2}>
              {gridDataValue.map((item, index) => (
                <Grid key={index} item xs={12}>
                  <TextField
                    variant='outlined'
                    label={gridDataLabel[index]}
                    value={item}
                    inputProps={{ readOnly: true }}
                    multiline
                    fullWidth
                  />
                </Grid>
              ))}
            </Grid>
            <Grid container>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  paddingLeft: '8px'
                }}>
                {data?.status !== null ? (
                  <>
                    <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Status</Typography>
                    <Chip
                      sx={{ marginTop: '3px' }}
                      label={statusLabel}
                      color={statusColor}
                    />
                  </>
                ) : (
                  <>
                    <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Status</Typography>
                    <Typography>{statusLabel}</Typography>
                  </>
                )}
              </Box>
            </Grid>
          </>
        )}
        {data && (
          <Accordion elevation={0} disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls='basic-information'>
              <Typography>Basic Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ marginTop: '4px', }}>
                  <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Created By</Typography>
                  <Typography>{createdBy}</Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: '4px', }}>
                  <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Last Modified By</Typography>
                  <Typography>{modifiedBy}</Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: '4px', }}>
                  <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Created Date</Typography>
                  <Typography>{createdAt}</Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: '4px', }}>
                  <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Last Modified Date</Typography>
                  <Typography>{modifiedAt}</Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </Container>
    </Drawer>
  )
}

export default Detail
