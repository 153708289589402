import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  MenuItem,
  Chip,
  IconButton,
  Menu,
  TableCell,
  TableRow,
  Autocomplete,
  TextField,
  CircularProgress
} from '@mui/material'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import Icon from 'components/icon'
import CONSTANT from 'modules/constant'
import Loading from 'components/loading/loading'
import GlobalStyle from 'modules/styles'
import {
  useDownloadAccountPermissionListMutation,
  useGetAccountPermissionListsssMutation
} from 'store/assignment'
import { useGetRolesListAllDropdownMutation } from 'store/roles'
// import type { PartialRolesAssignmentProps } from 'modules/partial'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps } from 'modules/types'
import useActiveMenu from 'modules/useActiveMenu'
import { UserPermission } from 'modules/enums'
import Create from './create/create'
import Detail from './detail/detail'
import Edit from './edit/edit'

const Assignment = () => {
  const { hasPermission } = useActiveMenu()

  const [getAccountPermissionList, account] = useGetAccountPermissionListsssMutation()
  const [downloadAccountPermissionList, download] = useDownloadAccountPermissionListMutation()
  const [getRolesListDropdown, rolesList] = useGetRolesListAllDropdownMutation()

  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [menu, setMenu] = useState<null | number>(null)

  const [filter, setFilter] = useState({
    status: '',
    type: ''
  })

  const [payload, setPayload] = useState<PartialRolesAssignmentProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_ROLES_MANAGEMENT_FIELD.join(','),
    search: '',
    status: '',
    type: ''
  })

  const onFilter = (state: boolean) => setDialogFilter(state)
  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: value })

  const onResync = () => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search)) formData.append('search', payload.search)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)
    if (!isEmpty(payload.type)) formData.append('type', payload.type)

    getAccountPermissionList(formData)
  }

  const onCreate = () => setDialogCreate(true)

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('Length', String(account.data?.recordsTotal))
    formData.append('field', payload.field)

    if (!isEmpty(payload.search)) formData.append('search', payload.search)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)
    if (!isEmpty(payload.type)) formData.append('type', payload.type)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadAccountPermissionList(formData)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const tableHeadTitles = ['Name', 'Email', 'Phone Number', 'Role', 'Permission', 'Status']

  const openAnchor = Boolean(anchor)

  const totalPage = account.data && GLOBAL.tableTotalPage(account.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title='Role Assignment'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New Role Assignment'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={account.data && account.data.recordsTotal}
        page={payload.start + 1}
        isLoading={account.isLoading}
        useDownloadDropdown
      >
        {account.isSuccess &&
          account.data &&
          account.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.userId}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.userId}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.userId}`}
                  aria-labelledby={`button-${index}-${table.userId}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  {hasPermission(UserPermission.Update) && (
                    <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                      Edit
                    </MenuItem>
                  )}
                </Menu>
                <Detail
                  id={table.userId}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
                <Edit
                  id={table.userId}
                  open={dialogUpdate && menu == index}
                  onClose={() => setDialogUpdate(false)}
                />
              </TableCell>
              <TableCell>{table.fullName || '-'}</TableCell>
              <TableCell>{table.email || '-'}</TableCell>
              <TableCell>{table.phone || '-'}</TableCell>
              <TableCell>{table.roleName || '-'}</TableCell>
              <TableCell {...GlobalStyle.EllipsisTable}>{table.permissions}</TableCell>
              <TableCell>
                <Chip
                  label={table.status === 'ENABLED' ? 'Enabled' : 'Disabled'}
                  color={table.status == 'ENABLED' ? 'success' : 'error'}
                />
              </TableCell>
            </TableRow>
          ))}
      </Content>

      {account.isLoading && <Loading />}

      <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />

      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setFilter({
            status: '',
            type: ''
          })
          setPayload({
            ...payload,
            start: 0,
            status: '',
            type: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={EnabledDisabledValues}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onChange={(_, renewalStatus) =>
            setFilter({
              ...filter,
              status: (renewalStatus && String(renewalStatus.id)) || ''
            })
          }
          value={EnabledDisabledValues.find((e) => String(e.id) == filter.status) || undefined}
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='status'
              label='Status'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {rolesList.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={rolesList.data || []}
          getOptionLabel={(list) => list.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.name == value.name : false
          }
          onOpen={() => getRolesListDropdown()}
          onInputChange={(_, type) => setFilter({ ...filter, type })}
          value={rolesList && rolesList.data && rolesList.data.find((e) => e.name == filter.type)}
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.pkId}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='Role'
              label='Select A Role'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {rolesList.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Filter>

      <Notification
        open={!account.isLoading && !account.isUninitialized && !account.isSuccess}
        onClose={() => (account.isError ? account.reset() : location.reload())}
        isError={Boolean(account.error) && account.isError}
        message={GLOBAL.returnExceptionMessage(account.isError, account.error as ErrorProps)}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />
    </>
  )
}

export type PartialRolesAssignmentProps = PartialFormDataProps & {
  status: string
  type: string
}

export type PartialFormDataProps = {
  start: number
  length: number
  field: string
  search: string
  status?: string | Blob
}

const EnabledDisabledValues = [
  { id: 'ENABLED', name: 'Enabled' },
  { id: 'DISABLED', name: 'Disabled' }
]

export default Assignment
