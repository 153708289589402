import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
// import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import CONSTANT from 'modules/constant'
import GLOBAL from 'modules/global'
// import type { PartialPromoCodeBatchProps } from 'modules/partial'
import GlobalStyle from 'modules/styles'
import type { ErrorProps } from 'modules/types'
import {
  useDownloadPromoCodeBatchMutation,
  useGetPromoCodeBatchListMutation,
  useUpdateStatusPromoCodeBatchMutation
} from 'store/promoCodeBatch'
import MenuModalItem from 'components/menuItem/menuItem'
import {
  useGetApplicableSubsPlanDropdownMutation,
  useGetMemberTypeDropdownsssMutation
} from 'store/dropdown'
import { useGetDiscountTypeDropdownMutation } from 'store/discountSetting'
import useActiveMenu from 'modules/useActiveMenu'
import { UserPermission } from 'modules/enums'
import Create from './create/create'
import Detail from './detail/detail'
import Edit from './edit/edit'

const PromoBatchCategory = () => {
  const { hasPermission } = useActiveMenu()

  const navigate = useNavigate()
  const [getPromoCodeBatchList, promoCodeBatch] = useGetPromoCodeBatchListMutation()
  const [downloadPromoCodeBatch, download] = useDownloadPromoCodeBatchMutation()
  const [updateStatusDiscount, update] = useUpdateStatusPromoCodeBatchMutation()
  const [getApplicableSubsPlanDropdown, applicableSubsPlanDropdown] =
    useGetApplicableSubsPlanDropdownMutation()
  const [getCarModelDropdown, carModelList] = useGetMemberTypeDropdownsssMutation()
  const [getDiscountTypeDropdown, DiscountTypeDropdown] = useGetDiscountTypeDropdownMutation()
  const [messageUntil, setMessageUntil] = useState<string>('')

  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [randomNumber, setRandomNumber] = useState({
    numberStatus: 0,
    rewardNumber: 0
  })
  const [payload, setPayload] = useState<PartialPromoCodeBatchProps>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: '',
    SubscriptionPlan: '',
    CarModel: '',
    PromoType: '',
    Status: '',
    MaxRedemption: '',
    ValidFrom: '',
    ValidUntil: ''
  })

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [filter, setFilter] = useState<{
    SubscriptionPlan: string
    CarModel: string
    PromoType: string
    Status: string
    MaxRedemption: string
    ValidFrom: string
    ValidUntil: string
  }>({
    SubscriptionPlan: '',
    CarModel: '',
    PromoType: '',
    Status: '',
    MaxRedemption: '',
    ValidFrom: '',
    ValidUntil: ''
  })
  // const [date, setDate] = useState<{
  //   ValidFrom: Dayjs | null
  //   ValidUntil: Dayjs | null
  // }>({
  //   ValidFrom: null,
  //   ValidUntil: null
  // })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: value })
  const onResync = () => {
    const formData = new FormData()
    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.SubscriptionPlan))
      formData.append('SubscriptionPlan', payload.SubscriptionPlan)
    if (!isEmpty(payload.CarModel)) formData.append('CarModel', payload.CarModel)
    if (!isEmpty(payload.PromoType)) formData.append('PromoType', payload.PromoType)
    if (!isEmpty(payload.Status)) formData.append('Status', payload.Status)
    if (!isEmpty(payload.MaxRedemption)) formData.append('MaxRedemption', payload.MaxRedemption)
    if (!isEmpty(payload.ValidFrom)) formData.append('ValidFrom', payload.ValidFrom)
    if (!isEmpty(payload.ValidUntil)) formData.append('ValidUntil', payload.ValidUntil)

    getPromoCodeBatchList(formData)
  }

  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })
  const onFilter = (state: boolean) => {
    setDialogFilter(state), setMessageUntil('')
  }
  const onDownload = (type?: string) => {
    const formData = new FormData()
    formData.append('Start', String(0))
    formData.append('Length', String(promoCodeBatch.data?.recordsTotal))
    
    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.SubscriptionPlan))
      formData.append('SubscriptionPlan', payload.SubscriptionPlan)
    if (!isEmpty(payload.CarModel)) formData.append('CarModel', payload.CarModel)
    if (!isEmpty(payload.PromoType)) formData.append('PromoType', payload.PromoType)
    if (!isEmpty(payload.Status)) formData.append('Status', payload.Status)
    if (!isEmpty(payload.MaxRedemption)) formData.append('MaxRedemption', payload.MaxRedemption)
    if (!isEmpty(payload.ValidFrom)) formData.append('ValidFrom', payload.ValidFrom)
    if (!isEmpty(payload.ValidUntil)) formData.append('ValidUntil', payload.ValidUntil)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadPromoCodeBatch(formData)
  }
  const onCreate = () => setDialogCreate(true)

  useEffect(() => {
    onResync()
    setMessageUntil('')
  }, [payload])

  const tableHeadTitles = [
    'Promo Name',
    'Prefix',
    'Suffix Length',
    'Quantity',
    'Max. Redemption per Promo Code',
    'Progress',
    'Notes',
    'Start Date',
    'End Date',
    'Promo Type',
    'Promo Amount',
    'Maximum Deduction',
    'Minimum Payment',
    'Applicable Subs Plan',
    'Car model',
    'Status'
  ]
  const numbers = GLOBAL.tableNumber(payload.Start)

  const totalPage =
    promoCodeBatch.data && GLOBAL.tableTotalPage(promoCodeBatch.data.recordsFiltered)

  const openAnchor = Boolean(anchor)

  // const applicableSubsPlanList = (subsPlan: SubscriptionPlanDataProps[]) => {
  //   if (!Array.isArray(subsPlan) || subsPlan.length === 0) {
  //     return '-';
  //   }
  //   return subsPlan.map(plan => plan.subscriptionPlanName).join(', ') || '-';
  // };

  // const carNames = (carModels: CarModelDataProps[]) => {
  //   if (!Array.isArray(carModels) || carModels.length === 0) {
  //     return '-';
  //   }
  //   return carModels.map(car => car.carName).join(', ') || '-';
  // };

  // const setValue = (value: number, promoType: string) => {
  //   if (promoType !== 'Deduct Price') {
  //     return `${value} %`
  //   } else {
  //     return `Rp ${GLOBAL.formatCurrency(value)}`
  //   }
  // }

  const isEndDateGreaterThanStartDate = () => {
    if (messageUntil && dayjs(filter.ValidFrom).isAfter(dayjs(filter.ValidUntil))) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <Content
        title='Promo Code Batch'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New Promo Batch General'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={promoCodeBatch.data && promoCodeBatch.data.recordsFiltered}
        page={payload.Start + 1}
        isLoading={promoCodeBatch.isLoading}
        useDownloadDropdown
      >
        {promoCodeBatch.isSuccess &&
          promoCodeBatch.data &&
          promoCodeBatch.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.pkId}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.pkId}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.pkId}`}
                  aria-labelledby={`button-${index}-${table.pkId}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem
                    onClick={() =>
                      onRemoveAnchor(() =>
                        navigate('list', {
                          state: { id: table.pkId.toString(), name: table.prefix }
                        })
                      )
                    }
                  >
                    Open
                  </MenuItem>

                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>

                  {hasPermission(UserPermission.Update) && (
                    <MenuModalItem
                      title={table.status === 'DISABLED' ? 'Enable' : 'Disable'}
                      onClick={() =>
                        onRemoveAnchor(() =>
                          updateStatusDiscount({
                            Id: table.pkId,
                            IsActive: table.status == 'DISABLED'
                          })
                        )
                      }
                    >
                      {table.status == 'DISABLED' ? 'Enable' : 'Disable'}
                    </MenuModalItem>
                  )}

                  {hasPermission(UserPermission.Update) && (
                    <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                      Edit
                    </MenuItem>
                  )}
                </Menu>
                {drawerDetail && menu == index && (
                  <Detail
                    id={table.pkId}
                    open={drawerDetail && menu == index}
                    onClose={() => setDrawerDetail(false)}
                    status={table.status}
                  />
                )}
                {dialogUpdate && menu == index && (
                  <Edit
                    id={table.pkId}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )}
              </TableCell>
              <TableCell>{table.name || '-'}</TableCell>
              <TableCell>{table.prefix || '-'}</TableCell>
              <TableCell>{table.codeLength || '-'}</TableCell>
              <TableCell>{table.quantity || '-'}</TableCell>
              <TableCell>{table.maxRedemption || '-'}</TableCell>
              <TableCell>{table.progress || '-'}</TableCell>
              <TableCell>{table.notes || '-'}</TableCell>
              <TableCell>{GLOBAL.formatDateddMMYYLongDate(table.validFrom) || '-'}</TableCell>
              <TableCell>{GLOBAL.formatDateddMMYYLongDate(table.validUntil) || '-'}</TableCell>
              <TableCell>{table.promoType || '-'}</TableCell>
              {/* <TableCell>{setValue(table.value, table.promoType) || '-'}</TableCell> */}
              <TableCell>{table.value || '-'}</TableCell>
              <TableCell>{GLOBAL.formatCurrency(table.maximumDeduction) || '-'}</TableCell>
              <TableCell>{GLOBAL.formatCurrency(table.minimumPayment) || '-'}</TableCell>
              {/* <TableCell>{applicableSubsPlanList(table.subscriptionPlan)}</TableCell> */}
              <TableCell>{table.subscriptionPlan}</TableCell>
              {/* <TableCell>{carNames(table.carModel)}</TableCell> */}
              <TableCell>{table.carModel}</TableCell>
              <TableCell>
                <Chip
                  label={table.status === 'ENABLED' ? 'Enabled' : 'Disabled'}
                  color={table.status == 'ENABLED' ? 'success' : 'error'}
                />
              </TableCell>
            </TableRow>
          ))}
      </Content>
      {promoCodeBatch.isLoading && <Loading />}
      <Notification
        open={
          !promoCodeBatch.isLoading && !promoCodeBatch.isUninitialized && !promoCodeBatch.isSuccess
        }
        onClose={() => (promoCodeBatch.isError ? promoCodeBatch.reset() : location.reload())}
        isError={Boolean(promoCodeBatch.error) && promoCodeBatch.isError}
        message={GLOBAL.returnExceptionMessage(
          promoCodeBatch.isError,
          promoCodeBatch.error as ErrorProps
        )}
      />
      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />
      {dialogCreate && <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />}
      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter) || isEndDateGreaterThanStartDate()}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setPayload({
            Start: 0,
            Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
            Search: '',
            SubscriptionPlan: '',
            CarModel: '',
            Status: '',
            MaxRedemption: '',
            PromoType: '',
            ValidFrom: '',
            ValidUntil: ''
          })
          setFilter({
            SubscriptionPlan: '',
            CarModel: '',
            PromoType: '',
            Status: '',
            MaxRedemption: '',
            ValidFrom: '',
            ValidUntil: ''
          })
          // setDate({
          //   ValidFrom: null,
          //   ValidUntil: null
          // })
          setRandomNumber({
            numberStatus: randomNumber.numberStatus + 1,
            rewardNumber: randomNumber.rewardNumber + 1
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({
            ...payload,
            ...filter
          })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={(applicableSubsPlanDropdown && applicableSubsPlanDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.name == value.name : false
          }
          onOpen={() => getApplicableSubsPlanDropdown()}
          onChange={(_, reward) => {
            if (!isEmpty(reward)) setFilter({ ...filter, SubscriptionPlan: reward?.name })
          }}
          value={
            (applicableSubsPlanDropdown &&
              applicableSubsPlanDropdown.data &&
              applicableSubsPlanDropdown.data.find((e) => e.name == filter.SubscriptionPlan)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.name}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='SubscriptionPlan'
              label='Applicable Subscription Plan'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {applicableSubsPlanDropdown.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(carModelList && carModelList.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.name == value.name : false
          }
          onOpen={() => getCarModelDropdown()}
          onChange={(_, reward) => {
            if (!isEmpty(reward)) setFilter({ ...filter, CarModel: reward?.name })
          }}
          value={
            (carModelList &&
              carModelList.data &&
              carModelList.data.find((e) => e.name == filter.CarModel)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.name}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='CarModel'
              label='Car Model'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {carModelList.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(DiscountTypeDropdown && DiscountTypeDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.name == value.name : false
          }
          onOpen={() => getDiscountTypeDropdown()}
          onChange={(_, PromoType) => {
            if (!isEmpty(PromoType)) setFilter({ ...filter, PromoType: PromoType?.name })
          }}
          value={
            (DiscountTypeDropdown &&
              DiscountTypeDropdown.data &&
              DiscountTypeDropdown.data.find((e) => e.name == filter.PromoType)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.name}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='PromoType'
              label='Promo Type'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {DiscountTypeDropdown.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <FormControl fullWidth>
          <InputLabel id='select-label'>Status</InputLabel>
          <Select
            labelId='select-label'
            id='id-select-label'
            value={filter.Status}
            label='Status'
            onChange={(event) => setFilter({ ...filter, Status: event.target.value })}
          >
            <MenuItem value='ENABLED'>Enabled</MenuItem>
            <MenuItem value='DISABLED'>Disabled</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id='MaxRedemption'
          variant='outlined'
          label='Maximum Redemtion Amount'
          type='number'
          value={filter.MaxRedemption}
          onChange={(event) => setFilter({ ...filter, MaxRedemption: event.target.value })}
          fullWidth
        />
        <Typography>Period</Typography>
        <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              // value={date.ValidFrom}
              value={filter.ValidFrom ? dayjs(filter.ValidFrom) : null}
              sx={{ width: '190px' }}
              onChange={(e) => {
                const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                setFilter({ ...filter, ValidFrom: newDate })
                // setDate((prevState) => ({ ...prevState, minDateSignUp: e }))
              }}
              label='Start Date'
              format='MMM, DD YYYY'
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              // value={date.ValidUntil}
              value={filter.ValidUntil ? dayjs(filter.ValidUntil) : null}
              minDate={dayjs(filter.ValidFrom)}
              sx={{ width: '190px' }}
              onChange={(e) => {
                const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                setFilter({ ...filter, ValidUntil: newDate })
                // setDate((prevState) => ({ ...prevState, maxDateSignUp: e }))
              }}
              onError={(error) => {
                if (error) {
                  setMessageUntil(filter.ValidUntil)
                }
              }}
              label='End Date'
              format='MMM, DD YYYY'
            />
          </LocalizationProvider>
        </div>
        {messageUntil && dayjs(filter.ValidFrom).isAfter(dayjs(filter.ValidUntil)) && (
          <FormHelperText sx={{ alignSelf: 'center', marginTop: -2 }} error id='ValidUntil'>
            End Date must be greater than Start Date
          </FormHelperText>
        )}
      </Filter>
    </>
  )
}

export type PartialPromoCodeBatchProps = {
  Start: number
  Length: number
  Search: string
  SubscriptionPlan: string
  CarModel: string
  Status: string
  MaxRedemption: string
  PromoType: string
  ValidFrom: string
  ValidUntil: string
}

export type CarModelDataProps = {
  carModelId: number
  pkId: number
  carName: string
}

export type SubscriptionPlanDataProps = {
  pkId: number
  subscriptionPlanName: string
}

export default PromoBatchCategory
