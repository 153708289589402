import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import moment from 'moment'
import Icon from 'components/icon'
// import Loading from 'components/loading/loading'
import type { Children } from 'modules/types'
import { useGetDetailPromoCodeGeneralQuery } from 'store/promoCodeGeneral'
import Loading from 'components/loading/loading'
import GLOBAL from 'modules/global'
import DialogStyle from './style'

const Detail = ({ open, id, onClose, status }: DetailStatePlanProps & { id: number }) => {
  if (!open) return <></>

  const { data, isLoading, isFetching } = useGetDetailPromoCodeGeneralQuery(id)

  const setValue = (value: number, isPercent: string) => {
    if (isPercent !== 'Deduct Price') {
      return `${value} %`;
    } else {
      return `Rp ${GLOBAL.formatCurrency(value)}`;
    }
  };

  const promoCodeID = (data && data.pkId) || '-'
  const promonameEN = (data && data.promoNameEN) || '-'
  const promonameID = (data && data.promoNameID) || '-'
  const promoCode = (data && data.promoCode) || '-'
  const promoname = (data && data.name) || '-'
  const quantity = (data && data.quantity) || '-'
  const descrption = (data && data.description) || '-'
  const promoType = (data && data.isPercent) || '-'
  const promoAmount = (data && setValue(data.value, data.isPercent)) || '-'
  const minimumPayment = (data && GLOBAL.formatCurrency(data.minimumPayment)) || '-'
  const maximumDeduction = (data && GLOBAL.formatCurrency(data.maximumDeduction)) || '-'
  const validFrom = (data && GLOBAL.formatDateddMMYYLongDateDetail(data.validFrom)) || '-'
  const validUntil = (data && GLOBAL.formatDateddMMYYLongDateDetail(data.validUntil)) || '-'

  const applicableSubsPlanList = (subsPlan: subscriptionPlanList[]) => {
    if (!Array.isArray(subsPlan) || subsPlan.length === 0) {
      return '-';
    }
    return subsPlan.map(plan => plan.subscriptionPlanName).join(', ') || '-';
  };

  const subscriptionPlan = (data && applicableSubsPlanList(data.subscriptionPlan)) || '-'
  console.log('subscriptionPlan', subscriptionPlan);

  const carNames = (carModels: CarModelDataProps[]) => {
    if (!Array.isArray(carModels) || carModels.length === 0) {
      return '-';
    }
    return carModels.map(car => car.carName).join(', ') || '-';
  };
  const carModelData = (data && carNames(data.carModelData)) || '-'
  console.log('carModelData', carModelData);

  const gridDataLabelFirstLeft = [
    'Promo Code ID',
    'Promo Name EN-US',
    'Promo Code',
    'Quantity',
    'Promo Type',
    'Promo Amount'
  ]
  const gridDataValueFirstLeft = [
    promoCodeID,
    promonameEN,
    promoCode,
    quantity,
    promoType,
    promoAmount,
  ]

  const gridDataLabelRightOne = ['Promo Name ID', 'Promo Name']
  const gridDataValueRightOne = [promonameID, promoname]

  const gridDataLabelRightTwo = ['Minimum Payment']
  const gridDataValueRightTwo = [minimumPayment]


  const gridDataLabelSecondLeft = ['Start Date']
  const gridDataValueSecondLeft = [validFrom]
  const gridDataLabelSecondRight = ['End Date']
  const gridDataValueSecondRight = [validUntil]

  const formatDateWithTime = (value: string) =>
    value ? moment(value).format('DD MMM YYYY HH:mm') : '-';

  const createdBy = (data && data.createdByName) || '-'
  const createdAt = (data && formatDateWithTime(data.createdDate)) || '-'
  const modifiedBy = (data && data.modifiedByName) || '-'
  const modifiedAt = (data && formatDateWithTime(data.modifiedDate)) || '-'

  const statusLabel = status === null ? '-' : (status === 'ENABLED' ? 'Enabled' : 'Disabled');
  const statusColor = status === 'ENABLED' ? 'success' : 'error';

  return (
    <Dialog open={open} fullWidth maxWidth='md' onClose={onClose}>
      <DialogContent>
        <DialogTitle
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Typography variant='h6'>Detail Promo Code General</Typography>
          <Box>
            <IconButton onClick={onClose}>
              <Icon icon='Close' />
            </IconButton>
          </Box>
        </DialogTitle>
        {(isLoading || isFetching) && !data && <Loading />}

        {data && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{
              display: 'flex', flexDirection: 'row', alignItems: 'flex-start',
              justifyContent: 'space-between',
              position: 'relative'
            }}>
              <Box sx={{ padding: '10px 24px 24px 10px', flex: 1 }}>
                {gridDataValueFirstLeft.map((item, index) => (
                  <TextField
                    key={index}
                    {...DialogStyle.textField}
                    value={item}
                    variant='outlined'
                    inputProps={{ readOnly: true }}
                    label={gridDataLabelFirstLeft[index]}
                    fullWidth
                  />
                ))}
              </Box>
              <Box
                sx={{
                  flex: 1,
                  marginTop: '50px',
                }}
              >
                <Box sx={{ padding: '10px 24px' }}>
                  {gridDataValueRightOne.map((item, index) => (
                    <TextField
                      key={index}
                      {...DialogStyle.textField}
                      value={item}
                      variant='outlined'
                      inputProps={{ readOnly: true }}
                      label={gridDataLabelRightOne[index]}
                      fullWidth
                    />
                  ))}
                  <TextField
                    value={descrption}
                    id='Description'
                    variant='outlined'
                    label='Description'
                    multiline
                    rows={3.2}
                    inputProps={{ readOnly: true }}
                    {...DialogStyle.textField}
                    fullWidth
                  />
                  {gridDataValueRightTwo.map((item, index) => (
                    <TextField
                      key={index}
                      {...DialogStyle.textField}
                      value={item}
                      variant='outlined'
                      inputProps={{ readOnly: true }}
                      label={gridDataLabelRightTwo[index]}
                      fullWidth
                    />
                  ))}
                </Box>
              </Box>
            </Box>
            {promoType !== 'Deduct Price' && (
              <Box sx={{ paddingLeft: '10px', paddingRight: '24px', marginTop: -2 }}>
                <TextField
                  value={maximumDeduction}
                  id='maximumDeduction'
                  variant='outlined'
                  label='Maximum Deduction'
                  inputProps={{ readOnly: true }}
                  {...DialogStyle.textField}
                  fullWidth
                />
              </Box>
            )}
            <Box sx={{
              display: 'flex', flexDirection: 'row', alignItems: 'flex-start',
              justifyContent: 'space-between',
              position: 'relative',
              marginTop: promoType !== 'Deduct Price' ? 0 : -4
            }}>
              <Box sx={{ padding: '10px 24px 24px 10px', flex: 1 }}>
                {gridDataValueSecondLeft.map((item, index) => (
                  <TextField
                    key={index}
                    {...DialogStyle.textField}
                    value={item}
                    variant='outlined'
                    inputProps={{ readOnly: true }}
                    label={gridDataLabelSecondLeft[index]}
                    fullWidth
                  />
                ))}
                <Autocomplete
                  sx={{ maxWidth: '409px', marginBottom: '10px' }}
                  multiple
                  id='fixed-tags-demo'
                  defaultValue={(data && data.subscriptionPlan) || []}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.subscriptionPlanName == value.subscriptionPlanName : false
                  }
                  readOnly={true}
                  options={(data && data.subscriptionPlan) || []}
                  getOptionLabel={(option) => option.subscriptionPlanName}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip label={option.subscriptionPlanName} {...getTagProps({ index })} key={index} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField sx={{ maxWidth: '409px' }} {...params} label='Applicable Subs Plan' />
                  )}
                />
              </Box>
              <Box
                sx={{
                  flex: 1
                }}
              >
                <Box sx={{ padding: '10px 24px' }}>
                  {gridDataValueSecondRight.map((item, index) => (
                    <TextField
                      key={index}
                      {...DialogStyle.textField}
                      value={item}
                      variant='outlined'
                      inputProps={{ readOnly: true }}
                      label={gridDataLabelSecondRight[index]}
                      fullWidth
                    />
                  ))}
                  <Autocomplete
                    multiple
                    id='fixed-tags-demo'
                    defaultValue={(data && data.carModelData) || []}
                    isOptionEqualToValue={(option, value) =>
                      option && value ? option.carName == value.carName : false
                    }
                    readOnly={true}
                    options={(data && data.carModelData) || []}
                    getOptionLabel={(option) => option.carName}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip label={option.carName} {...getTagProps({ index })} key={index} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField sx={{ maxWidth: '409px' }} {...params} label='Car Model' />
                    )}
                  />
                </Box>
              </Box>
            </Box>
            <Accordion elevation={0} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                aria-controls='basic-information'
              >
                <Typography>Basic Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={1.7}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                      }}>
                      {status !== null ? (
                        <>
                          <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Status</Typography>
                          <Chip
                            label={statusLabel}
                            color={statusColor}
                          />
                        </>
                      ) : (
                        <>
                          <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Status</Typography>
                          <Typography>{statusLabel}</Typography>
                        </>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: '4px', }}>
                    <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Created By</Typography>
                    <Typography>{createdBy}</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: '4px', }}>
                    <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Last Modified By</Typography>
                    <Typography>{modifiedBy}</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: '4px', }}>
                    <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Created Date</Typography>
                    <Typography>{createdAt}</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: '4px', }}>
                    <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Last Modified Date</Typography>
                    <Typography>{modifiedAt}</Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

export type DetailStatePlanProps = Children & {
  title?: string
  open: boolean
  maxWidth?: 'sm' | 'md'
  onClose: () => void,
  status: string
}

export type subscriptionPlanList = {
  planPkId: number
  subscriptionPlanName: string
}

export type CarModelDataProps = {
  carModelId: number
  pkId: number
  carName: string
}

export default Detail
