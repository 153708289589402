import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { UserSubMenuProps } from 'modules/types'
import type { RootState } from './api.store'

const initialState: UserSubMenuProps[] = []

const UserMenuSlice = createSlice({
  name: 'UserMenu',
  initialState,
  reducers: {
    setActiveMenu: (state, action: PayloadAction<UserSubMenuProps[]>) => action.payload
  }
})

export const { setActiveMenu } = UserMenuSlice.actions

export const getUserMenuState = (state: RootState) => state.userMenu

export default UserMenuSlice.reducer
