import { useState } from 'react'
import {
  Box,
  Chip,
  Container,
  Divider,
  Grid,
  Typography
} from '@mui/material'
import moment from 'moment'
import { EventAvailable } from '@mui/icons-material'
import MenuModalItem from 'components/menuItem/menuItem'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import type { ErrorProps } from 'modules/types'
import GLOBAL from 'modules/global'
import Drawer from 'components/drawer/detail'
import type { DetailStateProps } from 'modules/types'
import { useGetPaidSubscriptionDetailQuery, useUpdateStopAutoRenewalMutation } from 'store/paidSubscription'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetPaidSubscriptionDetailQuery(id)
  const [updateStopAutoRenewal, stopAutoRenewal] = useUpdateStopAutoRenewalMutation()

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const openAnchor = Boolean(anchor)

  const formatPrice = (price: number) => {
    if (price === null || price === undefined) {
      return 'Rp 0';
    }
    const formattedPrice = Math.abs(price).toLocaleString('id-ID');
    return price.toString().includes('-') || price < 0 ? `- Rp ${formattedPrice}` : `Rp ${formattedPrice}`;
  };


  const firstCondition = data?.nextPaymentDate !== null && data?.isRenewal === true;
  const secondCondition = data?.isRenewal === false && data?.stopRenewalBy !== null && data?.stopRenewalDate !== null
  // const secondCondition = data?.nextPaymentDate === null && data?.isRenewal === false && data?.stopRenewalBy !== null && data?.stopRenewalDate !== null && moment(data?.subscriptionEndDate).toDate() >= new Date();
  const thirdCondition = data?.isRenewal === false && data?.stopRenewalBy === null && data?.stopRenewalDate === null && moment(data?.subscriptionEndDate).toDate() >= new Date();
  const fourthCondition = data?.isRenewal === false && moment(data?.subscriptionEndDate).toDate() < new Date();
  // const fiveCondition = data?.nextPaymentDate !== null && data?.isRenewal === false && data?.stopRenewalDate === null;

  return (
    <>
      <Drawer open={open} title='Detail Paid Subscription' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {isLoading || isFetching || openAnchor ? (
            <Loading />
          ) : data ? (
            <>
              {data && (
                <>
                  {firstCondition && (
                    <>
                      <Box sx={{ mb: 4 }}>
                        <Box
                          sx={{
                            border: '1px solid #E4DCD3',
                            backgroundColor: '#F6F3F2',
                            p: 1.5,
                            mb: 2.5
                          }}
                        >
                          <Grid
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              marginBottom: 12
                            }}
                          >
                            {data.iconUrl ? (
                              <img
                                src={data.iconUrl}
                                alt={data.iconUrl}
                                style={{
                                  height: 24, width: 24, objectFit: 'contain'
                                }}
                              />
                            ) : (
                              <img
                                src={'https://dummyimage.com/50'}
                                style={{ width: 42, height: 42, borderRadius: 100 }}
                              />
                            )}
                            <Grid
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: 12
                              }}
                            >
                              <Typography sx={{ fontSize: 16 }}>Plan</Typography>
                              <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                                {data.subscriptionPlanData}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373' }}>
                              Subscription Period
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {moment(data.subscriptionStartDate).format('MMM DD, YYYY')} -{' '}
                              {moment(data.subscriptionEndDate).format('MMM DD, YYYY')}
                            </Typography>
                          </Box>

                          {data.autoRenewalStatus === 'Yes' && (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                border: '1px solid #E4DCD3',
                                backgroundColor: '#FFFFFF',
                                px: '12px',
                                py: '8px'
                              }}
                            >
                              <EventAvailable style={{ height: 24, width: 24 }} />
                              <Grid
                                style={{
                                  display: 'flex',
                                  flex: 1,
                                  flexDirection: 'column',
                                  marginLeft: 12
                                }}
                              >
                                <Typography sx={{ fontSize: 14, color: 'black' }}>
                                  Auto Renewal
                                </Typography>
                                <Typography sx={{ fontSize: 12, color: 'black' }}>
                                  Next Payment {moment(data.nextPaymentDate).format('MMM DD, YYYY')}
                                </Typography>
                              </Grid>
                            </Box>
                          )}
                        </Box>

                        <Box>
                          <Typography variant='h5' sx={{ fontSize: 16, fontWeight: 600, mb: 1.5 }}>
                            Transaction Summary
                          </Typography>

                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Transaction ID
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {data.transactionId}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Transaction Date
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {moment(data.transactionDate).format('MMM DD, YYYY HH:mm:ss')}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Invoice ID
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {data.invoiceId}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Registered Owner
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {data.registeredOwner}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Email
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>{data.email}</Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Auto Renewal
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {data.autoRenewalStatus}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>VIN</Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>{data.vin}</Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Car Model
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {data.carModelName}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Payment Method
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {data.paymentMethod}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Date of Birth
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {moment(data.dob).format('MMMM DD, YYYY')}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Transaction Region
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {data.transactionRegional}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Subscription Status
                            </Typography>
                            <Chip
                              label={data.subscriptionStatus === 'Active' ? 'Active' : 'InActive'}
                              color={data.subscriptionStatus == 'Active' ? 'success' : 'error'}
                            />
                          </Box>
                        </Box>

                        <Box>
                          <Typography variant='h5' sx={{ fontSize: 16, fontWeight: 600, mb: 1.5 }}>
                            Payment Information
                          </Typography>

                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Price
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {formatPrice(data.price)}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Discount
                            </Typography>
                            <Grid
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end'
                              }}
                            >
                              <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                                {/* Rp {data.discount ? GLOBAL.numberFormat(data.discount) : 0} */}
                                {formatPrice(data.discount)}
                              </Typography>
                              <Typography sx={{ color: '#737373', fontSize: 14 }}>
                                {data.discountName || '-'}
                              </Typography>
                            </Grid>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Promo Code
                            </Typography>
                            <Grid
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end'
                              }}
                            >
                              <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                                {/* Rp {data.promoCode ? GLOBAL.numberFormat(data.promoCode) : 0} */}
                                {formatPrice(data.promoCode)}
                              </Typography>
                              <Typography sx={{ color: '#737373', fontSize: 14 }}>
                                {data.promoCodeName || '-'}
                              </Typography>
                            </Grid>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>VAT</Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              Rp {data.vat ? GLOBAL.numberFormat(data.vat) : 0}
                            </Typography>
                          </Box>
                        </Box>

                        <Divider />

                        <Grid
                          style={{
                            marginTop: 16,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                          }}
                        >
                          <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                            Total (Including Tax)
                          </Typography>
                          <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                            Rp {GLOBAL.numberFormat(data.totalPrice)}
                          </Typography>
                        </Grid>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <MenuModalItem
                          sx={{
                            borderWidth: 2,
                            borderStyle: 'solid',
                            borderColor: '#002C5F',
                            fontSize: 18,
                            fontFamily: 'Hyundai Sans Head Office',
                            color: '#002C5F',
                            backgroundColor: 'white'
                          }}
                          title={'Stop Auto Renewal'}
                          onClick={() =>
                            onRemoveAnchor(() =>
                              updateStopAutoRenewal({
                                Id: data.pkId,
                                IsActive: false
                                // IsActive: data.isRenewal
                              })
                            )
                          }
                        >
                          Stop Auto Renewal
                        </MenuModalItem>
                      </Box>
                    </>
                  )}
                  {secondCondition && (
                    <>
                      <Box sx={{ mb: 4 }}>
                        <Box
                          sx={{
                            border: '1px solid #E4DCD3',
                            backgroundColor: '#F6F3F2',
                            p: 1.5,
                            mb: 2.5
                          }}
                        >
                          <Grid
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              marginBottom: 12
                            }}
                          >
                            {data.iconUrl ? (
                              <img
                                src={data.iconUrl}
                                alt={data.iconUrl}
                                style={{
                                  height: 24, width: 24, objectFit: 'contain'
                                }}
                              />
                            ) : (
                              <img
                                src={'https://dummyimage.com/50'}
                                style={{ width: 42, height: 42, borderRadius: 100 }}
                              />
                            )}
                            <Grid
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: 12
                              }}
                            >
                              <Typography sx={{ fontSize: 16 }}>Plan</Typography>
                              <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                                {data.subscriptionPlanData}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373' }}>
                              Subscription Period
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {moment(data.subscriptionStartDate).format('MMM DD, YYYY')} -{' '}
                              {moment(data.subscriptionEndDate).format('MMM DD, YYYY')}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              border: '1px solid #E4DCD3',
                              backgroundColor: '#FFFFFF',
                              px: '12px',
                              py: '8px'
                            }}
                          >
                            <EventAvailable style={{ height: 24, width: 24 }} />
                            <Grid
                              style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'column',
                                marginLeft: 12
                              }}
                            >
                              <Typography sx={{ fontSize: 14, color: 'black' }}>
                                Auto Renewal
                              </Typography>
                              <Typography sx={{ fontSize: 12, color: 'red' }}>
                                {/* Auto Renewal has been stop by user on {moment(data.nextPaymentDate).format('MMM DD, YYYY')} */}
                                Auto Renewal has been stop by {data.stopRenewalBy} on {data.stopRenewalDate ? moment(data.stopRenewalDate).format('MMM DD, YYYY') : 'Invalid Date'}
                              </Typography>
                            </Grid>
                          </Box>
                        </Box>

                        <Box>
                          <Typography variant='h5' sx={{ fontSize: 16, fontWeight: 600, mb: 1.5 }}>
                            Transaction Summary
                          </Typography>

                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Transaction ID
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {data.transactionId}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Transaction Date
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {moment(data.transactionDate).format('MMM DD, YYYY HH:mm:ss')}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Invoice ID
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {data.invoiceId}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Registered Owner
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {data.registeredOwner}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Email
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>{data.email}</Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Auto Renewal
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {data.autoRenewalStatus}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>VIN</Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>{data.vin}</Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Car Model
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {data.carModelName}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Payment Method
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {data.paymentMethod}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Date of Birth
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {moment(data.dob).format('MMMM DD, YYYY')}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Transaction Region
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {data.transactionRegional}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Subscription Status
                            </Typography>
                            <Chip
                              label={data.subscriptionStatus === 'Active' ? 'Active' : 'InActive'}
                              color={data.subscriptionStatus == 'Active' ? 'success' : 'error'}
                            />
                          </Box>
                        </Box>

                        <Box>
                          <Typography variant='h5' sx={{ fontSize: 16, fontWeight: 600, mb: 1.5 }}>
                            Payment Information
                          </Typography>

                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Price
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {/* Rp {data.price ? GLOBAL.numberFormat(data.price) : 0} */}
                              {formatPrice(data.price)}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Discount
                            </Typography>
                            <Grid
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end'
                              }}
                            >
                              <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                                {/* Rp {data.discount ? GLOBAL.numberFormat(data.discount) : 0} */}
                                {formatPrice(data.discount)}
                              </Typography>
                              <Typography sx={{ color: '#737373', fontSize: 14 }}>
                                {data.discountName || '-'}
                              </Typography>
                            </Grid>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Promo Code
                            </Typography>
                            <Grid
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end'
                              }}
                            >
                              <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                                {/* Rp {data.promoCode ? GLOBAL.numberFormat(data.promoCode) : 0} */}
                                {formatPrice(data.promoCode)}
                              </Typography>
                              <Typography sx={{ color: '#737373', fontSize: 14 }}>
                                {data.promoCodeName || '-'}
                              </Typography>
                            </Grid>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>VAT</Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              Rp {data.vat ? GLOBAL.numberFormat(data.vat) : 0}
                            </Typography>
                          </Box>
                        </Box>

                        <Divider />

                        <Grid
                          style={{
                            marginTop: 16,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                          }}
                        >
                          <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                            Total (Including Tax)
                          </Typography>
                          <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                            Rp {GLOBAL.numberFormat(data.totalPrice)}
                          </Typography>
                        </Grid>
                      </Box>
                    </>
                  )}
                  {thirdCondition && (
                    <>
                      <Box sx={{ mb: 4 }}>
                        <Box
                          sx={{
                            border: '1px solid #E4DCD3',
                            backgroundColor: '#F6F3F2',
                            p: 1.5,
                            mb: 2.5
                          }}
                        >
                          <Grid
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              marginBottom: 12
                            }}
                          >
                            {data.iconUrl ? (
                              <img
                                src={data.iconUrl}
                                alt={data.iconUrl}
                                style={{
                                  height: 24, width: 24, objectFit: 'contain'
                                }}
                              />
                            ) : (
                              <img
                                src={'https://dummyimage.com/50'}
                                style={{ width: 42, height: 42, borderRadius: 100 }}
                              />
                            )}
                            <Grid
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: 12
                              }}
                            >
                              <Typography sx={{ fontSize: 16 }}>Plan</Typography>
                              <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                                {data.subscriptionPlanData}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373' }}>
                              Subscription Period
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {moment(data.subscriptionStartDate).format('MMM DD, YYYY')} -{' '}
                              {moment(data.subscriptionEndDate).format('MMM DD, YYYY')}
                            </Typography>
                          </Box>
                        </Box>

                        <Box>
                          <Typography variant='h5' sx={{ fontSize: 16, fontWeight: 600, mb: 1.5 }}>
                            Transaction Summary
                          </Typography>

                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Transaction ID
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {data.transactionId}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Transaction Date
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {moment(data.transactionDate).format('MMM DD, YYYY HH:mm:ss')}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Invoice ID
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {data.invoiceId}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Registered Owner
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {data.registeredOwner}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Email
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>{data.email}</Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Auto Renewal
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {data.autoRenewalStatus}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>VIN</Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>{data.vin}</Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Car Model
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {data.carModelName}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Payment Method
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {data.paymentMethod}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Date of Birth
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {moment(data.dob).format('MMMM DD, YYYY')}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Transaction Region
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {data.transactionRegional}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Subscription Status
                            </Typography>
                            <Chip
                              label={data.subscriptionStatus === 'Active' ? 'Active' : 'InActive'}
                              color={data.subscriptionStatus == 'Active' ? 'success' : 'error'}
                            />
                          </Box>
                        </Box>

                        <Box>
                          <Typography variant='h5' sx={{ fontSize: 16, fontWeight: 600, mb: 1.5 }}>
                            Payment Information
                          </Typography>

                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Price
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {/* Rp {data.price ? GLOBAL.numberFormat(data.price) : 0} */}
                              {formatPrice(data.price)}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Discount
                            </Typography>
                            <Grid
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end'
                              }}
                            >
                              <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                                {/* Rp {data.discount ? GLOBAL.numberFormat(data.discount) : 0} */}
                                {formatPrice(data.discount)}
                              </Typography>
                              <Typography sx={{ color: '#737373', fontSize: 14 }}>
                                {data.discountName || '-'}
                              </Typography>
                            </Grid>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Promo Code
                            </Typography>
                            <Grid
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end'
                              }}
                            >
                              <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                                {/* Rp {data.promoCode ? GLOBAL.numberFormat(data.promoCode) : 0} */}
                                {formatPrice(data.promoCode)}
                              </Typography>
                              <Typography sx={{ color: '#737373', fontSize: 14 }}>
                                {data.promoCodeName || '-'}
                              </Typography>
                            </Grid>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>VAT</Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              Rp {data.vat ? GLOBAL.numberFormat(data.vat) : 0}
                            </Typography>
                          </Box>
                        </Box>

                        <Divider />

                        <Grid
                          style={{
                            marginTop: 16,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                          }}
                        >
                          <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                            Total (Including Tax)
                          </Typography>
                          <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                            Rp {GLOBAL.numberFormat(data.totalPrice)}
                          </Typography>
                        </Grid>
                      </Box>
                    </>
                  )}
                  {fourthCondition && (
                    <>
                      <Box sx={{ mb: 4 }}>
                        <Box
                          sx={{
                            border: '1px solid #E4DCD3',
                            backgroundColor: '#F6F3F2',
                            p: 1.5,
                            mb: 2.5
                          }}
                        >
                          <Grid
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              marginBottom: 12
                            }}
                          >
                            {data.iconUrl ? (
                              <img
                                src={data.iconUrl}
                                alt={data.iconUrl}
                                style={{
                                  height: 24, width: 24, objectFit: 'contain'
                                }}
                              />
                            ) : (
                              <img
                                src={'https://dummyimage.com/50'}
                                style={{ width: 42, height: 42, borderRadius: 100 }}
                              />
                            )}
                            <Grid
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: 12
                              }}
                            >
                              <Typography sx={{ fontSize: 16 }}>Plan</Typography>
                              <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                                {data.subscriptionPlanData}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373' }}>
                              Exp. Date
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {moment(data.subscriptionEndDate).format('MMM DD, YYYY')}
                            </Typography>
                          </Box>
                        </Box>

                        <Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Registered Owner
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {data.registeredOwner}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Email
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>{data.email}</Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>VIN</Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>{data.vin}</Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Car Model
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {data.carModelName}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Date of Birth
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {moment(data.dob).format('MMMM DD, YYYY')}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Transaction Region
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {data.transactionRegional}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Price
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                              {/* Rp {data.price ? GLOBAL.numberFormat(data.price) : 0} */}
                              {formatPrice(data.price)}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              mb: 1.5
                            }}
                          >
                            <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                              Subscription Status
                            </Typography>
                            <Chip
                              label={data.subscriptionStatus === 'Active' ? 'Active' : 'InActive'}
                              color={data.subscriptionStatus == 'Active' ? 'success' : 'error'}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <div>No data.</div>
          )}
        </Container>
      </Drawer>
      <Notification
        open={!stopAutoRenewal.isLoading && !stopAutoRenewal.isUninitialized}
        onClose={() => (stopAutoRenewal.isError ? stopAutoRenewal.reset() : location.reload())}
        isError={Boolean(stopAutoRenewal.error) && stopAutoRenewal.isError}
        message={GLOBAL.returnExceptionMessage(stopAutoRenewal.isError, stopAutoRenewal.error as ErrorProps)}
      />
    </>
  )
}

export default Detail
