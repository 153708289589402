import { isEmpty } from 'lodash'
import CONSTANT from 'modules/constant'
import type {
  ConfigCategoryProps,
  ConfigProps,
  ConfigPaymentMethodProps,
  PaginationProps,
  ResultProps
} from 'modules/types'
import type { PartialConfigProps, PartialUpdateConfigProps } from 'modules/partial'
import { API, download, headers } from './api'

const ConfigAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getConfigList: builder.query<PaginationProps<ConfigProps>, PartialConfigProps>({
      query: (payload) => {
        const body = new FormData()

        body.append('Start', payload.Start.toString())
        body.append('Length', payload.Length.toString())

        if (!isEmpty(payload.Search)) body.append('Search', payload.Search)
        if (!isEmpty(payload.Category)) body.append('Category', payload.Category)
        if (!isEmpty(payload.RewardType)) body.append('RewardType', payload.RewardType)
        if (!isEmpty(payload.MinValidFrom)) body.append('MinValidFrom', payload.MinValidFrom)
        if (!isEmpty(payload.MaxValidFrom)) body.append('MaxValidFrom', payload.MaxValidFrom)
        if (!isEmpty(payload.MinValidTo)) body.append('MinValidTo', payload.MinValidTo)
        if (!isEmpty(payload.MaxValidTo)) body.append('MaxValidTo', payload.MaxValidTo)
        if (!isEmpty(payload.MinCreatedAt)) body.append('MinCreatedAt', payload.MinCreatedAt)
        if (!isEmpty(payload.MaxCreatedAt)) body.append('MaxCreatedAt', payload.MaxCreatedAt)

        return {
          url: CONSTANT.URL_CONFIG_LIST,
          method: 'POST',
          headers: headers({ useContentType: false }),
          body
        }
      }
    }),
    getConfigDetail: builder.query<Omit<ConfigProps, 'value'> & { value: string[] }, number>({
      query: (id) => ({
        url: CONSTANT.URL_CONFIG_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    getConfigPaymentMethodList: builder.query<ConfigPaymentMethodProps[], void>({
      query: () => ({
        url: CONSTANT.URL_CONFIG_PAYMENT_METHOD_LIST,
        method: 'GET',
        headers: headers()
      })
    }),
    getConfigCategoryList: builder.query<ConfigCategoryProps[], void>({
      query: () => ({
        url: CONSTANT.URL_CONFIG_CATEGORY_LIST,
        method: 'GET',
        headers: headers()
      })
    }),
    updateConfig: builder.mutation<ResultProps, PartialUpdateConfigProps>({
      query: (body) => ({
        url: CONSTANT.URL_CONFIG_UPDATE,
        method: 'PUT',
        headers: headers(),
        body
      })
    }),
    downloadConfigList: builder.mutation<unknown, PartialConfigProps & { type?: string, lengthValue?: string }>({
      query: (payload) => {
        const body = new FormData()

        body.append('Start', String(0))
        body.append('Length', payload.lengthValue || payload.Length.toString())

        if (!isEmpty(payload.Search)) body.append('Search', payload.Search)
        if (!isEmpty(payload.Category)) body.append('Category', payload.Category)
        if (!isEmpty(payload.RewardType)) body.append('RewardType', payload.RewardType)
        if (!isEmpty(payload.MinValidFrom)) body.append('MinValidFrom', payload.MinValidFrom)
        if (!isEmpty(payload.MaxValidFrom)) body.append('MaxValidFrom', payload.MaxValidFrom)
        if (!isEmpty(payload.MinValidTo)) body.append('MinValidTo', payload.MinValidTo)
        if (!isEmpty(payload.MaxValidTo)) body.append('MaxValidTo', payload.MaxValidTo)
        if (!isEmpty(payload.MinCreatedAt)) body.append('MinCreatedAt', payload.MinCreatedAt)
        if (!isEmpty(payload.MaxCreatedAt)) body.append('MaxCreatedAt', payload.MaxCreatedAt)
        if (payload.type && !isEmpty(payload.type)) body.append('Download-Type', payload.type)

        return {
          url: CONSTANT.URL_CONFIG_LIST_DOWNLOAD,
          method: 'POST',
          headers: headers({ useContentType: false }),
          responseHandler: download,
          body,
          formData: true
        }
      }
    })
  })
})

export const {
  useLazyGetConfigListQuery,
  useGetConfigDetailQuery,
  useGetConfigPaymentMethodListQuery,
  useLazyGetConfigCategoryListQuery,
  useUpdateConfigMutation,
  useDownloadConfigListMutation
} = ConfigAPI
