import React, { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { isEmpty } from 'lodash'
import * as yup from 'yup'
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type {
  ApplicableSubsPlanDropDown,
  CarTypeDataDropDown,
  DetailStateProps,
  ErrorProps,
} from 'modules/types'
import GlobalStyle from 'modules/styles'
import { useCreatePromoCodeGeneralMutation } from 'store/promoCodeGeneral'
import { useGetApplicableSubsPlanDropdownMutation, useGetMemberTypeDropdownsssMutation } from 'store/dropdown'
import { useGetDiscountTypeDropdownMutation } from 'store/discountSetting'
import Dialog from 'components/dialog/dialog'
import DialogStyle from './style'


const Create = ({ open, onClose }: DetailStateProps) => {
  const [getDiscountTypeDropdown, DiscountTypeDropdown] = useGetDiscountTypeDropdownMutation()
  const [getApplicableSubsPlanDropdown, applicableSubsPlanDropdown] = useGetApplicableSubsPlanDropdownMutation();
  const [getCarModelDropdown, carModelList] = useGetMemberTypeDropdownsssMutation()
  const [createPromoCodeGeneral, create] = useCreatePromoCodeGeneralMutation()
  const [applicableSubsPlan, setApplicableSubsPlan] = useState<ApplicableSubsPlanDropDown[]>([])

  const [userType, setUserType] = useState<CarTypeDataDropDown[]>([])
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [discountType, setDiscountType] = useState<number | null>(null)
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)
  const [messageUntil, setMessageUntil] = useState<string>('')

  const scheme = yup.object<PartialPromoCodeGeneralCreateProps>({
    Name: yup.string().required('Promo Name is required'),
    PromoNameEN: yup.string().required('Promo Name EN is required'),
    PromoNameID: yup.string().required('Promo Name ID is required'),
    PromoCode: yup.string()
      .required('Prefix is required')
      .matches(/^[A-Z]+$/, 'Prefix must be all uppercase letters'),
    Quantity: yup.number().required('Quantity is required').positive().integer(),
    Description: yup.string().required('Description is required').max(300, 'Maximum Length is 300 characters'),
    IsPercent: yup.string().required('promo Type is required'),
    Value: yup.number().required('Value is required').positive().integer(),
    ValidFrom: yup.string().required('Valid From is required'),
    ValidUntil: yup.string().required('Valid Until is required'),
    MinimumPayment: yup.number().required('Minimum Payment is required').positive().integer(),
    // MaximumDeduction: yup.number().required('Maximum Deduction is required').positive().integer(),
    SubscriptionPlan: yup.array().of(yup.object().shape({
      PlanPkId: yup.number().required(),
      SubscriptionPlanName: yup.string().required(),
    })).required('SubscriptionPlan is required'),
    CarModel: yup.array().of(yup.object().shape({
      PkId: yup.number().required(),
      CarName: yup.string().required(),
    })).required('CarModel is required')
  });

  const formik = useFormik<PartialPromoCodeGeneralCreateProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      Name: '',
      PromoNameEN: '',
      PromoNameID: '',
      PromoCode: '',
      Quantity: null,
      Description: '',
      IsPercent: '',
      Value: null,
      ValidFrom: '',
      ValidUntil: '',
      MinimumPayment: null,
      MaximumDeduction: 0,
      SubscriptionPlan: [],
      CarModel: []
    },
    onSubmit: (values: PartialPromoCodeGeneralCreateProps) => handleSubmit(values)
  })

  const handleChangeStartDate = (date: Dayjs | null) => {
    setStartDate(date)
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    formik.setFieldValue('ValidFrom', newDate)
  }

  const handleChangeEndDate = (date: Dayjs | null) => {
    setEndDate(date)
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    formik.setFieldValue('ValidUntil', newDate)
  }
  const handleChange = (value: ApplicableSubsPlanDropDown[]) => {
    const formattedPlans = value.map(plan => ({
      PlanPkId: plan.id, // Make sure these properties exist in your dropdown data
      SubscriptionPlanName: plan.name
    }));
    formik.setFieldValue('SubscriptionPlan', formattedPlans);
  };

  const handleChangeCarModel = (value: CarTypeDataDropDown[]) => {
    const isian = carModelList.data || []; // All available car models
    // Check if "All" is selected
    if (value.some(item => item.name === "All")) {
      setUserType(isian); // Set userType to all available options
      setIsDisabled(true); // Disable the dropdown
      formik.setFieldValue('IsAllCarModel', true); // Set IsAllCarModel to true
      formik.setFieldValue('CarModel', isian.map(car => ({ PkId: car.id, CarName: car.name }))); // Set all car models
    } else {
      const filteredValue = value.filter(item => item.name !== "All");
      setUserType(filteredValue);
      setIsDisabled(false);
      formik.setFieldValue('CarModel', filteredValue.map(car => ({ PkId: car.id, CarName: car.name }))); // Set selected car models

      const isAllSelected = filteredValue.length === isian.length; // Check if all available models are selected
      formik.setFieldValue('IsAllCarModel', isAllSelected);
    }
  };


  const formatNumberWithCommas = (value: string) => {
    console.log('value', value);

    if (!value) return '';
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };


  const checkMinutesValidity = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target instanceof HTMLInputElement) {
      const value = Number(event.target.value);
      if (value > 100) {
        event.target.value = '100';
      }
      if (value < 0) {
        event.target.value = '0';
      }
    }
  }

  const isEndDateGreaterThanStartDate = () => {
    if (
      messageUntil && dayjs(formik.values.ValidFrom).isAfter(dayjs(formik.values.ValidUntil))
    ) {
      return true
    } else {
      return false
    }
  }

  const handleSubmit = (e: PartialPromoCodeGeneralCreateProps) => {

    const formatPayload = {
      Name: e.Name,
      PromoNameEN: e.PromoNameEN,
      PromoNameID: e.PromoNameID,
      PromoCode: e.PromoCode,
      Quantity: e.Quantity,
      Description: e.Description,
      IsPercent: e.IsPercent,
      Value: e.Value,
      // ValidFrom: dayjs(e.ValidFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      // ValidUntil: dayjs(e.ValidUntil).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      ValidFrom: dayjs(e.ValidFrom).format('YYYY-MM-DDTHH:mm:ss'),
      ValidUntil: dayjs(e.ValidUntil).format('YYYY-MM-DDTHH:mm:ss'),
      MinimumPayment: e.MinimumPayment,
      MaximumDeduction: e.MaximumDeduction,
      IsAllCarModel: e.IsAllCarModel,
      SubscriptionPlan: e.SubscriptionPlan,
      CarModel: e.CarModel
    };
    createPromoCodeGeneral(formatPayload).then()
  }

  const disabled = !formik.isValid ||
    (formik.values.SubscriptionPlan.length === 0) ||
    (formik.values.CarModel.length === 0) || isEndDateGreaterThanStartDate();
  console.log('disabled', disabled);
  console.log('formikError', formik.errors)
  console.log('formikTouched', formik.touched)


  return (
    <>
      <Dialog
        isDisabled={disabled}
        title='New Promo Code General'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        maxWidth='md'

      >
        <FormikProvider value={formik}>
          <Container {...DialogStyle.Container}>

            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
                <TextField
                  {...DialogStyle.textField}
                  id='PromoNameEN'
                  variant='outlined'
                  label='Promo Name EN-US'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.PromoNameEN}
                  error={formik.touched && formik.touched.PromoNameEN && Boolean(formik.errors.PromoNameEN)}
                  helperText={
                    formik.touched && formik.touched.PromoNameEN && formik.errors && formik.errors.PromoNameEN
                  }
                  fullWidth
                />
                <TextField
                  id='PromoCode'
                  variant='outlined'
                  label='Promo Code'
                  onChange={formik.handleChange}
                  {...DialogStyle.textField}
                  onBlur={formik.handleBlur}
                  value={formik.values.PromoCode}
                  error={formik.touched && formik.touched.PromoCode && Boolean(formik.errors.PromoCode)}
                  helperText={
                    formik.touched && formik.touched.PromoCode && formik.errors && formik.errors.PromoCode
                  }
                  fullWidth
                />
                <TextField
                  id='Quantity'
                  variant='outlined'
                  label='Quantity'
                  type='number'
                  onChange={formik.handleChange}
                  {...DialogStyle.textField}
                  sx={{ marginBottom: '23px' }}
                  onBlur={formik.handleBlur}
                  value={formik.values.Quantity}
                  error={formik.touched && formik.touched.Quantity && Boolean(formik.errors.Quantity)}
                  helperText={
                    formik.touched && formik.touched.Quantity && formik.errors && formik.errors.Quantity
                  }
                  fullWidth
                />
                <Autocomplete
                  options={DiscountTypeDropdown.data || []}
                  getOptionLabel={(list) => list.name}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.id == value.id : false
                  }
                  onOpen={() => getDiscountTypeDropdown()}
                  onChange={(_, id) => {
                    if (id?.name) {
                      formik.setFieldValue('IsPercent', id && id?.name);
                      setDiscountType(id && id?.id)
                      if (id.name === 'Percentage') {
                        setDiscountType(id?.id);
                        formik.setFieldValue('Value', 0);
                      } else {
                        setDiscountType(id?.id);
                        formik.setFieldValue('Value', 0);
                      }
                    }
                  }}
                  ListboxProps={GlobalStyle.ListBox}
                  renderOption={(props, item) => (
                    <li {...props} key={item.id}>
                      {item.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='IsPercent'
                      label='Promo Type'
                      error={
                        formik.touched && formik.touched.IsPercent && Boolean(formik.errors.IsPercent)
                      }
                      helperText={
                        formik.touched &&
                        formik.touched.IsPercent &&
                        formik.errors &&
                        formik.errors.IsPercent
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {DiscountTypeDropdown.isLoading && (
                              <CircularProgress color='inherit' size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
              </Box>
              <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
                <TextField
                  {...DialogStyle.textField}
                  id='PromoNameID'
                  variant='outlined'
                  label='Promo Name ID'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.PromoNameID}
                  error={formik.touched && formik.touched.PromoNameID && Boolean(formik.errors.PromoNameID)}
                  helperText={
                    formik.touched && formik.touched.PromoNameID && formik.errors && formik.errors.PromoNameID
                  }
                  fullWidth
                />
                <TextField
                  id='Name'
                  variant='outlined'
                  label='Promo Name'
                  onChange={formik.handleChange}
                  {...DialogStyle.textField}
                  onBlur={formik.handleBlur}
                  value={formik.values.Name}
                  error={formik.touched && formik.touched.Name && Boolean(formik.errors.Name)}
                  helperText={
                    formik.touched && formik.touched.Name && formik.errors && formik.errors.Name
                  }
                  fullWidth
                />
                <TextField
                  {...DialogStyle.textField}
                  id='Description'
                  variant='outlined'
                  label='Description'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.Description}
                  error={formik.touched && formik.touched.Description && Boolean(formik.errors.Description)}
                  helperText={
                    formik.touched && formik.touched.Description && formik.errors && formik.errors.Description
                  }
                  multiline
                  rows={4.5}
                  fullWidth
                />
              </Box>
            </Box>

            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
                {discountType === 1 && (
                  <>
                    <FormControl fullWidth>
                      <InputLabel htmlFor='electricityFee'>Promo Amount</InputLabel>
                      <OutlinedInput
                        id='Value'
                        label='Promo Amount'
                        type='number'
                        value={formik.values.Value && formik.values.Value.toString()}
                        // onChange={formik.handleChange}
                        onChange={(e) => {
                          checkMinutesValidity(e);
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        error={formik.touched && formik.touched.Value && Boolean(formik.errors.Value)}
                        inputProps={{
                          autoComplete: 'off',
                          min: 0,
                          max: 100
                        }}
                        onKeyDown={(event) => {
                          if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
                        }}
                        endAdornment={<InputAdornment position='end'>%</InputAdornment>
                        }
                      />
                    </FormControl>
                  </>
                )}
                {discountType === 2 && (
                  <>
                    <FormControl fullWidth>
                      <InputLabel htmlFor='electricityFee'>Promo Amount</InputLabel>
                      <OutlinedInput
                        id='Value'
                        label='Promo Amount'
                        // type='number'
                        type='text'
                        // value={formik.values.Value && formik.values.Value.toString()}
                        value={formatNumberWithCommas((formik.values.Value || 0).toString())}
                        // onChange={formik.handleChange}
                        onChange={(e) => {
                          const rawValue = parseFloat(e.target.value.replace(/,/g, ''));
                          formik.setFieldValue('Value', rawValue);
                        }}
                        onBlur={formik.handleBlur}
                        error={formik.touched && formik.touched.Value && Boolean(formik.errors.Value)}
                        inputProps={{ autoComplete: 'off' }}
                        onKeyDown={(event) => {
                          if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
                        }}
                        startAdornment={<InputAdornment position='start'>Rp</InputAdornment>}
                      />
                    </FormControl>
                  </>
                )}
              </Box>
              <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
                {discountType === 1 && (
                  <>
                    <TextField
                      id='MinimumPayment'
                      variant='outlined'
                      label='Minimum Payment'
                      {...DialogStyle.textField}
                      // type='number'
                      type='text'
                      // value={formik.values.MinimumPayment}
                      value={formatNumberWithCommas((formik.values.MinimumPayment || 0).toString())}
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        const rawValue = parseFloat(e.target.value.replace(/,/g, ''));
                        formik.setFieldValue('MinimumPayment', rawValue);
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched && formik.touched.MinimumPayment && Boolean(formik.errors.MinimumPayment)}
                      helperText={
                        formik.touched && formik.touched.MinimumPayment && formik.errors && formik.errors.MinimumPayment
                      }
                      fullWidth
                    />
                  </>
                )}
                {discountType === 2 && (
                  <>
                    <TextField
                      id='MinimumPayment'
                      variant='outlined'
                      label='Minimum Payment'
                      {...DialogStyle.textField}
                      // type='number'
                      type='text'
                      // value={formik.values.MinimumPayment}
                      value={formatNumberWithCommas((formik.values.MinimumPayment || 0).toString())}
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        const rawValue = parseFloat(e.target.value.replace(/,/g, ''));
                        formik.setFieldValue('MinimumPayment', rawValue);
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched && formik.touched.MinimumPayment && Boolean(formik.errors.MinimumPayment)}
                      helperText={
                        formik.touched && formik.touched.MinimumPayment && formik.errors && formik.errors.MinimumPayment
                      }
                      fullWidth
                    />
                  </>
                )}
              </Box>
            </Box>

            {discountType === 1 && (
              <>
                <TextField
                  {...DialogStyle.textField}
                  id='MaximumDeduction'
                  variant='outlined'
                  label='Maximum Deduction'
                  // type='number'
                  type='text'
                  // value={formik.values.MaximumDeduction}
                  value={formatNumberWithCommas((formik.values.MaximumDeduction || 0).toString())}
                  // onChange={formik.handleChange}
                  onChange={(e) => {
                    const rawValue = parseFloat(e.target.value.replace(/,/g, ''));
                    formik.setFieldValue('MaximumDeduction', rawValue);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched && formik.touched.MaximumDeduction && Boolean(formik.errors.MaximumDeduction)}
                  helperText={
                    formik.touched && formik.touched.MaximumDeduction && formik.errors && formik.errors.MaximumDeduction
                  }
                  fullWidth
                />
              </>
            )}
            {/* {discountType === 2 && (
              <>
                <TextField
                  {...DialogStyle.textField}
                  id='MaximumDeduction'
                  variant='outlined'
                  label='Maximum Deduction'
                  type='number'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.MaximumDeduction}
                  error={formik.touched && formik.touched.MaximumDeduction && Boolean(formik.errors.MaximumDeduction)}
                  helperText={
                    formik.touched && formik.touched.MaximumDeduction && formik.errors && formik.errors.MaximumDeduction
                  }
                  fullWidth
                />
              </>
            )} */}

            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
                {discountType === 1 && (
                  <>
                    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                      <DatePicker
                        value={startDate}
                        onChange={handleChangeStartDate}
                        label='Start Date'
                        format='DD MMM YYYY'
                      />
                    </LocalizationProvider>
                    <Autocomplete
                      fullWidth
                      sx={{ marginBottom: '10px' }}
                      multiple
                      id='fixed-tags-demo'
                      value={applicableSubsPlan}
                      onOpen={() => getApplicableSubsPlanDropdown()}
                      isOptionEqualToValue={(option, value) =>
                        option && value ? option.name === value.name : false
                      }
                      onChange={(event, newValue) => {
                        setApplicableSubsPlan(newValue)
                        handleChange(newValue);
                      }}
                      options={(applicableSubsPlanDropdown && applicableSubsPlanDropdown.data) || []}
                      getOptionLabel={(option) => option.name}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip label={option.name} {...getTagProps({ index })} key={index} />
                        ))
                      }
                      renderInput={(params) => <TextField {...params} label='Applicable Subscription Plan' />}
                    />
                  </>
                )}
                {discountType === 2 && (
                  <>
                    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                      <DatePicker
                        value={startDate}
                        onChange={handleChangeStartDate}
                        label='Start Date'
                        format='DD MMM YYYY'
                      />
                    </LocalizationProvider>
                    <Autocomplete
                      fullWidth
                      sx={{ marginBottom: '10px' }}
                      multiple
                      id='fixed-tags-demo'
                      value={applicableSubsPlan}
                      onOpen={() => getApplicableSubsPlanDropdown()}
                      isOptionEqualToValue={(option, value) =>
                        option && value ? option.name === value.name : false
                      }
                      onChange={(event, newValue) => {
                        setApplicableSubsPlan(newValue)
                        handleChange(newValue);
                      }}
                      options={(applicableSubsPlanDropdown && applicableSubsPlanDropdown.data) || []}
                      getOptionLabel={(option) => option.name}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip label={option.name} {...getTagProps({ index })} key={index} />
                        ))
                      }
                      renderInput={(params) => <TextField {...params} label='Applicable Subscription Plan' />}
                    />
                  </>
                )}
              </Box>

              <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
                {discountType === 1 && (
                  <>
                    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                      <DatePicker
                        value={endDate}
                        minDate={dayjs(startDate)}
                        onChange={handleChangeEndDate}
                        onError={(error) => {
                          if (error) {
                            formik.setFieldError('ValidUntil', error);
                            setMessageUntil(formik.values.ValidUntil)
                          } else if (formik.values.ValidFrom && dayjs(formik.values.ValidFrom).isAfter(dayjs(formik.values.ValidUntil))) {
                            formik.setFieldError('ValidUntil', 'Valid Until must be greater than Valid From');
                          }
                        }}
                        label='End Date'
                        format='DD MMM YYYY'
                      />
                    </LocalizationProvider>
                    {messageUntil && dayjs(formik.values.ValidFrom).isAfter(dayjs(formik.values.ValidUntil)) && (
                      <FormHelperText sx={{ alignSelf: 'center', marginTop: -2 }} error id='ValidUntil'>End Date must be greater than Start Date</FormHelperText>
                    )}
                    <Autocomplete
                      fullWidth
                      sx={{ marginBottom: '10px' }}
                      multiple
                      id='fixed-tags-demo'
                      value={userType}
                      onOpen={() => getCarModelDropdown()}
                      isOptionEqualToValue={(option, value) =>
                        option && value ? option.id === value.id : false // Compare by id instead of name
                      }
                      onChange={(event, newValue) => {
                        setUserType(newValue);
                        handleChangeCarModel(newValue);
                      }}
                      options={[
                        { id: 14, name: "All" }, // New option
                        ...(carModelList && carModelList.data || []), // Existing options
                      ]}
                      getOptionLabel={(option) => option.name}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            label={option.name} {...getTagProps({ index })}
                            key={index}
                            disabled={isDisabled}
                          />
                        ))
                      }
                      renderInput={(params) => <TextField {...params} label='Car Model' />}
                    />
                  </>
                )}
                {discountType === 2 && (
                  <>
                    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                      <DatePicker
                        value={endDate}
                        minDate={dayjs(startDate)}
                        onChange={handleChangeEndDate}
                        onError={(error) => {
                          if (error) {
                            formik.setFieldError('ValidUntil', error);
                            setMessageUntil(formik.values.ValidUntil)
                          } else if (formik.values.ValidFrom && dayjs(formik.values.ValidFrom).isAfter(dayjs(formik.values.ValidUntil))) {
                            formik.setFieldError('ValidUntil', 'Valid Until must be greater than Valid From');
                          }
                        }}
                        label='End Date'
                        format='DD MMM YYYY'
                      />
                    </LocalizationProvider>
                    {messageUntil && dayjs(formik.values.ValidFrom).isAfter(dayjs(formik.values.ValidUntil)) && (
                      <FormHelperText sx={{ marginTop: -2 }} error id='ValidUntil'>End Date must be greater than Start Date</FormHelperText>
                    )}
                    <Autocomplete
                      fullWidth
                      sx={{ marginBottom: '10px' }}
                      multiple
                      id='fixed-tags-demo'
                      value={userType}
                      onOpen={() => getCarModelDropdown()}
                      isOptionEqualToValue={(option, value) =>
                        option && value ? option.id === value.id : false // Compare by id instead of name
                      }
                      onChange={(event, newValue) => {
                        setUserType(newValue);
                        handleChangeCarModel(newValue);
                      }}
                      options={[
                        { id: 14, name: "All" }, // New option
                        ...(carModelList && carModelList.data || []), // Existing options
                      ]}
                      getOptionLabel={(option) => option.name}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            label={option.name} {...getTagProps({ index })}
                            key={index}
                            disabled={isDisabled}
                          />
                        ))
                      }
                      renderInput={(params) => <TextField {...params} label='Car Model' />}
                    />
                  </>
                )}
              </Box>
            </Box>

          </Container>
        </FormikProvider>
      </Dialog>
      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export type PartialPromoCodeGeneralCreateProps = {
  Name: string
  PromoNameEN: string
  PromoNameID: string
  PromoCode: string
  Quantity: number | null
  Description: string
  IsPercent: string
  Value: number | null
  ValidFrom: string
  ValidUntil: string
  MinimumPayment: number | null
  MaximumDeduction?: number
  IsAllCarModel?: boolean
  SubscriptionPlan: SubscriptionApplicablePlanProps[]
  CarModel: CarModelCreateProps[]
}

export type SubscriptionApplicablePlanProps = {
  PlanPkId: number,
  SubscriptionPlanName: string
}
export type CarModelCreateProps = {
  PkId: number,
  CarName: string
}
export default Create
