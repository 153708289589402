import type { CSSProperties } from "react";
import type { BoxProps, ButtonProps } from "@mui/material";

class LoginOtpStyle {
  static QRCode: CSSProperties = {
    height: '160px',
    width: '160px',
    marginBottom: '1rem'
  }

  static Wrapper: BoxProps = {
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    sx: {
      backgroundColor: 'grey.100'
    }
  }

  static Card: React.HTMLAttributes<HTMLFormElement> = {
    style: {
      display: 'flex',
      minWidth: '30rem',
      padding: '4rem',
      gap: '1rem',
      borderRadius: '1rem',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'white'
    }
  }

  static Button: ButtonProps = {
    type: 'submit',
    variant: 'contained',
    size: 'large',
    fullWidth: true,
    disableElevation: true,
    style: {
      marginTop: '1rem'
    }
  }

  static Validity: React.HTMLAttributes<HTMLParagraphElement> = {
    style: {
      margin: 0,
      fontSize: '13px',
      color: 'rgb(0 0 0 / 50%)'
    }
  }

  static Logo: React.HTMLAttributes<HTMLImageElement> = {
    draggable: false,
    style: {
      width: '14rem',
      marginBottom: '1rem'
    }
  }
}

export default LoginOtpStyle
