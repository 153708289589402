import { useEffect, useState } from 'react'
import { FormikProvider } from 'formik'
import { isEmpty } from 'lodash'
import * as yup from 'yup'
import {
    Autocomplete,
    Box,
    Chip,
    CircularProgress,
    Container,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField, Typography
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import FORMIK from 'modules/formik'
import Loading from 'components/loading/loading'
import { useGetDetailSubsPlanQuery, useUpdateSubsPlanMutation } from 'store/subsplan'
import { useGetMemberTypeDropdownsssMutation } from 'store/dropdown'
import { useGetSchemeTypeDropdownMutation } from 'store/serviceBookingEventBanner'
import type { CallbackHandler, CarTypeDataDropDown, DetailStateProps, ErrorProps } from 'modules/types'
import BackdropImage from 'components/backdropImage/backdropImage'
import GlobalStyle from 'modules/styles'
import DialogStyle from './style'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: string | number }) => {
    if (!open) return <></>

    const { data, isFetching, isLoading } = useGetDetailSubsPlanQuery(id)
    const [getSchemetype, schemeTypeDropdown] = useGetSchemeTypeDropdownMutation()
    const [updateBanner, update] = useUpdateSubsPlanMutation()
    const [getCarModelDropdown, carModelList] = useGetMemberTypeDropdownsssMutation()

    const [previewImage, setPreviewImage] = useState<string | ArrayBuffer | null>(null)
    const [previewBackdrop, setPreviewBackdrop] = useState<boolean>(false)
    const [userType, setUserType] = useState<CarTypeDataDropDown[]>([])
    const [isImageChanged, setIsImageChanged] = useState<boolean>(false)
    const [messageUntil, setMessageUntil] = useState<string>('')

    const scheme = yup.object<PartialSubsPlanCreateProps>({
        Files: yup.mixed().required('Files is required'),
        data: yup.object<PartialSubsPlanDataCreateProps>({
            SubscriptionPlanName: yup.string().required('Subscription Plan Name is required'),
            SubscriptionPlanNameID: yup.string().required('Subscription Plan ID is required'),
            SubscriptionPlanNameEN: yup.string().required('Subscription Plan EN is required'),
            // SeqNo: yup.number().nullable(),
            SeqNo: yup.number().required('Sequence No is required').positive('Sequence No must be greater than 0').integer('Sequence No must be an integer'),
            Price: yup.string().nullable(),
            Duration: yup.string().required('Duration is required'),
            ValidFrom: yup.string().required('Valid From is required'),
            ValidUntil: yup.string()
                .required('Valid Until is required')
                .test('is-greater', 'Valid Until must be greater than Valid From', function (value) {
                    const { ValidFrom } = this.parent.data || {}; // Safely access ValidFrom
                    if (!ValidFrom) return true; // If ValidFrom is not set, skip the validation
                    return dayjs(value).isAfter(dayjs(ValidFrom)); // Compare the dates
                }),
            CarModel: yup.array().of(yup.object().shape({
                PkId: yup.number().required(),
                CarName: yup.string().required(),
            })).required('Car Model is required'),
        }),
        setIsImageChanged: yup.boolean()
    });

    const initialValues: PartialSubsPlanCreateProps = {
        Files: {} as File,
        // Files: null,
        data: {
            PkId: (data && data.pkId) || null,
            SubscriptionPlanName: (data && data.subscriptionPlanName) || '',
            SubscriptionPlanNameID: (data && data.subscriptionPlanNameID) || '',
            SubscriptionPlanNameEN: (data && data.subscriptionPlanNameEN) || '',
            SeqNo: (data && Number(data.seqNo)) || null,
            Price: (data && Number(data.price)) || null,
            Duration: (data && data.duration) || '',
            ValidFrom: (data && GLOBAL.formatDate(data.validFrom)) || '',
            ValidUntil: (data && GLOBAL.formatDate(data.validUntil)) || '',
            CarModel: []
        },
        isImageChanged: isImageChanged
    }

    const formik = FORMIK.useFormFormik<PartialSubsPlanCreateProps>(scheme, initialValues, (values) => {
        const formData = new FormData();
        const data = {
            ...values.data,
            // IsAllCarModel: userType.length === 0,
            // CarModel: userType.length > 0 ? userType : [],
            CarModel: userType.length > 0
                ? userType.map(car => ({ PkId: car.id, CarName: car.name }))
                : [],
        };

        // Check if the image has changed
        if (isImageChanged) {
            // If the image has changed, append the selected file
            if (values.Files) {
                formData.append('Files', values.Files);
            } else {
                console.warn('No file selected to send.'); // Log warning if no file
            }
        }
        // Check if the image has changed
        // if (isImageChanged) {
        //     // If the image has changed, append the selected file
        //     if (values.Files) {
        //         formData.append('Files', values.Files);
        //     } else {
        //         console.warn('No file selected to send.'); // Log warning if no file
        //     }
        // } else {
        //     // If the image has not changed, append a string representation of null
        //     formData.append('Files', 'null'); // Indicate no file is being sent
        // }

        // Menambahkan data ke FormData
        formData.append('data', JSON.stringify(data));
        formData.append('isImageChanged', JSON.stringify(isImageChanged));

        // Cek isi FormData
        console.log('FormData contents:');
        formData.forEach((value, key) => {
            console.log('key', key);
            console.log('value', value);
        });

        updateBanner(formData).then(response => {
            console.log('Response from server:', response);
        }).catch(error => {
            console.error('Error sending FormData:', error);
        });
    });

    const fileOnChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        field: string,
        callback: CallbackHandler<string | ArrayBuffer | null>
    ) => {
        const files = e && e.target && e.target.files;
        const notEmpty = files && files.length > 0 && !isEmpty(files);
        if (notEmpty) {
            const file = files[0];
            console.log('Selected file:', file); // Log file yang dipilih

            const reader = new FileReader();
            reader.onload = () => callback(reader.result);
            reader.readAsDataURL(file);

            formik.setFieldValue(field, file);
            // formik.setFieldValue('isImageChanged', true);
            setIsImageChanged(true)
            return
        } else {
            // formik.setFieldValue(field, null);
            formik.setFieldValue(field, {} as File)
            // formik.setFieldValue('isImageChanged', false);
            setIsImageChanged(false)
            return callback(null);
        }
    };

    const error = formik.errors && (formik.errors.data as unknown as PartialSubsPlanDataCreateProps)
    const touched = formik.touched && (formik.touched as PartialSubsPlanDataCreateProps)
    const requestToken = formik.values.data

    // const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const handleChange = (value: CarTypeDataDropDown[]) => {
        const isian = carModelList.data || []; // All available car models

        // Check if "All" is selected
        if (value.some(item => item.name === "All")) {
            setUserType(isian); // Set userType to all available options
            // setIsDisabled(true); // Disable the dropdown
            formik.setFieldValue('data.IsAllCarModel', true); // Set IsAllCarModel to true
        } else {
            // If "All" is not selected, filter out the "All" option
            const filteredValue = value.filter(item => item.name !== "All");
            setUserType(filteredValue);
            // setIsDisabled(false); // Enable the dropdown

            // Set IsAllCarModel based on the selection
            const isAllSelected = filteredValue.length === isian.length; // Check if all available models are selected
            formik.setFieldValue('data.IsAllCarModel', isAllSelected);
        }
    };

    // const selectedEventId =
    //     (!schemeTypeDropdown.isLoading &&
    //         schemeTypeDropdown &&
    //         schemeTypeDropdown.data &&
    //         schemeTypeDropdown.data.find((brand) => brand.name.toLowerCase() == data?.duration.toLowerCase())) ||
    //     null


    useEffect(() => {
        if (data && data.iconUrl) {
            getSchemetype()
            getCarModelDropdown()

            const defaultCarModel =
                (data &&
                    data.carModel &&
                    data.carModel.map((item) => ({
                        id: item.carModelId,
                        name: item.carName
                    }))) ||
                []
            setUserType(defaultCarModel)
            setPreviewImage(data.iconUrl)
        }
    }, [data])

    // console.log('errror', formik.values.data.ValidFrom);
    console.log('formikError', formik.errors)
    // console.log('messa', messageUntil);
    // console.log('formik value duration', formik.values.data.Duration);

    const isEndDateGreaterThanStartDate = () => {
        if (
            messageUntil && dayjs(formik.values.data.ValidFrom).isAfter(dayjs(formik.values.data.ValidUntil))
        ) {
            return true
        } else {
            return false
        }
    }

    const disabled = !formik.isValid || isEndDateGreaterThanStartDate() ||
        (formik.values.data.SeqNo === 0);

    console.log('formikError', formik.errors)

    return (
        <>
            <Dialog
                title='Edit Subscription Plan'
                open={open}
                onCancel={onClose}
                onSubmit={() => formik.handleSubmit()}
                loading={update.isLoading}
                isDisabled={disabled}
                submitText={'Submit'}
            >
                <Container {...DialogStyle.Container}>
                    {(isLoading || isFetching) && !data && <Loading />}
                    {data && (
                        <>
                            <FormikProvider value={formik}>
                                <TextField
                                    id='SubscriptionPlanName'
                                    variant='outlined'
                                    disabled={true}
                                    label='Subscription Plan Name'
                                    value={formik.values.data.SubscriptionPlanName}
                                    onChange={(event) => formik.setFieldValue('data.SubscriptionPlanName', event.target.value)}
                                    onBlur={(event) => formik.setFieldValue('data.SubscriptionPlanName', event.target.value)}
                                    error={touched && Boolean(touched.SubscriptionPlanName) && error && Boolean(error.SubscriptionPlanName)}
                                    helperText={touched && touched.SubscriptionPlanName && error && error.SubscriptionPlanName}
                                    fullWidth
                                />
                                <TextField
                                    id='SubscriptionPlanNameID'
                                    variant='outlined'
                                    disabled={true}
                                    label='Subscription Plan Name ID'
                                    value={formik.values.data.SubscriptionPlanNameID}
                                    onChange={(event) => formik.setFieldValue('data.SubscriptionPlanNameID', event.target.value)}
                                    onBlur={formik.handleBlur}
                                    error={touched && Boolean(touched.SubscriptionPlanNameID) && error && Boolean(error.SubscriptionPlanNameID)}
                                    helperText={touched && touched.SubscriptionPlanNameID && error && error.SubscriptionPlanNameID}
                                    fullWidth
                                />
                                <TextField
                                    id='SubscriptionPlanNameEN'
                                    variant='outlined'
                                    disabled={true}
                                    label='Subscription Plan Name EN-US'
                                    value={formik.values.data.SubscriptionPlanNameEN}
                                    onChange={(event) => formik.setFieldValue('data.SubscriptionPlanNameEN', event.target.value)}
                                    onBlur={formik.handleBlur}
                                    error={touched && Boolean(touched.SubscriptionPlanNameEN) && error && Boolean(error.SubscriptionPlanNameEN)}
                                    helperText={touched && touched.SubscriptionPlanNameEN && error && error.SubscriptionPlanNameEN}
                                    fullWidth
                                />
                                <TextField
                                    id='SeqNo'
                                    variant='outlined'
                                    label='Sequence No'
                                    type='number'
                                    value={formik.values.data.SeqNo}
                                    onChange={(event) => formik.setFieldValue('data.SeqNo', event.target.value)}
                                    onBlur={formik.handleBlur}
                                    error={touched && Boolean(touched.SeqNo) && error && Boolean(error.SeqNo)}
                                    helperText={touched && touched.SeqNo && error && error.SeqNo}
                                    fullWidth
                                />
                                <Autocomplete
                                    options={(schemeTypeDropdown && schemeTypeDropdown.data) || []}
                                    getOptionLabel={(list) => list.name}
                                    isOptionEqualToValue={(option, value) =>
                                        option && value ? option.id == value.id : false
                                    }
                                    disabled={true}
                                    onOpen={() => getSchemetype()}
                                    // value={selectedEventId}
                                    value={
                                        (schemeTypeDropdown &&
                                            schemeTypeDropdown.data &&
                                            schemeTypeDropdown.data.find(
                                                (e) => e.name == formik.values.data.Duration
                                            )) ||
                                        null
                                    }
                                    onChange={(_, id) => {
                                        // console.log('calueeeee', id?.name);
                                        formik.setFieldValue('data.Duration', id && id?.name)
                                    }
                                    }
                                    ListboxProps={GlobalStyle.ListBox}
                                    renderOption={(props, item) => (
                                        <li {...props} key={item.id}>
                                            {item.name}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name='Duration'
                                            label='Scheme Type'
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {schemeTypeDropdown.isLoading && (
                                                            <CircularProgress color='inherit' size={20} />
                                                        )}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                )
                                            }}
                                        />
                                    )}
                                />
                                <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                                        <DatePicker
                                            value={requestToken && requestToken.ValidFrom ? dayjs(requestToken.ValidFrom) : null}
                                            onChange={(e: Dayjs | null) => {
                                                const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                                                formik.setFieldValue('data.ValidFrom', newDate)
                                            }}
                                            label='Start Date'
                                            format='MMM, DD YYYY'
                                        />
                                    </LocalizationProvider>

                                    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                                        <DatePicker
                                            value={requestToken && requestToken.ValidFrom ? dayjs(requestToken.ValidUntil) : null}
                                            minDate={requestToken && requestToken.ValidFrom ? dayjs(requestToken.ValidFrom) : undefined}
                                            onChange={(e: Dayjs | null) => {
                                                const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                                                formik.setFieldValue('data.ValidUntil', newDate)
                                            }}
                                            onError={(error) => {
                                                if (error) {
                                                    formik.setFieldError('data.ValidUntil', error);
                                                    setMessageUntil(formik.values.data.ValidFrom)
                                                } else if (formik.values.data.ValidFrom && dayjs(formik.values.data.ValidFrom).isAfter(dayjs(formik.values.data.ValidUntil))) {
                                                    formik.setFieldError('data.ValidUntil', 'Valid Until must be greater than Valid From');
                                                }
                                            }}
                                            label='End Date'
                                            format='MMM, DD YYYY'
                                        />

                                    </LocalizationProvider>
                                </div>
                                {messageUntil && dayjs(formik.values.data.ValidFrom).isAfter(dayjs(formik.values.data.ValidUntil)) && (
                                    <FormHelperText sx={{ alignSelf: 'center', marginTop: -1 }} error id='ValidUntil'>End Date must be greater than Start Date</FormHelperText>
                                )}
                                <FormControl fullWidth>
                                    <InputLabel htmlFor='electricityFee'>Price</InputLabel>
                                    <OutlinedInput
                                        id='Price'
                                        label='Price'
                                        type='number'
                                        disabled={true}
                                        value={formik.values.data.Price && formik.values.data.Price.toString() || 0}
                                        onChange={(event) => formik.setFieldValue('data.Price', event.target.value)}
                                        onBlur={formik.handleBlur}
                                        // error={touched && Boolean(touched.Price) && error && Boolean(error.Price)}
                                        error={
                                            formik.touched &&
                                            formik.touched.data?.Price &&
                                            Boolean(formik.errors.data?.Price)
                                        }
                                        inputProps={{ autoComplete: 'off' }}
                                        onKeyDown={(event) => {
                                            if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
                                        }}
                                        startAdornment={<InputAdornment position='start'>Rp</InputAdornment>}
                                    />
                                    {formik.touched && formik.touched.data?.Price && formik.errors && (
                                        <FormHelperText error id='Price'>
                                            {formik.errors.data?.Price}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <Autocomplete
                                    fullWidth
                                    sx={{ marginBottom: '10px' }}
                                    multiple
                                    id='fixed-tags-demo'
                                    disabled={true}
                                    value={userType}
                                    onOpen={() => getCarModelDropdown()}
                                    isOptionEqualToValue={(option, value) =>
                                        option && value ? option.id === value.id : false // Compare by id instead of name
                                    }
                                    onChange={(event, newValue) => {
                                        setUserType(newValue);
                                        handleChange(newValue);
                                    }}
                                    options={[
                                        { id: 14, name: "All" }, // New option
                                        ...(carModelList && carModelList.data || []), // Existing options
                                    ]}
                                    getOptionLabel={(option) => option.name}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip
                                                label={option.name} {...getTagProps({ index })}
                                                key={index}
                                                // disabled={isDisabled}
                                                disabled={true}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => <TextField {...params} label='Car Model' />}
                                />
                                <TextField
                                    id='Files'
                                    label='Icon Plan'
                                    type='file'
                                    placeholder='Files'
                                    disabled={true}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ accept: 'image/png, image/gif, image/jpeg' }}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        fileOnChange(e, 'Files', (value) => setPreviewImage(value))
                                    }
                                    onBlur={formik.handleBlur}
                                    fullWidth
                                />
                                {previewImage && (
                                    <Grid item xs={12}>
                                        <Typography {...DialogStyle.Title}>Icon Plan</Typography>
                                        <Box {...DialogStyle.Image}>
                                            <BackdropImage
                                                open={previewBackdrop}
                                                onOpen={() => setPreviewBackdrop(true)}
                                                onClose={() => setPreviewBackdrop(false)}
                                            >
                                                <img
                                                    src={previewImage as string | undefined}
                                                    alt='Files'
                                                    width='100%'
                                                    height='100%'
                                                />
                                            </BackdropImage>
                                        </Box>
                                    </Grid>
                                )}

                            </FormikProvider>
                        </>
                    )}
                </Container>
            </Dialog>
            <Notification
                open={!update.isLoading && !update.isUninitialized}
                onClose={() => (update.isError ? update.reset() : location.reload())}
                isError={Boolean(update.error) && update.isError}
                message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
            />
        </>
    )
}

export type PartialSubsPlanCreateProps = {
    Files: File | null
    data: PartialSubsPlanDataCreateProps
    isImageChanged: boolean
}
export type PartialSubsPlanDataCreateProps = {
    PkId: number | null
    SubscriptionPlanName: string
    SubscriptionPlanNameID: string
    SubscriptionPlanNameEN: string
    SeqNo: number | null
    Price: number | null
    Duration: string
    ValidFrom: string
    ValidUntil: string
    IsAllCarModel?: boolean,
    CarModel: CarModelCreateProps[]
}
export type CarModelCreateProps = {
    PkId: number,
    CarName: string
}

export default Edit