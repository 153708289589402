// import { ArrowDownLeftIcon } from "@heroicons/react/20/solid";
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export const AreaChart = ({ title, valuePerMonth }: AreaChartProps) => {
  if (!valuePerMonth || !Array.isArray(valuePerMonth)) {
    return <div>Error: Invalid data for AreaChart</div>
  }

  const xAxisCategories = valuePerMonth.map((item) => item.name)

  const options: Highcharts.Options = {
    chart: {
      // type: 'area',
      // type: 'spline',
      type: 'areaspline',
      backgroundColor: 'transparent',
      height: 400
    },
    title: {
      text: title,
      useHTML: true,
      align: 'left',
      style: {
        fontFamily: 'Hyundai Sans Head Office'
      }
    },
    credits: { enabled: false },
    xAxis: {
      categories: xAxisCategories,
      tickmarkPlacement: 'on',
      gridLineWidth: 0,
      title: { text: undefined },
      crosshair: { // Tambahkan crosshair pada xAxis
        dashStyle: 'Dash', // Garis putus-putus
        color: '#000000', // Warna garis
        width: 1, // Lebar garis
        zIndex: 5 // garis berada di atas area chart
      },
      plotLines: [
        {
          color: '#000000',
          width: 2,
          value: 32000,
          dashStyle: 'Dash',
          zIndex: 10,
          label: {
            text: '1,348 Subs',
            style: {
              color: '#FFFFFF',
              fontWeight: 'bold',
              fotSize: '12px'
            }
          },
          events: {
            click: function () {
              alert('Click On the Line!')
            }
          }
        }
      ]
    },
    yAxis: {
      title: { text: '' },
      labels: {
        style: { fontFamily: 'Hyundai Sans Text Office', color: '#999' }
      },
      // min: 0,
      // max: 1000000,
      // tickInterval: 100000,
      gridLineDashStyle: 'Dash'
      // gridLineWidth: 1,
      // gridLineColor: '#eee',
      // labels: {
      //     formatter: onRemoveAnchor(dataa)
      // },
    },
    // tooltip: {
    //     // formatter: function () {
    //     //     return '<b>' + this.x + '</b><br/>' + 'Rp ' + this.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'k';
    //     // },
    //     style: {
    //         fontSize: '12px',
    //         fontWeight: 'normal'
    //     },
    //     backgroundColor: '#fff',
    //     borderColor: '#ccc',
    //     borderWidth: 1
    // },
    tooltip: {
      backgroundColor: '#000000',
      style: {
        color: '#FFFFFF',
        fontSize: '12px',
        fontWeight: 'normal'
      },
      borderColor: '#000000',
      borderWidth: 1,
      formatter: function () {
        const index = xAxisCategories.indexOf(this.x?.toString() || ''); // Ambil index bulan yang sedang dihover
        const subs = valuePerMonth[index].subs; // Ambil nilai subs sesuai bulan
        const revenue = valuePerMonth[index].value; // Format nilai revenue
        return `
          <b>${this.x}</b><br/>
          ${subs} Subs<br/>
          Revenue: ${revenue}
        `;
      }
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      // area: {
      //     marker: {
      //         radius: 2
      //     },
      //     lineWidth: 1,
      //     color: {
      //         linearGradient: {
      //             x1: 0,
      //             y1: 0,
      //             x2: 0,
      //             y2: 1
      //         },
      //         stops: [
      //             [0, '#00AAD2'],
      //             [0.7, '#A3DCFF']
      //         ]
      //     },
      //     states: {
      //         hover: {
      //             lineWidth: 1
      //         }
      //     },
      //     threshold: null
      // },
      // spline: {
      //     marker: {
      //         enabled: false
      //     },
      //     lineWidth: 2,
      //     color: {
      //         linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
      //         stops: [
      //             [0, '#00C39A'],
      //             [1, '#E1F9FF']
      //         ]
      //     },
      //     fill: {
      //         linearGradient: { x1: 0, x2: 1, y1: 0, y2: 1 },
      //         stops: [
      //             [0, 'rgba(0, 195, 154, 0,5)'],
      //             [1, 'rgba(225, 249, 225, 0,5)']
      //         ]
      //     }
      // }
      areaspline: {
        // marker: {
        //   // enabled: false
        //   radius: 2
        // },
        marker: {
          enabled: true, // Aktifkan marker
          radius: 2, // Ukuran default marker
          states: {
            hover: {
              enabled: true, // Aktifkan marker saat dihover
              radius: 6, // Ukuran marker saat dihover (lebih besar)
              fillColor: '#000000', // Warna isi marker (hitam)
              lineColor: '#000000', // Warna garis outline marker (hitam)
              lineWidth: 1 // Lebar garis outline marker
            }
          }
        },
        lineWidth: 2
        // color: {
        //     linearGradient: {
        //         x1: 0,
        //         x2: 0,
        //         y1: 0,
        //         y2: 1
        //     },
        //     stops: [
        //         [0, '#00AAD2'],
        //         [0.7, '#A3DCFF']
        //     ]
        // },
        // fill: {
        //     linearGradient: { x1: 0, x2: 1, y1: 0, y2: 1 },
        //     stops: [
        //         [0, 'rgba(0, 195, 154, 0,5)'],
        //         [1, 'rgba(225, 249, 225, 0,5)']
        //     ]
        // }
      }
    },
    series: [
      {
        type: 'areaspline',
        name: 'Revenue',
        data: valuePerMonth.map((item) => item.value),
        color: '#00AAD2',
        // color: {
        //     linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        //     stops: [
        //         [0, '#fa4fed'],
        //         [1, '#5897ff']
        //     ]
        // },
        fillOpacity: 1,
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [
            [0, '#00AAD2'],
            [0, '#A3DCFF'],
            [1, '#FFFFFF']
          ]
        }
        // arearange: {
        //   enableMouseTracking: false,
        //   states: {
        //     inactive: {
        //       enabled: false
        //     }
        //   },
        //   color: 'red',
        //   fillOpacity: 1 / 3,
        //   lineWidth: 0
        // }
        // marker: {
        //     enabled: true,
        //     symbol: 'circle',
        //     radius: 4,
        //     fillColor: '#00a1d3',
        //     lineWidth: 0,
        //     states: {
        //         hover: {
        //             lineWidth: 1,
        //             lineColor: '#00a1d3'
        //         }
        //     }
        // }
      }
    ]
    // tooltip: {
    //     shared: true,
    //     valueSuffix: 'k'
    // },
    // credits: {
    //     enabled: false
    // }
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

interface AreaChartProps {
  /** Chart title. Support HTML format. */
  title: string
  valuePerMonth: { name: string; value: number; subs: number }[]
}
