import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import type { GridColDef } from '@mui/x-data-grid'
import { DataGrid } from '@mui/x-data-grid'
import { Container, Grid, TextField, Typography } from '@mui/material'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import { useGetAccountPermissionDetailsssQuery } from 'store/assignment'
import { useGetUsersAccountDetailsssQuery } from 'store/control'
import { useGetMenuTreesssQuery } from 'store/menu'
import { useGetRolesListDropdownsssMutation } from 'store/roles'
import type { DetailStateProps, RolesAssignmentMenuProps } from 'modules/types'
import DetailStyle from './style'

const Detail = ({
  open,
  id,
  onClose
}: DetailStateProps & { id: string; nameCheck: string }) => {
  // console.log('id ==>', id);

  if (!open) return <Drawer open={open} onClose={onClose} />

  const [getRolesListDropdown, rolesList] = useGetRolesListDropdownsssMutation()

  const {
    data: dataAccount,
    isFetching: isAccountFetching,
    isLoading: isAccountLoading
  } = useGetUsersAccountDetailsssQuery(id)

  const {
    data: dataPermission,
    isFetching: isPermissionFetching,
    isLoading: isPermissionLoading
  } = useGetAccountPermissionDetailsssQuery(id)

  const {
    data: dataMenu,
    isFetching: isMenuFetching,
    isLoading: isMenuLoading
  } = useGetMenuTreesssQuery()

  const selectedColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 96 },
    { field: 'menu', headerName: 'Main Menu', minWidth: 256 },
    { field: 'name', headerName: 'Sub Menu', minWidth: 256 },
    {
      field: 'convertedFunctions',
      headerName: 'Function Name',
      minWidth: 256,
      flex: 1
    }
  ]

  const selectedRole =
    rolesList &&
    rolesList.data &&
    rolesList.data.find((el) => el.name == (dataPermission && dataPermission.roleName))

  const selectedMainMenu =
    dataMenu &&
    dataMenu.mainMenu &&
    dataMenu.mainMenu
      .map((main) => {
        const subMenu = main.subMenu.map((subMenu) => {
          console.log('subMenu', subMenu);

          const functions = subMenu.listFunction.filter(
            (func) => dataPermission && dataPermission.permissions.includes(func.name)
          )
          console.log('functions', functions);


          const filtered = functions.map((func) => ({
            id: func.functionId,
            name: func.name,
            type: func.type
          }))

          const list = {
            menu: main.name,
            name: subMenu.name,
            // id: subMenu.subMenuId,
            id: subMenu.mainMenuId,
            functions: filtered,
            convertedFunctions: filtered.map((func) => func.name).join(', ')
          }

          return list
        })

        const filtered = subMenu.filter((sub) => sub.functions.length)

        return filtered
      })
      .filter((menu) => menu.length)
      .flat(1)

  const userId = (dataAccount && dataAccount.userCode) || '-'
  const email = (dataAccount && dataAccount.email) || '-'
  const fullName = (dataAccount && dataAccount.fullname) || '-'
  const mobilePhone = (dataAccount && dataAccount.mobileNumber) || '-'
  const role = (selectedRole && selectedRole.name) || '-'

  const gridDataLabel = ['User ID']
  const gridDataValue = [userId]
  const gridDataLabeRight = ['Full Name', 'Phone Number']
  const gridDataValueRight = [fullName, mobilePhone]
  const gridDataLabeLeft = ['Email', 'Role']
  const gridDataValueLeft = [email, role]

  const [selectedRow, setSelectedRow] = useState<(RolesAssignmentMenuProps | undefined)[]>(
    selectedMainMenu as (RolesAssignmentMenuProps | undefined)[]
  )

  console.log('dataPermission', dataPermission);
  console.log('dataMenu', dataMenu);

  useEffect(() => {
    getRolesListDropdown()
  }, [])

  useEffect(() => {
    if (isEmpty(selectedRow) && selectedMainMenu) setSelectedRow(selectedMainMenu)
  }, [selectedMainMenu])

  return (
    // <Drawer open={open} title='Check Access Detail' onClose={onClose}>
    <Container {...DetailStyle.Container}>
      {(isAccountLoading || isAccountFetching) &&
        !dataAccount &&
        (isPermissionLoading || isPermissionFetching) &&
        !dataPermission &&
        (isMenuLoading || isMenuFetching) &&
        !dataMenu && <Loading />}

      {dataAccount && (
        <Grid container spacing={2}>
          {gridDataValue.map((item, index) => (
            <Grid key={index} item xs={12}>
              <TextField
                variant='outlined'
                label={gridDataLabel[index]}
                value={item}
                inputProps={{ readOnly: true }}
                multiline
                fullWidth
                sx={{ backgroundColor: 'white' }}
              />
            </Grid>
          ))}
          <Grid sx={{ display: 'flex', flexDirection: 'column', marginLeft: 2, width: '48%' }}>
            {gridDataValueRight.map((item, index) => (
              <Grid key={index} item xs={12}>
                <TextField
                  variant='outlined'
                  label={gridDataLabeRight[index]}
                  value={item}
                  inputProps={{ readOnly: true }}
                  multiline
                  fullWidth
                  sx={{ backgroundColor: 'white', marginTop: 2 }}
                />
              </Grid>
            ))}
          </Grid>
          <Grid sx={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
            {gridDataValueLeft.map((item, index) => (
              <Grid key={index} item xs={12}>
                <TextField
                  variant='outlined'
                  label={gridDataLabeLeft[index]}
                  value={item}
                  inputProps={{ readOnly: true }}
                  multiline
                  fullWidth
                  sx={{ backgroundColor: 'white', marginTop: 2, marginLeft: 3 }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      <Typography><strong>Permissions</strong></Typography>
      <DataGrid sx={{ backgroundColor: 'white' }} rows={selectedRow || []} columns={selectedColumns} hideFooter autoHeight />
    </Container>
    // </Drawer>
  )
}

export default Detail
