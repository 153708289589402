import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TableCell,
  TableRow
} from '@mui/material'
import { useLocation } from 'react-router-dom'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import Icon from 'components/icon'
import type { ErrorProps } from 'modules/types'
import MenuModalItem from 'components/menuItem/menuItem'
// import type {PartialPromoCodeBatchProps} from 'modules/partial'
import CONSTANT from 'modules/constant'
import {
  useDownloadPromoCodeBatchSubMutation,
  useGetPromoCodeBatchSubListMutation,
  useUpdateStatusPromoCodeBatchSubMutation
} from 'store/promoCodeBatch'
import GLOBAL from 'modules/global'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import useActiveMenu from 'modules/useActiveMenu'
import { UserPermission } from 'modules/enums'
import Detail from './detail/detail'

const PromoBatchList = () => {
  const { hasPermission } = useActiveMenu()

  const locationState = useLocation()
  const [getPromoCodeBatchSubList, promoCodeBatchSub] = useGetPromoCodeBatchSubListMutation()
  const [downloadPromoCodeBatchSub, download] = useDownloadPromoCodeBatchSubMutation()
  const [updateStatusPromoCodeSub, update] = useUpdateStatusPromoCodeBatchSubMutation()
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)

  const [payload, setPayload] = useState<PartialPromoCodeBatchProps & { PromoId: string }>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: '',
    Redemeed: '',
    Status: '',
    PromoId: locationState.state.id || ''
  })

  const [filter, setFilter] = useState({
    Redemeed: '',
    Status: ''
  })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })
  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: value })
  const onResync = () => {
    const formData = new FormData()
    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.Redemeed)) formData.append('Redemeed', payload.Redemeed)
    if (!isEmpty(payload.Status)) formData.append('Status', payload.Status)
    if (!isEmpty(payload.PromoId)) formData.append('PromoId', payload.PromoId)
    getPromoCodeBatchSubList(formData)
  }
  const onFilter = (state: boolean) => setDialogFilter(state)
  const onDownload = (type?: string) => {
    const formData = new FormData()
    formData.append('Start', payload.Start.toString())
    formData.append('Length', String(promoCodeBatchSub.data?.recordsTotal))

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.Redemeed)) formData.append('Redemeed', payload.Redemeed)
    if (!isEmpty(payload.Status)) formData.append('Status', payload.Status)
    if (!isEmpty(payload.PromoId)) formData.append('PromoId', payload.PromoId)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadPromoCodeBatchSub(formData)
  }

  useEffect(() => {
    onResync()
  }, [payload])

  const tableHeadTitles = [
    'Promo Code',
    'Prefix',
    'Promo type',
    'Promo Amount',
    'Maximum Deduction',
    'Start Date',
    'End Date',
    'Redeemed',
    'Status'
  ]

  const openAnchor = Boolean(anchor)
  const totalPage =
    promoCodeBatchSub.data && GLOBAL.tableTotalPage(promoCodeBatchSub.data.recordsFiltered)

  return (
    <>
      <Content
        title={`Promo Batch List > PREFIX: ${locationState.state.name || ''}`}
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        useBackButton
        downloadLoading={download.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={promoCodeBatchSub.data && promoCodeBatchSub.data.recordsFiltered}
        page={payload.Start + 1}
        isLoading={promoCodeBatchSub.isLoading}
        useDownloadDropdown
      >
        {promoCodeBatchSub.isSuccess &&
          promoCodeBatchSub.data &&
          promoCodeBatchSub.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.pkId}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.pkId}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.pkId}`}
                  aria-labelledby={`button-${index}-${table.pkId}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>

                  {hasPermission(UserPermission.Update) && (
                    <MenuModalItem
                      title={table.status === 'DISABLED' ? 'Enable' : 'Disable'}
                      onClick={() =>
                        onRemoveAnchor(() =>
                          updateStatusPromoCodeSub({
                            Id: table.pkId,
                            IsActive: table.status == 'DISABLED'
                          })
                        )
                      }
                    >
                      {table.status == 'DISABLED' ? 'Enable' : 'Disable'}
                    </MenuModalItem>
                  )}
                </Menu>
                {drawerDetail && menu == index && (
                  <Detail
                    id={table.pkId}
                    open={drawerDetail && menu == index}
                    onClose={() => setDrawerDetail(false)}
                    status={table.status}
                  />
                )}
              </TableCell>
              <TableCell>{table.name || '-'}</TableCell>
              <TableCell>{table.promoCode || '-'}</TableCell>
              <TableCell>{table.promoType || '-'}</TableCell>
              <TableCell>{table.value || '-'}</TableCell>
              <TableCell>{GLOBAL.formatCurrency(table.maximumDeduction) || '-'}</TableCell>
              <TableCell>{GLOBAL.formatDateddMMYYLongDate(table.validFrom) || '-'}</TableCell>
              <TableCell>{GLOBAL.formatDateddMMYYLongDate(table.validUntil) || '-'}</TableCell>
              <TableCell>{table.redeemed || '-'}</TableCell>
              <TableCell>
                <Chip
                  label={table.status === 'ENABLED' ? 'Enabled' : 'Disabled'}
                  color={table.status == 'ENABLED' ? 'success' : 'error'}
                />
              </TableCell>
            </TableRow>
          ))}
      </Content>
      {promoCodeBatchSub.isLoading && <Loading />}
      <Notification
        open={
          !promoCodeBatchSub.isLoading &&
          !promoCodeBatchSub.isUninitialized &&
          !promoCodeBatchSub.isSuccess
        }
        onClose={() => (promoCodeBatchSub.isError ? promoCodeBatchSub.reset() : location.reload())}
        isError={Boolean(promoCodeBatchSub.error) && promoCodeBatchSub.isError}
        message={GLOBAL.returnExceptionMessage(
          promoCodeBatchSub.isError,
          promoCodeBatchSub.error as ErrorProps
        )}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setPayload({
            Start: 0,
            Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
            Search: '',
            Redemeed: '',
            Status: '',
            PromoId: locationState.state.id || ''
          })
          setFilter({
            Redemeed: '',
            Status: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({
            ...payload,
            ...filter
          })
          onFilter(false)
        }}
      >
        <FormControl fullWidth>
          <InputLabel id='select-label'>Redemeed</InputLabel>
          <Select
            labelId='select-label'
            id='id-select-label'
            value={filter.Redemeed}
            label='Status'
            onChange={(event) => setFilter({ ...filter, Redemeed: event.target.value })}
          >
            <MenuItem value='Yes'>Yes</MenuItem>
            <MenuItem value='No'>No</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id='select-label'>Status</InputLabel>
          <Select
            labelId='select-label'
            id='id-select-label'
            value={filter.Status}
            label='Status'
            onChange={(event) => setFilter({ ...filter, Status: event.target.value })}
          >
            <MenuItem value='ENABLED'>Enabled</MenuItem>
            <MenuItem value='DISABLED'>Disabled</MenuItem>
          </Select>
        </FormControl>
      </Filter>
    </>
  )
}

export type PartialPromoCodeBatchProps = {
  Start: number
  Length: number
  Search: string
  Redemeed: string
  Status: string
}

export default PromoBatchList
