import CONSTANT from 'modules/constant'
import type { PartialBarChartsProps, PartialDashboardProps, PartialTotalSubscriptionPLanProps, PartialTotalSubscriptionStatusProps } from 'modules/partial'
import type {
    BarChartsProps,
    NumberFormatProps,
    RevenueProps,
    SubscriptionRenewalRateProps,
    TotalSubscriptionPLanProps,
    TotalSubscriptionStatusProps,
    TransactionPaymentMethodProps,
} from 'modules/types'
import { API, headers } from './api'

const DashboardAPI = API.injectEndpoints({
    endpoints: (builder) => ({
        getRevenue: builder.query<RevenueProps, PartialTotalSubscriptionPLanProps>({
            query: (query) => ({
                url: CONSTANT.URL_REVENUE + `?StartDate=${query.StartDate}&EndDate=${query.EndDate}&Duration=${query.Duration}`,
                method: 'GET',
                headers: headers()
            })
        }),
        getSubscriptionRenewalRate: builder.query<SubscriptionRenewalRateProps, PartialDashboardProps>({
            query: (query) => ({
                url: CONSTANT.URL_SUBSCRIPTION_RENEWAL_RATE + `?StartDate=${query.StartDate}&EndDate=${query.EndDate}`,
                method: 'GET',
                headers: headers()
            })
        }),
        getSubscriptionChurnRate: builder.query<SubscriptionRenewalRateProps, PartialDashboardProps>({
            query: (query) => ({
                url: CONSTANT.URL_SUBSCRIPTION_CHURN_RATE + `?StartDate=${query.StartDate}&EndDate=${query.EndDate}`,
                method: 'GET',
                headers: headers()
            })
        }),
        getTotalUserGrowth: builder.query<NumberFormatProps, PartialTotalSubscriptionPLanProps>({
            query: (query) => ({
                url: CONSTANT.URL_TOTAL_USER_GROWTH + `?StartDate=${query.StartDate}&EndDate=${query.EndDate}&Duration=${query.Duration}`,
                method: 'GET',
                headers: headers()
            })
        }),
        getNewSubscriber: builder.query<NumberFormatProps, PartialTotalSubscriptionPLanProps>({
            query: (query) => ({
                url: CONSTANT.URL_NEW_SUBSCRIBER + `?StartDate=${query.StartDate}&EndDate=${query.EndDate}&Duration=${query.Duration}`,
                method: 'GET',
                headers: headers()
            })
        }),
        getRenewedSubscription: builder.query<NumberFormatProps, PartialTotalSubscriptionPLanProps>({
            query: (query) => ({
                url: CONSTANT.URL_RENEWED_SUBSCRIPTION + `?StartDate=${query.StartDate}&EndDate=${query.EndDate}&Duration=${query.Duration}`,
                method: 'GET',
                headers: headers()
            })
        }),
        getTotalTransactionPaidByType: builder.query<BarChartsProps[], PartialBarChartsProps>({
            query: (query) => ({
                url: CONSTANT.URL_TOTAL_TRANSACTION_PAID_BY_TYPE + `?StartDate=${query.StartDate}&EndDate=${query.EndDate}&Type=${query.Type}`,
                method: 'GET',
                headers: headers()
            })
        }),
        getTotalUserByType: builder.query<BarChartsProps[], PartialBarChartsProps>({
            query: (query) => ({
                url: CONSTANT.URL_TOTAL_USER_BY_TYPE + `?StartDate=${query.StartDate}&EndDate=${query.EndDate}&Type=${query.Type}`,
                method: 'GET',
                headers: headers()
            })
        }),
        getTotalRevenueByType: builder.query<BarChartsProps[], PartialBarChartsProps>({
            query: (query) => ({
                url: CONSTANT.URL_TOTAL_REVENUE_BY_TYPE + `?StartDate=${query.StartDate}&EndDate=${query.EndDate}&Type=${query.Type}`,
                method: 'GET',
                headers: headers()
            })
        }),
        getTotalSubscriptionPLan: builder.query<TotalSubscriptionPLanProps, PartialTotalSubscriptionPLanProps>({
            query: (query) => ({
                url: CONSTANT.URL_TOTAL_SUBSCRIPTION_PLAN + `?StartDate=${query.StartDate}&EndDate=${query.EndDate}&Duration=${query.Duration}`,
                method: 'GET',
                headers: headers()
            })
        }),
        getTransactionPaymentMethod: builder.query<TransactionPaymentMethodProps[], PartialTotalSubscriptionPLanProps>({
            query: (query) => ({
                url: CONSTANT.URL_TRANSACTION_PAYMENT_METHOD + `?StartDate=${query.StartDate}&EndDate=${query.EndDate}&Duration=${query.Duration}`,
                method: 'GET',
                headers: headers()
            })
        }),
        getTotalSubscriptionStatus: builder.query<TotalSubscriptionStatusProps, PartialTotalSubscriptionStatusProps>({
            query: (query) => ({
                url: CONSTANT.URL_TOTAL_SUBSCRIPTION_STATUS + `?StartDate=${query.StartDate}&EndDate=${query.EndDate}`,
                method: 'GET',
                headers: headers()
            })
        }),
        getTotalSubscription: builder.query<TotalSubscriptionStatusProps, PartialTotalSubscriptionStatusProps>({
            query: (query) => ({
                url: CONSTANT.URL_TOTAL_SUBSCRIPTION + `?StartDate=${query.StartDate}&EndDate=${query.EndDate}`,
                method: 'GET',
                headers: headers()
            })
        }),
        getTotalUsers: builder.query<TotalSubscriptionStatusProps, PartialTotalSubscriptionStatusProps>({
            query: (query) => ({
                url: CONSTANT.URL_TOTAL_USERS + `?StartDate=${query.StartDate}&EndDate=${query.EndDate}`,
                method: 'GET',
                headers: headers()
            })
        }),
    })
})

export const {
    useLazyGetRevenueQuery,
    useLazyGetSubscriptionRenewalRateQuery,
    useLazyGetSubscriptionChurnRateQuery,
    useLazyGetTotalSubscriptionPLanQuery,
    useLazyGetTotalUserGrowthQuery,
    useLazyGetNewSubscriberQuery,
    useLazyGetRenewedSubscriptionQuery,
    useLazyGetTotalTransactionPaidByTypeQuery,
    useLazyGetTotalUserByTypeQuery,
    useLazyGetTotalRevenueByTypeQuery,
    useLazyGetTransactionPaymentMethodQuery,
    useLazyGetTotalSubscriptionStatusQuery,
    useLazyGetTotalSubscriptionQuery,
    useLazyGetTotalUsersQuery
} = DashboardAPI
