import { isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
import { Breadcrumbs, Grid, Stack, Typography } from '@mui/material'
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs'
import { useGetUserMenuQuery } from 'store/userMenu'
import Menu from 'components/menu/menu'
import BreadcrumbStyle from './style'

const Breadcrumb = ({ id, sideComponent }: BreadcrumbProps) => {
  const { data: userMenu } = useGetUserMenuQuery()

  const breadcrumbs = useReactRouterBreadcrumbs()
  const current = userMenu?.mainMenu.find((menu) => breadcrumbs.some((bread) => menu.path == bread.key))
  const title = (current && current.name) || breadcrumbs[breadcrumbs.length > 1 ? 1 : 0].breadcrumb

  return (
    <>
      <Grid container spacing={2} p={2} id={id} {...BreadcrumbStyle.Container}>
        <Grid item flex={1}>
          <Typography {...BreadcrumbStyle.Title}>{title}</Typography>
          <Stack>
            <Breadcrumbs separator='›' aria-label='breadcrumb' sx={{ textTransform: 'lowercase' }}>
              {breadcrumbs.map(({ breadcrumb, key }, index) => (
                <Link key={index} {...BreadcrumbStyle.Link} to={key}>
                  {breadcrumb}
                </Link>
              ))}
            </Breadcrumbs>
          </Stack>
        </Grid>
        <Grid item>{sideComponent}</Grid>
      </Grid>
      {!isEmpty(current) && !isEmpty(current.subMenu) && <Menu menu={current.subMenu} />}
    </>
  )
}

interface BreadcrumbProps {
  id?: string
  sideComponent?: JSX.Element
}

export default Breadcrumb
