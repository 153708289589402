import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getUserMenuState } from 'store/userMenu.slice'
import type { UserSubMenuProps } from './types'
import { UserPermission } from './enums'

const useActiveMenu = () => {
  const location = useLocation()

  const userMenu = useSelector(getUserMenuState)

  const [activeMenu, setActiveMenu] = useState<UserSubMenuProps>()

  useEffect(() => {
    setActiveMenu(userMenu.find((menu) => menu.path === location.pathname))
  }, [userMenu])

  const hasPermission = (permission: UserPermission): boolean =>
    Boolean(activeMenu?.permission.find((p) => p.split(':').pop() === UserPermission[permission]))

  return {
    hasPermission
  }
}

export default useActiveMenu
