import { useFormik } from 'formik'
import * as yup from 'yup'
import { Container, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import Loading from 'components/loading/loading'
import type {
  DetailStateProps,
  ErrorProps,
  UserAccountListProps,
  UserResetPasswordProps,
} from 'modules/types'
import { useGetUsersAccountDetailsssQuery, useUpdateUsersAccountPhoneMutation } from 'store/control'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import DialogStyle from './style'

const Edit = ({ open, user, onClose }: DetailStateProps & { user: UserAccountListProps }) => {
  if (!open) return <></>
  console.log('user.phone', user.phone);

  const { data, isFetching, isLoading } = useGetUsersAccountDetailsssQuery(user.userId)

  const [resetPassword, reset] = useUpdateUsersAccountPhoneMutation()

  const onSubmit = (values: UserResetPasswordProps) => resetPassword(values)

  const scheme = yup.object<UserResetPasswordProps>({
    MobileNumber: yup
      .string()
      .required('Phone Number is required')
      .min(11, 'Phone Number is not valid')
      .max(15, 'Maximum Length is 15')
      .matches(/^\d+$/, 'Phone Number must be a number')
  })

  const formik = useFormik<UserResetPasswordProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnChange: true,
    // initialValues: {
    //   UserId: (user && user.userId) || '',
    //   MobileNumber: (user && user.phone)
    // },
    initialValues: {
      UserId: (data && data.userCode) || '',
      MobileNumber: (data && data.mobileNumber) || '',
    },
    onSubmit: onSubmit
  })
  const disabled = !formik.isValid
  console.log('Formik Errors:', formik.errors);
  console.log('Formik Touched:', formik.touched);
  console.log('Formik Values:', formik.values);
  console.log('Is Valid:', !formik.isValid);
  return (
    <>
      <Dialog
        title='Edit User'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={reset.isLoading}
        isDisabled={disabled}
      >
        <Container {...DialogStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <TextField
                variant='outlined'
                label='Full Name'
                disabled={true}
                value={user.fullName}
                inputProps={{ readOnly: true }}
                fullWidth
              />
              <TextField
                variant='outlined'
                label='Email'
                value={user.email}
                disabled={true}
                inputProps={{ readOnly: true }}
                fullWidth
              />
              <TextField
                id='MobileNumber'
                variant='outlined'
                label='Phone Number'
                type='text'
                value={formik.values.MobileNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.MobileNumber && Boolean(formik.errors.MobileNumber)}
                helperText={formik.touched.MobileNumber && formik.errors.MobileNumber}
                fullWidth
              />
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!reset.isLoading && !reset.isUninitialized}
        onClose={() => (reset.isError ? reset.reset() : location.reload())}
        isError={Boolean(reset.error) && reset.isError}
        message={GLOBAL.returnExceptionMessage(reset.isError, reset.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
