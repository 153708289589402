import { useEffect, useState } from 'react'
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField
} from '@mui/material'
import Filter from 'components/filter/filter'
import Content from 'components/content/content'
import type { ErrorProps } from 'modules/types'
import Icon from 'components/icon'
import GlobalStyle from 'modules/styles'
import type { PartialConfigProps } from 'modules/partial'
import CONSTANT from 'modules/constant'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import {
  useDownloadConfigListMutation,
  useLazyGetConfigCategoryListQuery,
  useLazyGetConfigListQuery
} from 'store/config'
import useActiveMenu from 'modules/useActiveMenu'
import { UserPermission } from 'modules/enums'
import Detail from './detail/detail'
import Edit from './edit/edit'

const ConfigList = () => {
  const { hasPermission } = useActiveMenu()

  const [getConfigList, configListResult] = useLazyGetConfigListQuery()

  const [getConfigCategoryList, configCategoryListResult] = useLazyGetConfigCategoryListQuery()

  const [downloadConfigList, downloadConfigListResult] = useDownloadConfigListMutation()

  const [filter, setFilter] = useState({
    Category: '',
    RewardType: '',
    MinValidFrom: '',
    MaxValidFrom: '',
    MinValidTo: '',
    MaxValidTo: '',
    MinCreatedAt: '',
    MaxCreatedAt: ''
  })

  const [payload, setPayload] = useState<PartialConfigProps>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: '',
    Category: '',
    RewardType: '',
    MinValidFrom: '',
    MaxValidFrom: '',
    MinValidTo: '',
    MaxValidTo: '',
    MinCreatedAt: '',
    MaxCreatedAt: '',
    Status: ''
  })
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  //   const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [randomNumber, setRandomNumber] = useState<number>(0)

  const [selectedId, setSelectedId] = useState<number>(0)

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: value })
  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })

  const onResync = () => getConfigList(payload)

  const onFilter = (state: boolean) => setDialogFilter(state)

  const onDownload = (type?: string) => {
    const lengthValue = String(configListResult.data?.recordsTotal);
    downloadConfigList({ ...payload, type, lengthValue })
  }

  useEffect(() => {
    onResync()
  }, [payload])

  const tableHeadTitles = ['Config Code', 'Config Name', 'Category', 'Value']
  const numbers = GLOBAL.tableNumber(payload.Start)
  const openAnchor = Boolean(anchor)

  console.log('loading ', configListResult.isLoading);
  

  return (
    <>
      <Content
        title='Config List'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        // onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={downloadConfigListResult.isLoading}
        useDownloadDropdown
        // onAdditional={onCreate}
        additionalTitle='Create New Plan'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={
          configListResult.data && GLOBAL.tableTotalPage(configListResult.data.recordsTotal)
        }
        totalRecords={configListResult.data && configListResult.data.recordsTotal}
        page={payload.Start + 1}
        isLoading={configListResult.isLoading}
      >
        {configListResult.isSuccess &&
          configListResult.data &&
          configListResult.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.pkId}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.pkId}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => {
                    onAction(event, index)
                    setSelectedId(table.pkId)
                  }}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.pkId}`}
                  aria-labelledby={`button-${index}-${table.pkId}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>

                  {hasPermission(UserPermission.Update) && (
                    <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                      Edit
                    </MenuItem>
                  )}
                </Menu>
              </TableCell>
              <TableCell>{table.code || '-'}</TableCell>
              <TableCell>{table.name || '-'}</TableCell>
              <TableCell>{table.category || '-'}</TableCell>
              <TableCell {...GlobalStyle.EllipsisTable}>{table.value || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>

      <Detail id={selectedId} open={drawerDetail} onClose={() => setDrawerDetail(false)} />

      <Edit id={selectedId} open={dialogUpdate} onClose={() => setDialogUpdate(false)} />

      {/* {dialogCreate && <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />} */}

      {configListResult.isLoading && <Loading />}

      <Notification
        open={
          !configListResult.isLoading &&
          !configListResult.isUninitialized &&
          !configListResult.isSuccess
        }
        // onClose={() => (configListResult.isError ? configListResult.reset() : location.reload())}
        isError={Boolean(configListResult.error) && configListResult.isError}
        message={GLOBAL.returnExceptionMessage(
          configListResult.isError,
          configListResult.error as ErrorProps
        )}
      />

      {/* <Notification
        open={!deletePromo.isLoading && !deletePromo.isUninitialized}
        onClose={() => (deletePromo.isError ? deletePromo.reset() : location.reload())}
        isError={Boolean(deletePromo.error) && deletePromo.isError}
        message={GLOBAL.returnExceptionMessage(
          deletePromo.isError,
          deletePromo.error as ErrorProps
        )}
      /> */}

      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setRandomNumber(randomNumber + 1)
          setFilter({
            Category: '',
            RewardType: '',
            MinValidFrom: '',
            MaxValidFrom: '',
            MinValidTo: '',
            MaxValidTo: '',
            MinCreatedAt: '',
            MaxCreatedAt: ''
          })
          // setDate({
          //   minValidFrom: null,
          //   maxValidFrom: null,
          //   minValidUntil: null,
          //   maxValidUntil: null,
          //   minCreatedDate: null,
          //   maxCreateDate: null
          // })
          setPayload({
            Start: 0,
            Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
            Search: '',
            Category: '',
            RewardType: '',
            MinValidFrom: '',
            MaxValidFrom: '',
            MinValidTo: '',
            MaxValidTo: '',
            MinCreatedAt: '',
            MaxCreatedAt: '',
            Status: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({
            ...payload,
            ...filter
          })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={(configCategoryListResult && configCategoryListResult.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getConfigCategoryList()}
          onChange={(_, category) =>
            setFilter({
              ...filter,
              Category: (category && String(category.name)) || ''
            })
          }
          value={
            (configCategoryListResult &&
              configCategoryListResult.data &&
              configCategoryListResult.data.find((e) => String(e.name) == filter.Category)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='category'
              label='Category'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {configCategoryListResult.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Filter>

      <Notification
        open={!downloadConfigListResult.isLoading && !downloadConfigListResult.isUninitialized}
        onClose={() => downloadConfigListResult.reset()}
        isError={Boolean(downloadConfigListResult.error) && downloadConfigListResult.isError}
        message={GLOBAL.returnExceptionMessage(
          downloadConfigListResult.isError,
          downloadConfigListResult.error as ErrorProps
        )}
      />
    </>
  )
}

export default ConfigList
