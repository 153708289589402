import React from 'react'
import type { TooltipProps } from '@mui/material'
import { styled, Tooltip, tooltipClasses } from '@mui/material'

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#0f0f0f'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#0f0f0f'
  }
}))

export default CustomTooltip
