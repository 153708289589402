import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import moment from 'moment'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import type { DetailStateProps } from 'modules/types'
import { useGetConfigDetailQuery, useGetConfigPaymentMethodListQuery } from 'store/config'
import { IOSSwitch } from 'components/iosSwitch/iosSwitch'
import DrawerStyle from './style'


const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const configPaymentMethodListResult = useGetConfigPaymentMethodListQuery()

  const { data, isFetching, isLoading } = useGetConfigDetailQuery(id)

  const loading = !data && (isLoading || isFetching)

  const code = (data && data.code) || '-'
  const category = (data && data.category) || '-'
  const name = data && data.name
  const value = data && data.value

  const gridDataLabel = ['Config Code', 'Category', 'Config Name', 'Value']

  const gridDataValue = [code, category, name, value]

    const formatDateWithTime = (value: string) =>
      value ? moment(value).format('DD MMM YYYY HH:mm') : '-';
    
  const createdBy = (data && data.createdByName) || '-'
  const createdAt = (data && formatDateWithTime(data.createdDate)) || '-'
  const modifiedBy = (data && data.modifiedByName) || '-'
  const modifiedAt = (data && formatDateWithTime(data.modifiedDate)) || '-'

  return (
    <>
      <Drawer open={open} title='Voucher Usage Detail' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {loading && <Loading />}
          {!loading && (
            <>
              <Grid container spacing={2}>
                {gridDataValue.map((item, index) => {
                  if (index === 3) {
                    const values = item as string[]

                    switch (code) {
                      case 'PAYMENT_METHOD':
                        return (
                          <Grid key={index} item xs={12}>
                            <FormGroup>
                              {configPaymentMethodListResult.data?.map((v, id) => (
                                <FormControlLabel
                                  key={id}
                                  label={v.name}
                                  disabled
                                  control={
                                    <Checkbox
                                      value={v.id}
                                      defaultChecked={values.indexOf(v.name) !== -1}
                                    />
                                  }
                                />
                              ))}
                            </FormGroup>
                          </Grid>
                        )

                      case 'AUTO_RENEWAL':
                        return (
                          <Grid key={index} item xs={12}>
                            <FormControlLabel
                              labelPlacement='start'
                              label={values[0] === 'Disable' ? 'Disable' : 'Enable'}
                              disabled
                              control={
                                <IOSSwitch sx={{ mx: 1 }} defaultChecked={Boolean(values[0] === 'Enable')} />
                              }
                            />
                          </Grid>
                        )
                    }
                  }

                  return (
                    <Grid key={index} item xs={12}>
                      <TextField
                        variant='outlined'
                        label={gridDataLabel[index]}
                        value={item}
                        inputProps={{ readOnly: true }}
                        fullWidth
                      />
                    </Grid>
                  )
                })}
              </Grid>
              <Accordion elevation={0} disableGutters>
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined />}
                  aria-controls='basic-information'
                >
                  <Typography>Basic Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ marginTop: '4px', }}>
                      <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Created By</Typography>
                      <Typography>{createdBy}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: '4px', }}>
                      <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Last Modified By</Typography>
                      <Typography>{modifiedBy}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: '4px', }}>
                      <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Created Date</Typography>
                      <Typography>{createdAt}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: '4px', }}>
                      <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Last Modified Date</Typography>
                      <Typography>{modifiedAt}</Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export default Detail
