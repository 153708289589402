import CONSTANT from 'modules/constant'
import type { SasTokenProps } from 'modules/types'
import { API, headers } from './api'

const BlobAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getSasToken: builder.query<SasTokenProps, string>({
      query: (url) => ({
        url: CONSTANT.URL_GET_SAS_TOKEN,
        params: { fileUrl: url },
        method: 'GET',
        headers: headers()
      })
    }),
    downloadFile: builder.query<string, string>({
      query: (fileUrl) => ({
        url: CONSTANT.URL_DOWNLOAD_FILE,
        method: 'GET',
        headers: headers({ useContentType: false }),
        params: { fileUrl },
        responseHandler: async (response: Response) => {
          const contentType = response.headers.get('Content-Type')
          if (response.status !== 200 || contentType === 'application/json')
            return await response.json()

          const blob = await response.blob()
          return { data: URL.createObjectURL(blob) }
        }
      })
    })
  })
})

export const { useLazyGetSasTokenQuery, useLazyDownloadFileQuery } = BlobAPI
