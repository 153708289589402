import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import moment from 'moment'
import GLOBAL from 'modules/global'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import type { Children } from 'modules/types'
import { useGetDetailDiscountSettingQuery } from 'store/discountSetting'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose, status }: DetailStatePlanProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetDetailDiscountSettingQuery(id)

  const loading = !data && (isLoading || isFetching)

  const discountCode = (data && data.pkId) || '-'
  const discountName = (data && data.name) || '-'
  const description = data && data.description || '-'
  const isPercent = data && data.isPercent || '-'
  const discountAmount = data && data.value || null
  const validFrom = data && GLOBAL.formatDateddMMYYLongDateDetail(data.validFrom) || '-'
  const validUntil = data && GLOBAL.formatDateddMMYYLongDateDetail(data.validUntil) || '-'
  const subscriptionPlanNames = data?.subscriptionPlan.map(plan => plan.subscriptionPlanName).join(', ');
  const applicableSubsPlan = data && subscriptionPlanNames

  const gridDataLabel = [
    'Discount Code',
    'Discount Name',
  ]
  const gridDataValue = [
    discountCode,
    discountName
  ]
  const gridDataLabelTwo = [
    'Discount Type',
    'Discount Amount',
    'Start Date',
    'End Date',
    'Applicable Subscription Plan'
  ]

  const gridDataValueTwo = [
    isPercent,
    discountAmount,
    validFrom,
    validUntil,
    applicableSubsPlan

  ]

  const formatDateWithTime = (value: string) =>
    value ? moment(value).format('DD MMM YYYY HH:mm') : '-';

  const createdBy = (data && data.createdByName) || '-'
  const createdAt = (data && formatDateWithTime(data.createdDate)) || '-'
  const modifiedBy = (data && data.modifiedByName) || '-'
  const modifiedAt = (data && formatDateWithTime(data.modifiedDate)) || '-'

  const statusLabel = status === null ? '-' : (status === 'ENABLED' ? 'Enabled' : 'Disabled');
  const statusColor = status === 'ENABLED' ? 'success' : 'error';

  return (
    <>
      <Drawer open={open} title='Detail Discount' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {loading && <Loading />}
          {!loading && (
            <>
              <Grid container spacing={2}>
                {gridDataValue.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <TextField
                      variant='outlined'
                      label={gridDataLabel[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                ))}
              </Grid>
              <TextField
                value={description}
                id='Descrption'
                variant='outlined'
                label='Descrption'
                multiline
                rows={3.8}
                inputProps={{ readOnly: true }}
                {...DrawerStyle.textField}
                fullWidth
              />
              <Grid container spacing={2}>
                {gridDataValueTwo.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <TextField
                      variant='outlined'
                      label={gridDataLabelTwo[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid container>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    paddingLeft: '8px'
                  }}>
                  {status !== null ? (
                    <>
                      <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Status</Typography>
                      <Chip
                        sx={{ marginTop: '3px' }}
                        label={statusLabel}
                        color={statusColor}
                      />
                    </>
                  ) : (
                    <>
                      <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Status</Typography>
                      <Typography>{statusLabel}</Typography>
                    </>
                  )}
                </Box>
              </Grid>
              <Accordion elevation={0} disableGutters>
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined />}
                  aria-controls='basic-information'
                >
                  <Typography>Basic Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ marginTop: '4px', }}>
                      <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Created By</Typography>
                      <Typography>{createdBy}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: '4px', }}>
                      <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Last Modified By</Typography>
                      <Typography>{modifiedBy}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: '4px', }}>
                      <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Created Date</Typography>
                      <Typography>{createdAt}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: '4px', }}>
                      <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Last Modified Date</Typography>
                      <Typography>{modifiedAt}</Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export default Detail

export type DetailStatePlanProps = Children & {
  title?: string
  open: boolean
  maxWidth?: 'sm' | 'md'
  onClose: () => void,
  status: string
}
