/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsMore from 'highcharts/highcharts-more'
import solidGauge from 'highcharts/modules/solid-gauge'

highchartsMore(Highcharts)
solidGauge(Highcharts)

export const GaugeChart = ({ name, color, value, tooltip }: GaugeChartProps) => {
  const [plotRadius, setPlotRadius] = useState<number>(1)

  const options = {
    chart: {
      height: 200,
      events: {
        render: function (this) {
          const series = this.series[0]
          setPlotRadius(series.points[0].shapeArgs?.r || 1)
        }
      }
    },
    title: {
      text: undefined
    },
    credits: { enabled: false },
    pane: {
      background: undefined,
      startAngle: -90,
      endAngle: 90,
      size: '120%',
      center: ['50%', '80%']
    },
    yAxis: {
      min: 0,
      max: 100,
      tickColor: 'transparent',
      minorTickColor: 'transparent',
      tickInterval: 100,
      minorTickInterval: undefined,
      lineWidth: 0,
      labels: { enabled: false },
      plotBands: [
        {
          from: 0,
          to: 100,
          color: '#e5eafc',
          borderRadius: 6,
          innerRadius: '83%'
        }
      ]
    },
    plotOptions: {
      series: { states: { inactive: { opacity: 1 } } },
      solidgauge: {
        borderRadius: 6,
        dataLabels: {
          y: 5,
          borderWidth: 0,
          useHTML: true
        }
      }
    },
    tooltip: {
      enabled: Boolean(tooltip),
      format: `<div style="width: 120px; white-space: break-spaces; color: white; font-size: 10px">${tooltip}</div>`,
      useHTML: true,
      outside: true,
      backgroundColor: '#0f0f0f',
      positioner: function (boxWidth) {
        return {
          x: this.chart.plotLeft + this.chart.plotWidth / 2 - boxWidth / 2,
          y: this.chart.plotHeight + 20
        }
      }
    },
    series:
      value > 0
        ? ([
          {
            type: 'solidgauge',
            name,
            data: [
              {
                color,
                radius: '100%',
                innerRadius: '83%',
                y: Number(value || 0)
              }
            ],
            dataLabels: {
              format: [
                `<div style="text-align: center; width: ${plotRadius * 1.25}px; position: absolute; top: -${plotRadius / 1.6}px; transform: translateX(-50%)">`,
                `<div style="font-size: ${plotRadius / 4.6}px; font-weight: 500">{y}%</div>`,
                `<div style="font-size: ${plotRadius / 8}px; font-weight: normal; color: #999; white-space: break-spaces; position: relative;">`,
                name,
                  `<div style=" display: flex; justify-content: center; align-items: center; transform: translate(43%, 5%);">
                    <svg xmlns="http://www .w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" color="#00aad2" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                    </svg>
                   </div>`,
                '</div>',
                '</div>'
              ].join(''),
              useHTML: true,
              borderWidth: 0,
              color:
                (Highcharts.defaultOptions.title &&
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color) ||
                '#333333',
              style: {
                fontFamily: 'Hyundai Sans Head Office',
                fontSize: '16px'
              }
            }
          },
          // {
          //   name: 'Pointer',
          //   isRectanglePoint: true,
          //   type: 'gauge',
          //   data: [value || 0],
          //   dial: {
          //     backgroundColor: color,
          //     rearLength: '0%'
          //   },
          //   dataLabels: {
          //     enabled: false
          //   },
          //   pivot: {
          //     radius: 0
          //   }
          // }
        ] as any[])
        : ([
          {
            type: 'solidgauge',
            name,
            data: [
              {
                color,
                radius: '100%',
                innerRadius: '83%',
                y: Number(value || 0)
              }
            ],
            dataLabels: {
              format: [
                `<div style="text-align: center; width: ${plotRadius * 1.25
                }px; position: absolute; top: -${plotRadius / 1.6
                }px; transform: translateX(-50%)">`,
                `<div style="font-size: ${plotRadius / 4.6}px; font-weight: 500">{y}%</div>`,
                `<div style="font-size: ${plotRadius / 8
                }px; font-weight: normal; color: #999; white-space: break-spaces">`,
                name,
                '</div>',
                '</div>'
              ].join(''),
              useHTML: true,
              borderWidth: 0,
              color:
                (Highcharts.defaultOptions.title &&
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color) ||
                '#333333',
              style: {
                fontFamily: 'Hyundai Sans Head Office',
                fontSize: '16px'
              }
            }
          }
        ] as any[])
  } as Highcharts.Options

  return <HighchartsReact highcharts={Highcharts} options={options} />
};

// const H: any = Highcharts
// const each = H.each,
//   merge = H.merge,
//   pInt = H.pInt,
//   pick = H.pick,
//   isNumber = H.isNumber as (value: number) => boolean

// ;(Highcharts as any).seriesTypes.gauge.prototype.translate = function () {
//   const series = this,
//     yAxis = series.yAxis,
//     options = series.options,
//     center = yAxis.center

//   series.generatePoints()

//   each(series.points, function (point: any) {
//     const dialOptions = merge(options.dial, point.dial),
//       isRectanglePoint = point.series.userOptions.isRectanglePoint,
//       radius = (pInt(pick(dialOptions.radius, 80)) * center[2]) / 200,
//       baseLength = (pInt(pick(dialOptions.baseLength, 70)) * radius) / 100,
//       rearLength = (pInt(pick(dialOptions.rearLength, 10)) * radius) / 100,
//       baseWidth = dialOptions.baseWidth || 3,
//       topWidth = dialOptions.topWidth || 1

//     let overshoot = options.overshoot,
//       rotation = yAxis.startAngleRad + yAxis.translate(point.y, null, null, null, true)

//     // Handle the wrap and overshoot options
//     if (isNumber(overshoot)) {
//       overshoot = (overshoot / 180) * Math.PI
//       rotation = Math.max(
//         yAxis.startAngleRad - overshoot,
//         Math.min(yAxis.endAngleRad + overshoot, rotation)
//       )
//     } else if (options.wrap === false) {
//       rotation = Math.max(yAxis.startAngleRad, Math.min(yAxis.endAngleRad, rotation))
//     }

//     rotation = (rotation * 180) / Math.PI

//     // Checking series to draw dots
//     if (isRectanglePoint) {
//       //draw new dial
//       point.shapeType = 'path'
//       point.shapeArgs = {
//         d: dialOptions.path || [
//           'M',
//           -rearLength + 96,
//           -baseWidth / 20,
//           'm',
//           16,
//           0,
//           'a',
//           16,
//           16,
//           0,
//           1,
//           0,
//           -32,
//           0,
//           'a',
//           16,
//           16,
//           0,
//           1,
//           0,
//           32,
//           0
//         ],
//         translateX: center[0],
//         translateY: center[1],
//         rotation,
//         style: 'stroke: white; stroke-width: 4;'
//       }
//     } else {
//       //draw standard dial
//       point.shapeType = 'path'
//       point.shapeArgs = {
//         d: dialOptions.path || [
//           'M',
//           -rearLength,
//           -baseWidth / 2,
//           'L',
//           baseLength,
//           -baseWidth / 2,
//           radius,
//           -topWidth / 2,
//           radius,
//           topWidth / 2,
//           baseLength,
//           baseWidth / 2,
//           -rearLength,
//           baseWidth / 2,
//           'z'
//         ],
//         translateX: center[0],
//         translateY: center[1],
//         rotation: rotation
//       }
//     }

//     // Positions for data label
//     point.plotX = center[0]
//     point.plotY = center[1]
//   })
// }

interface GaugeChartProps {
  color: string
  name: string
  value: number
  tooltip?: string
}
