import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import moment from 'moment'
import Icon from 'components/icon'
// import Loading from 'components/loading/loading'
import type { Children } from 'modules/types'
import { useGetDetailPromoCodeBatchSubsDetailQuery } from 'store/promoCodeBatch'
import Loading from 'components/loading/loading'
import GLOBAL from 'modules/global'
import DialogStyle from './style'

const Detail = ({ open, id, onClose, status }: DetailStatePlanProps & { id: number }) => {
  if (!open) return <></>

  const { data, isLoading, isFetching } = useGetDetailPromoCodeBatchSubsDetailQuery(id)

  const setValue = (value: number, isPercent: string) => {
    if (isPercent !== 'Deduct Price') {
      return `${value} %`;
    } else {
      return `Rp ${GLOBAL.formatCurrency(value)}`;
    }
  };
  
  const promoCodeID = (data && data.code) || '-'
  const promoCode = (data && data.name) || '-'
  const prefix = (data && data.promoCode) || '-'
  const redeemed = (data && data.redeemed) || '-'
  const descrption = (data && data.description) || '-'
  const promoType = (data && data.isPercent) || '-'
  const promoAmount = (data && setValue(data.value, data.isPercent)) || '-'
  const maximumDeduction = (data && data.maximumDeduction) || '-'
  const minimumPayment = (data && data.minimumPayment) || '-'
  const validFrom = (data && GLOBAL.formatDate(data.validFrom)) || '-'
  const validUntil = (data && GLOBAL.formatDate(data.validUntil)) || '-'

  const gridDataLabelFirstLeft = ['Promo Code ID', 'Prefix']
  const gridDataValueFirstLeft = [promoCodeID, prefix]

  const gridDataLabelRight = [
    'Promo Code',
    'Redeemed'
  ]
  const gridDataValueRight = [promoCode, redeemed]


  const gridDataLabelSecondLeft = [
    'Promo Type',
    'Maximum Deduction',
    'Start Date'
  ]

  const gridDataValueSecondLeft = [
    promoType,
    maximumDeduction,
    validFrom
  ]
  const gridDataLabelSecondRight = [
    'Promo Amount',
    'Minimum Payment',
    'End Date'
  ]

  const gridDataValueSecondRight = [
    promoAmount,
    minimumPayment,
    validUntil
  ]

  const formatDateWithTime = (value: string) =>
    value ? moment(value).format('DD MMM YYYY HH:mm') : '-';

  const createdBy = (data && data.createdByName) || '-'
  const createdAt = (data && formatDateWithTime(data.createdDate)) || '-'
  const modifiedBy = (data && data.modifiedByName) || '-'
  const modifiedAt = (data && formatDateWithTime(data.modifiedDate)) || '-'

  const statusLabel = status === null ? '-' : (status === 'ENABLED' ? 'Enabled' : 'Disabled');
  const statusColor = status === 'ENABLED' ? 'success' : 'error';


  return (
    <Dialog open={open} fullWidth maxWidth='md' onClose={onClose}>
      <DialogContent>
        <DialogTitle
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Typography variant='h6'>Detail Promo Code Batch List</Typography>
          <Box>
            <IconButton onClick={onClose}>
              <Icon icon='Close' />
            </IconButton>
          </Box>
        </DialogTitle>
        {(isLoading || isFetching) && !data && <Loading />}

        {data && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{
              display: 'flex', flexDirection: 'row', alignItems: 'flex-start',
              justifyContent: 'space-between',
              position: 'relative'
            }}>
              <Box sx={{ padding: '10px 24px 24px 10px', flex: 1 }}>
                {gridDataValueFirstLeft.map((item, index) => (
                  <TextField
                    key={index}
                    {...DialogStyle.textField}
                    value={item}
                    variant='outlined'
                    inputProps={{ readOnly: true }}
                    label={gridDataLabelFirstLeft[index]}
                    fullWidth
                  />
                ))}
              </Box>
              <Box
                sx={{
                  flex: 1
                }}
              >
                <Box sx={{ padding: '10px 24px' }}>
                  {gridDataValueRight.map((item, index) => (
                    <TextField
                      key={index}
                      {...DialogStyle.textField}
                      value={item}
                      variant='outlined'
                      inputProps={{ readOnly: true }}
                      label={gridDataLabelRight[index]}
                      fullWidth
                    />
                  ))}
                </Box>
              </Box>
            </Box>
            <Box sx={{ paddingLeft: '10px', paddingRight: '24px', marginTop: -2 }}>
              <TextField
                value={descrption}
                id='subDistrict'
                variant='outlined'
                label='Descrption'
                multiline
                rows={2}
                inputProps={{ readOnly: true }}
                {...DialogStyle.textField}
                fullWidth
              />
            </Box>
            <Box sx={{
              display: 'flex', flexDirection: 'row', alignItems: 'flex-start',
              justifyContent: 'space-between',
              position: 'relative'
            }}>
              <Box sx={{ padding: '10px 24px 24px 10px', flex: 1 }}>
                {gridDataValueSecondLeft.map((item, index) => (
                  <TextField
                    key={index}
                    {...DialogStyle.textField}
                    value={item}
                    variant='outlined'
                    inputProps={{ readOnly: true }}
                    label={gridDataLabelSecondLeft[index]}
                    fullWidth
                  />
                ))}
              </Box>
              <Box
                sx={{
                  flex: 1
                }}
              >
                <Box sx={{ padding: '10px 24px' }}>
                  {gridDataValueSecondRight.map((item, index) => (
                    <TextField
                      key={index}
                      {...DialogStyle.textField}
                      value={item}
                      variant='outlined'
                      inputProps={{ readOnly: true }}
                      label={gridDataLabelSecondRight[index]}
                      fullWidth
                    />
                  ))}
                </Box>
              </Box>
            </Box>
            <Accordion elevation={0} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                aria-controls='basic-information'
              >
                <Typography>Basic Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={1.6}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                      }}>
                      {status !== null ? (
                        <>
                          <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Status</Typography>
                          <Chip
                            label={statusLabel}
                            color={statusColor}
                          />
                        </>
                      ) : (
                        <>
                          <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Status</Typography>
                          <Typography>{statusLabel}</Typography>
                        </>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: '4px', }}>
                    <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Created By</Typography>
                    <Typography>{createdBy}</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: '4px', }}>
                    <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Last Modified By</Typography>
                    <Typography>{modifiedBy}</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: '4px', }}>
                    <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Created Date</Typography>
                    <Typography>{createdAt}</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: '4px', }}>
                    <Typography sx={{ color: '#737373', fontFamily: 'Hyundai Sans Text KR', fontSize: '14px', fontWeight: 400, lineHeight: '17.72px' }}>Last Modified Date</Typography>
                    <Typography>{modifiedAt}</Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}
export type DetailStatePlanProps = Children & {
  title?: string
  open: boolean
  maxWidth?: 'sm' | 'md'
  onClose: () => void,
  status: string
}

export type subscriptionPlanList = {
  planPkId: number
  subscriptionPlanName: string
}

export type CarModelDataProps = {
  carModelId: number
  pkId: number
  carName: string
}

export default Detail
