import { Box, CircularProgress } from '@mui/material'

export const DashboardEmpty = ({ loading = false, title }: EmptyProps) => (
  <>
    {title && (
      <div
        style={{
          fontFamily: 'Hyundai Sans Head Office',
          fontWeight: '500',
          fontSize: '18px',
          paddingTop: '0.4rem',
          paddingLeft: '0.6rem'
        }}
      >
        {title}
      </div>
    )}

    <Box
      position='absolute'
      top='50%'
      left='50%'
      textAlign='center'
      sx={{ transform: 'translate(-50%, -50%)' }}
    >
      {loading ? (
        <>
          <CircularProgress size={12} sx={{ mr: 1 }} />
          Loading...
        </>
      ) : (
        <>🚫 Data not found</>
      )}
    </Box>
  </>
)

interface EmptyProps {
  loading?: boolean
  title?: string
}
