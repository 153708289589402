import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import type { GridColDef, GridRowId, GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid'
import * as yup from 'yup'
import { DataGrid } from '@mui/x-data-grid'
import {
  Autocomplete,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import Dialog from 'components/dialog/dialog'
import Icon from 'components/icon'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import {
  useGetAccountCheckUserListsssMutation,
  useGetMainMenuListMutation,
  useGetSubMenuListMutation,
  useUpdateAccountPermissionMutation
} from 'store/assignment'
import { useGetRolesListAllDropdownMutation } from 'store/roles'
import type { PartialMenuPermissionProps } from 'modules/partial'
import type {
  DetailStateProps,
  ErrorProps,
  RolesAssignmentFunctionProps,
  RolesAssignmentMenuProps
} from 'modules/types'
import GlobalStyle from 'modules/styles'
import useDebounce from 'modules/useDebounce'
import DialogStyle from './style'

type SubMenuProps = {
  menu?: string
  name: string
  id: number
  parentLevel1Id?: number
  parentLevel2Id?: number
  functions: RolesAssignmentFunctionProps[]
  convertedFunctions: string
}

const Create = ({ open, onClose }: DetailStateProps) => {
  if (!open) return <></>

  const [getRolesListDropdown, rolesList] = useGetRolesListAllDropdownMutation()
  const [getAccountList, accountList] = useGetAccountCheckUserListsssMutation()
  const [getMainMenuList, mainMenuList] = useGetMainMenuListMutation()
  const [getSubMenuList, subMenuList] = useGetSubMenuListMutation()
  const [updateAccountPermission, updateAccount] = useUpdateAccountPermissionMutation()

  const [nameCheck, setNameCheck] = useState<string>('')
  const [focus, setFocus] = useState<boolean>(false)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true)

  const [functionNameRows, setFunctionNameRows] = useState<{ id: string; name: string }[]>([
    { id: 'CREATE', name: 'Create' },
    { id: 'READ', name: 'View' },
    { id: 'UPDATE', name: 'Edit / Update' },
    { id: 'EXPORT', name: 'Export' }
  ])

  const [selectedSubMenu, setSelectedSubMenu] = useState<RolesAssignmentMenuProps[]>([])
  const [selectedFunction, setSelectedFunction] = useState<
    ({ id: string; name: string } | undefined)[]
  >([])
  const [selectedRow, setSelectedRow] = useState<(RolesAssignmentMenuProps | undefined)[]>([])

  const subMenuColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 48, sortable: false },
    {
      field: 'parentLevel1Id',
      headerName: 'Parent ID',
      valueGetter: (params) => params.row.parentLevel2Id || params.row.parentLevel1Id,
      width: 72,
      sortable: false
    },
    { field: 'name', headerName: 'Sub Menu', width: 240, sortable: false }
  ]

  const functionNameColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 96 },
    { field: 'name', headerName: 'Function Name', width: 240 }
  ]

  const selectedColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 96 },
    { field: 'menu', headerName: 'Main Menu', width: 160 },
    { field: 'name', headerName: 'Sub Menu', width: 160 },
    { field: 'convertedFunctions', headerName: 'Function Name', width: 256 },
    {
      field: 'Action',
      headerName: 'Action',
      width: 96,
      sortable: false,
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <IconButton onClick={() => onDeleteSelectionRow(row)}>
          <Icon icon='DeleteForever' />
        </IconButton>
      )
    }
  ]

  const onSubMenuSelectionRow = (ids: GridRowSelectionModel) => {
    const allMenu = subMenuList.data ?? []
    console.log('test', ids)

    if (ids.length === allMenu.length) {
      // Check all items
      setSelectedSubMenu(allMenu)
    } else if (ids.length === 0) {
      // Uncheck all items
      setSelectedSubMenu([])
    } else if (ids.length === selectedSubMenu.length + 1) {
      // Check single item
      const addedId = ids
        .filter((id) => !selectedSubMenu.map((menu) => menu.id).includes(+id))
        .shift()
      if (!addedId) return

      const addedMenu = allMenu.find((menu) => menu.id === addedId)
      if (!addedMenu) return

      const parentIds = [addedMenu.parentLevel1Id ?? 0, addedMenu.parentLevel2Id ?? 0].filter(
        Boolean
      )
      const addedParentMenus = allMenu.filter((menu) => parentIds.includes(menu.id))

      const allAddedMenus = [addedMenu, ...addedParentMenus].filter(
        (menu) => !selectedSubMenu.map((m) => m.id).includes(menu.id)
      )

      setSelectedSubMenu((prevMenu) => [...prevMenu, ...allAddedMenus])
    } else if (ids.length === selectedSubMenu.length - 1) {
      // Uncheck single item
      const removedMenu = selectedSubMenu.filter((menu) => !ids.includes(menu.id)).shift()
      if (!removedMenu) return

      const removedChildrenMenus = allMenu.filter(
        (menu) => menu.parentLevel1Id === removedMenu.id || menu.parentLevel2Id === removedMenu.id
      )

      const updatedMenus = selectedSubMenu.filter(
        (menu) => ![removedMenu, ...removedChildrenMenus].map((m) => m.id).includes(menu.id)
      )

      setSelectedSubMenu(updatedMenus)
    }

    // setIsAddEnabled(selectedSubMenu.length > 0 && selectedFunction.length > 0)
  }

  const onFunctionSelectionRow = (ids: GridRowSelectionModel) => {
    const selectedIds = ids as string[]

    // Jika CREATE, UPDATE, atau EXPORT dipilih, pastikan READ juga tercentang
    if (
      selectedIds.includes('CREATE') ||
      selectedIds.includes('UPDATE') ||
      selectedIds.includes('EXPORT')
    ) {
      if (!selectedIds.includes('READ')) {
        selectedIds.push('READ')
      }
    }

    const SelectionRow = selectedIds.map((id) => functionNameRows.find((row) => row.id === id))
    setSelectedFunction(SelectionRow)
    // setIsAddEnabled(
    //   selectedSubMenu.some((sub) => sub !== undefined) &&
    //     SelectionRow.some((func) => func !== undefined)
    // )
  }

  const onAddSelectionRow = () => {
    const menu = mainMenuList?.data?.find((menu) => formik.values.menuId === menu.id) || null

    const functionId = selectedFunction.map((func) => func?.id)
    const currentListedId = subMenuList?.data?.map((el) => el?.id) || []

    const selected = selectedSubMenu.map((sub) => {
      if (sub) {
        const result: SubMenuProps = {
          menu: sub.menu,
          name: sub.name,
          id: sub.id,
          functions: sub.functions.filter((func) => functionId.includes(func.type.toUpperCase())),
          convertedFunctions: sub.functions
            .filter((func) => functionId.includes(func.type.toUpperCase()))
            .map(({ name }) => name)
            .join(', ')
        }

        if (currentListedId.includes(sub.id)) {
          result.menu = menu?.name || ''
        }

        return result
      }

      return undefined
    })

    // Cek jika ada parentLevel1Id atau parentLevel2Id yang sama dengan id dari selected
    const parentIds = selected
      .flatMap((row) => [row?.parentLevel1Id, row?.parentLevel2Id])
      .filter((id) => id !== undefined)

    const additionalRows = subMenuList?.data?.filter((row) => parentIds.includes(row?.id))

    const finalSelectionRow = [...selected, ...(additionalRows || [])]

    // Cek jika ada data yang sama sebelum menambahkannya ke dalam grid
    const uniqueData = [...selectedRow, ...finalSelectionRow].filter(
      (value, index, self) => index === self.findIndex((t) => t?.id === value?.id)
    )

    setSelectedRow(uniqueData)
  }

  const onDeleteSelectionRow = (row: RolesAssignmentMenuProps) => {
    const SelectionRow = selectedRow.filter((selected) => selected && selected.id !== row.id)
    setSelectedRow(SelectionRow)

    // Cek jika ada parentLevel1Id atau parentLevel2Id yang sama dengan id dari row yang dihapus
    const parentIds = [row.parentLevel1Id, row.parentLevel2Id].filter((id) => id !== undefined)
    const list = isEmpty(selectedRow) ? subMenuList.data : selectedRow.concat(subMenuList.data)
    const additionalRows = list?.filter((row) => parentIds.includes(row?.id as number))
    const finalSelectionRow = [...SelectionRow, ...(additionalRows || [])]

    // Jika selectedRow kosong setelah penghapusan, disable tombol Submit
    if (isEmpty(finalSelectionRow)) {
      setIsSubmitDisabled(true)
    } else {
      setSelectedRow(finalSelectionRow)
    }
  }

  const onSubmit = (values: PartialMenuPermissionProps) => {
    const data = { ...values }
    delete data.menuId

    const list = selectedRow.map((selected) => selected?.functions.map((func) => func.id))
    const flattened = list.flat(1)
    const menus = flattened.map((id) => ({ FunctionId: id, Action: 'INSERT' }))

    updateAccountPermission({ ...data, Menus: menus })
  }

  const scheme = yup.object<PartialMenuPermissionProps>({
    userId: yup.string().required('Assign is required'),
    roleId: yup.number().positive('RoleId is required').required('RoleId is required'),
    menuId: yup.number().positive('menuId is required').required('menuId is required'),
    Menus: yup.array().of(
      yup.object({
        FunctionId: yup
          .number()
          .positive('FunctionId is required')
          .required('FunctionId is required'),
        Action: yup.string().required('Action is required')
      })
    )
  })

  const formik = useFormik<PartialMenuPermissionProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      userId: '',
      roleId: 0,
      menuId: 0,
      Menus: []
    },
    onSubmit: onSubmit
  })

  const debounce = useDebounce(nameCheck)
  const filteredOptions = (rolesList.data || []).filter(
    (item) => item.code.toLocaleUpperCase() !== 'CUSTOMER'
  )

  // useEffect(() => {
  //   if (focus) {
  //     getAccountList({ nameCheck });
  //     setIsSubmitDisabled(isEmpty(selectedRow));
  //   }
  // }, [debounce, selectedRow]);

  useEffect(() => {
    if (focus) {
      const selectedRoleId = formik.values.roleId
      if (selectedRoleId) {
        // Jika roleId ada, kirim roleId ke API
        getAccountList({ nameCheck, roleId: selectedRoleId })
      } else {
        // Jika roleId tidak ada, kirim hanya nameCheck ke API
        getAccountList({ nameCheck })
      }
      setIsSubmitDisabled(isEmpty(selectedRow))
    }
  }, [debounce, selectedRow, formik.values.roleId])

  useEffect(() => {
    if (selectedSubMenu.length > 0) {
      const selectedSubMenuFunctions = selectedSubMenu[0]?.functions || []
      const hasOnlyRead =
        selectedSubMenuFunctions.length === 1 && selectedSubMenuFunctions[0].type === 'READ'

      if (hasOnlyRead) {
        setFunctionNameRows([{ id: 'READ', name: 'View' }])
      } else {
        setFunctionNameRows([
          { id: 'CREATE', name: 'Create' },
          { id: 'READ', name: 'View' },
          { id: 'UPDATE', name: 'Edit / Update' },
          { id: 'EXPORT', name: 'Export' }
        ])
      }
    } else {
      // Set default functionNameRows if selectedSubMenu is empty
      setFunctionNameRows([
        { id: 'CREATE', name: 'Create' },
        { id: 'READ', name: 'View' },
        { id: 'UPDATE', name: 'Edit / Update' },
        { id: 'EXPORT', name: 'Export' }
      ])
    }
  }, [selectedSubMenu])

  return (
    <>
      <Dialog
        title='Create New Role Assignment'
        open={open}
        maxWidth='md'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={rolesList.isLoading || accountList.isLoading}
        isDisabled={!formik.isValid || isSubmitDisabled}
      >
        <Container {...DialogStyle.Container}>
          <Autocomplete
            options={filteredOptions}
            getOptionLabel={(list) => list.name}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.pkId === value.pkId : false
            }
            onOpen={() => getRolesListDropdown()}
            onChange={(_, id) => formik.setFieldValue('roleId', id?.pkId)}
            ListboxProps={GlobalStyle.ListBox}
            renderOption={(props, item) => (
              <li {...props} key={item.pkId}>
                {item.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name='roleId'
                label='Select A Role'
                error={formik.touched && formik.touched.roleId && Boolean(formik.errors.roleId)}
                helperText={
                  formik.touched && formik.touched.roleId && formik.errors && formik.errors.roleId
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {rolesList.isLoading && <CircularProgress color='inherit' size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
          <Autocomplete
            options={accountList.data || []}
            noOptionsText='Search your account name'
            getOptionLabel={(list) => list.name}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.userId === value.userId : false
            }
            onChange={(_, userId) => formik.setFieldValue('userId', userId?.userId)}
            onInputChange={(_, value) => setNameCheck(value)}
            onFocus={() => setFocus(true)}
            ListboxProps={GlobalStyle.ListBox}
            renderOption={(props, item) => (
              <li {...props} key={item.userId}>
                {item.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name='userId'
                label='Assign Access To'
                error={formik.touched && formik.touched.userId && Boolean(formik.errors.userId)}
                helperText={
                  formik.touched && formik.touched.userId && formik.errors && formik.errors.userId
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {false && <CircularProgress color='inherit' size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
          <Typography {...DialogStyle.Permission}>Permissions</Typography>
          <Autocomplete
            options={mainMenuList.data || []}
            getOptionLabel={(list) => list.name}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.id === value.id : false
            }
            onOpen={() => getMainMenuList()}
            onChange={(_, id) => {
              formik.setFieldValue('menuId', id?.id)
              getSubMenuList(id?.id || 0)
              setSelectedSubMenu([])
            }}
            onBlur={formik.handleBlur}
            ListboxProps={GlobalStyle.ListBox}
            renderOption={(props, item) => (
              <li {...props} key={item.id}>
                {item.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name='menuId'
                label='Select Main Menu'
                error={formik.touched && formik.touched.menuId && Boolean(formik.errors.menuId)}
                helperText={
                  formik.touched && formik.touched.menuId && formik.errors && formik.errors.menuId
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {mainMenuList.isLoading && <CircularProgress color='inherit' size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
          {!formik.errors.menuId && (
            <>
              <Grid container columnSpacing={2}>
                <Grid item xs={6}>
                  <DataGrid
                    rows={subMenuList?.data || []}
                    columns={subMenuColumns}
                    rowSelectionModel={selectedSubMenu.map((el) => el?.id) as GridRowId[]}
                    onRowSelectionModelChange={onSubMenuSelectionRow}
                    keepNonExistentRowsSelected
                    checkboxSelection
                    hideFooter
                    autoHeight
                  />
                </Grid>
                <Grid item xs={6}>
                  <DataGrid
                    rows={functionNameRows}
                    columns={functionNameColumns}
                    rowSelectionModel={selectedFunction.map((el) => el?.id) as GridRowId[]}
                    onRowSelectionModelChange={onFunctionSelectionRow}
                    checkboxSelection
                    hideFooter
                    autoHeight
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item flex={1} />
                <Grid item>
                  <Button
                    variant='contained'
                    disabled={selectedSubMenu.length === 0 || selectedFunction.length === 0}
                    onClick={onAddSelectionRow}
                    style={{
                      display:
                        selectedSubMenu.length > 0 && selectedFunction.length > 0 ? 'block' : 'none'
                    }} // Control visibility
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              {selectedRow && !isEmpty(selectedRow) && (
                <DataGrid rows={selectedRow} columns={selectedColumns} hideFooter autoHeight />
              )}
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!updateAccount.isLoading && !updateAccount.isUninitialized}
        onClose={() => (updateAccount.isError ? updateAccount.reset() : location.reload())}
        isError={Boolean(updateAccount.error) && updateAccount.isError}
        message={GLOBAL.returnExceptionMessage(
          updateAccount.isError,
          updateAccount.error as ErrorProps
        )}
      />
    </>
  )
}

export default Create
