import { isEmpty } from 'lodash'
import * as MuiIcons from '@mui/icons-material'
import moment from 'moment'
import * as HeroIcon from '@heroicons/react/20/solid'
import type { ErrorFetchProps, ErrorProps } from './types'
import CONSTANT from './constant'
class GLOBAL {
  static returnExceptionMessage = (
    _: boolean,
    value: ErrorProps | ErrorFetchProps | Response,
    success?: string
  ) => {
    const error = value as ErrorProps
    const errorFetch = value as ErrorFetchProps

    if (value) {
      const message =
        'data' in value && error.data ? error.data?.error?.errorMessage : errorFetch.message
      return this.splitExceptionMessage(message)
    }

    return success ?? 'Success'
  }

  static splitExceptionMessage = (value: string) => value && value.split('|')[0]

  static icon = (name: string) => MuiIcons[name as keyof typeof MuiIcons]
  static iconHero = (name: string) => HeroIcon[name as keyof typeof HeroIcon]

  static phoneNumberRegex = () =>
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  static passwordRegex = () => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g

  static isFilterContainValue = (filter: object) => Object.values(filter).some((e) => !isEmpty(e))

  static encodeUrl = (value: string) => value.replaceAll(' ', '_')
  static decodeUrl = (value: string) => value.replaceAll('_', ' ')

  static emptyQuill = (value: string) => (value == '<p><br></p>' ? null : value)

  static tableTotalPage = (totalPage: number) =>
    Math.ceil(totalPage / CONSTANT.DEFAULT_PAGINATION_PER_PAGE)

  static tableNumber = (start: number) =>
    Array.from(Array(CONSTANT.DEFAULT_PAGINATION_PER_PAGE * (start + 1)).keys()).slice(
      -CONSTANT.DEFAULT_PAGINATION_PER_PAGE
    )

  static formatDate = (value: string) => (value ? moment(value).format('YYYY-MM-DD') : '-')

  static formatDateWithTime = (value: string) =>
    value ? moment(value).format('YYYY-MM-DD HH:mm') : '-'

  static formatDateddMMYY = (value: string) => (value ? moment(value).format('DD-MM-YYYY') : '-')

  static formatDateddMMYYLongDate = (value: string) =>
    value ? moment(value).format('DD MMMM YYYY') : '-'

  static formatDateddMMYYLongDateDetail = (value: string) =>
    value ? moment(value).format('DD MMM YYYY') : '-'

  static logout = async (callback: CallableFunction) => {
    localStorage.clear()
    return callback()
  }

  static formatCurrency = (value: number) =>
    value ? value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') : '-'

  static numberFormat = (value: string | number) => {
    const parsed = parseFloat(value as string)
    const numeric = !isNaN(parsed)

    if (!numeric) return value
    return new Intl.NumberFormat(['ban', 'id']).format(parsed)
  }

  static timeConvert = (n: number) => {
    const num = n
    const hours = num / 60
    const rhours = Math.floor(hours)
    const minutes = (hours - rhours) * 60
    const rminutes = Math.round(minutes)
    return rhours + 'h ' + rminutes + 'm'
  }

  static capitalize = (value: string) => {
    const array = value.split(' ')
    const result = array.map((el) => el.charAt(0).toUpperCase() + el.slice(1))
    return result.join(' ')
  }
}

export default GLOBAL
